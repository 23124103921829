import React, { FC, ReactChild } from 'react';
import {
    View, Heading, Divider
} from '@adobe/react-spectrum';

interface IDashboardHeaderProps {
    title: string;
    children: ReactChild | ReactChild[];
}

export const DashboardHeader: FC<IDashboardHeaderProps> = ({ title, children }) => (
    <View marginStart="size-1000">
        <Heading level={1} marginTop="size-400">{title}</Heading>
        <Divider size="M" marginBottom="size-400" />
        <View
            height="calc(100vh - 180px)"
            UNSAFE_style={{
                overflow: 'hidden visible',
            }}
        >
            {children}
        </View>
    </View>
);
