import React, { FC, useEffect, useState } from 'react';
import {
    ActionButton, Text,
    Flex, Heading, Item, Menu, MenuTrigger, View
} from '@adobe/react-spectrum';
import { IAnalytics } from 'sharedtypes/analytic';

import {
    IAnInterval, IAnIntervalData, IImageQuality, getAnalyticArray
} from '~/Api/Analytics';
import { AnalyticsChart } from '../Charts/chart';

interface IChartProps {
    data: IAnalytics;
    int: IAnInterval;
}

export const ImageCharts: FC<IChartProps> = ({ data, int }) => {
    const [quality, setQuality] = useState<IImageQuality>('All');
    const [sorted, setSorted] = useState<IAnIntervalData>([]);

    useEffect(() => {
        const sortedData = getAnalyticArray(int, data, quality);
        setSorted(sortedData);
    }, [quality, data]);

    return (
        <Flex direction="column">
            <Flex direction="row" alignItems="center">
                <Heading level={3}>
                    {'Images rendered this '}
                    {' '}
                    {int}
                </Heading>
                <View marginStart="auto">
                    <Flex direction="row" alignItems="center" gap="size-100">
                        <Text>Quality: </Text>
                        <MenuTrigger>
                            <ActionButton>
                                {quality}
                            </ActionButton>
                            <Menu onAction={(key) => setQuality(key as IImageQuality)}>
                                <Item key="All">All</Item>
                                <Item key="1K">1k</Item>
                                <Item key="2K">2k</Item>
                                <Item key="4K">4k</Item>
                                <Item key="8K">8k</Item>
                            </Menu>
                        </MenuTrigger>
                    </Flex>
                </View>
            </Flex>

            <AnalyticsChart data={sorted} />
        </Flex>
    );
};
