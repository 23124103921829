import React, { FC, useEffect, useState } from 'react';
import {
    TableView,
    TableHeader,
    TableBody,
    Column,
    Row,
    Cell,
    ActionButton
} from '@adobe/react-spectrum';
import Download from '@spectrum-icons/workflow/Download';
import Camera from '@spectrum-icons/workflow/Camera';
import MovieCamera from '@spectrum-icons/workflow/MovieCamera';
import Box from '@spectrum-icons/workflow/Box';
import filter from 'lodash/filter';
import { IVisualType } from 'sharedtypes/rtx/rtx';
import { IScenario, IScenarioImage, IScenarioVideo } from 'sharedtypes/rtx/scenario';

import { useAuthContext } from '~/Context/contextAuth';
import { DeleteButton } from '~/Components/Delete/deleteButton';
import { deleteScenario, getScenariosByType } from '~/Services/Scenario/scenarioGet';

export interface IScenarioListProp {
    type?: IVisualType;
    deletable?: boolean;
    onImport?: (scenario: IScenario) => void;
    onSelect?: (scenarios: IScenario[]) => void;
}

export const ScenarioList: FC<IScenarioListProp> = ({
    type,
    deletable,
    onImport,
    onSelect
}: IScenarioListProp) => {
    const [scenarios, setScenarios] = useState<IScenario[]>([]);
    const { user } = useAuthContext();

    const columns = [
        { name: 'Name', key: 'name', width: 200 },
        { name: 'Type', key: 'type', width: 50 },
        // { name: 'Created By', key: 'creator' },
        // { name: 'Date Modified', key: 'date' },
        { name: 'Paramaters', key: 'paramaters' },
        { name: 'import', key: 'import' },
        { name: 'delete', key: 'delete' },
    ];

    const onDelete = (id: string) => {
        deleteScenario(user.email, id, type, (scenarios) => {
            if (scenarios) {
                setScenarios([...scenarios]);
            }
        });
    };

    const getScenarioString = (sce: IScenario): string => {
        if (sce.type === 'image') {
            const state = sce.state as IScenarioImage;
            const povNumber = (state.povs) ? state.povs.length : 0;
            const res = (state.resolutions && state.resolutions[0]) ? state.resolutions[0].name : 0;
            const form = state.format;
            return `${povNumber} Point of view, ${form}, ${res}`;
        } if (sce.type === 'video') {
            const state = sce.state as IScenarioVideo;
            const res = (state.resolution) ? state.resolution.name : 0;
            const { duration, fps } = state;
            return `${duration} seconde(s), ${fps} FPS, ${res}`;
        } if (sce.type === 'model') {
            return '';
        }
        return '';
    };

    useEffect(() => {
        getScenariosByType(user.email, type, (scenarios) => {
            setScenarios(scenarios);
        });
    }, []);

    const selectionChange = (keys: Set<string> | string) => {
        if (keys === 'all') {
            onSelect(scenarios);
        } else {
            const selectedItems = filter(scenarios, (i: IScenario) => keys.has(i._id));
            onSelect(selectedItems);
        }
    };

    return (
        <TableView
            aria-label="Scenario list"
            isQuiet
            selectionMode={onSelect ? 'multiple' : 'none'}
            onSelectionChange={selectionChange}
        >
            <TableHeader columns={columns}>
                {(column) => (
                    <Column
                        width={column.width}
                        hideHeader={column.key === 'import' || column.key === 'delete'}
                    >
                        {column.name}
                    </Column>
                )}
            </TableHeader>
            <TableBody items={scenarios}>
                {(scenario) => (
                    <Row key={scenario._id}>
                        {(key) => {
                            if (key === 'type') {
                                return (
                                    <Cell>
                                        {scenario.type === 'image'
                                            && <Camera />}
                                        {scenario.type === 'video'
                                            && <MovieCamera />}
                                        {scenario.type === 'model'
                                            && <Box />}
                                    </Cell>
                                );
                            } if (key === 'paramaters') {
                                return (
                                    <Cell>{getScenarioString(scenario)}</Cell>
                                );
                            } if (key === 'import') {
                                return (onImport
                                    ? (
                                        <Cell>
                                            <ActionButton
                                                isQuiet
                                                onPress={() => {
                                                    onImport(scenario);
                                                }}
                                            >
                                                <Download />
                                            </ActionButton>
                                        </Cell>
                                    )
                                    : <Cell />
                                );
                            } if (key === 'delete') {
                                return (deletable && scenario.type !== 'model'
                                    ? (
                                        <Cell>
                                            <DeleteButton
                                                onDelete={
                                                    () => onDelete(scenario._id)
                                                }
                                                title="scenario"
                                            />
                                        </Cell>
                                    ) : <Cell />
                                );
                            } return (<Cell>{scenario[key]}</Cell>);
                        }}
                    </Row>
                )}
            </TableBody>
        </TableView>
    );
};

ScenarioList.defaultProps = {
    type: null,
    onImport: null,
    deletable: false,
    onSelect: null,
};
