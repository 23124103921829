import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { IGeometryPointFlat, IVector3 } from 'sharedtypes/math';

export const RadianToDegree = 180 / Math.PI;
export const RadianToDegreeVector = new Vector3(RadianToDegree, RadianToDegree, RadianToDegree);

export const DegreeToRadian = Math.PI / 180;
export const DegreeToRadianVector = new Vector3(DegreeToRadian, DegreeToRadian, DegreeToRadian);

export const deg2rad = (deg: number): number => deg * DegreeToRadian;

export const rad2deg = (rad: number): number => rad * RadianToDegree;
export const modulo2PI = (rad: number): number => rad % (2 * Math.PI);

export const getRandomString = (): string => {
    const rand1 = Math.random().toString(36).substring(2, 15);
    const rand2 = Math.random().toString(36).substring(2, 15);
    return rand1 + rand2;
};

export const clamp = (perc: number, min = 0, max = 1): number => {
    perc = Math.max(perc, min);
    return Math.min(perc, max);
};

export const vector3ToJson = (vec: Vector3): IVector3 => ({
    x: vec.x,
    y: vec.y,
    z: vec.z
});

export interface IGeometryPoint {
    position: Vector3;
    rotation: Vector3;
}

export const geometryToJson = (geo: IGeometryPoint): IGeometryPointFlat => ({
    position: vector3ToJson(geo.position),
    rotation: vector3ToJson(geo.rotation),
});
