import { Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';

import { MouseCatcher, NakerMouseEvent } from '../Catchers/mouseCatcher';
import { SystemLayer } from '../System/systemLayer';
import { vector2Json, vector3Json } from '../Content/content';
import { projectInterface } from '../Tool/interface';
import { ICameraGeometryPoint } from '../System/systemCamera';
import { DegreeToRadian } from '../../Utils/math';

export const DEFAULT_CAMERA_GEOMETRY: ICameraGeometryPoint = {
    position: new Vector3(0, 0.5, 0),
    rotation: new Vector3(40, 180, 0),
    radius: 3,
};

export interface ICameraOptions {
    type?: 'free' | 'arc' | 'static'
    followMouse?: boolean
    followMouseSensibility?: Vector2
    draggableSensibility?: Vector2
    rangeMax?: Vector2
    zoomSensibility?: number
    zoomMax?: number
    zoomMin?: number
    initialView?: ICameraGeometryPoint
}

const initialViewJson = {
    dim: 'iv',
    next: {
        position: {
            dim: 'p',
            next: vector3Json,
        },
        rotation: {
            dim: 'r',
            next: vector3Json,
        },
        radius: 'ra',
    },
};

export const cameraJson = {
    type: 'ct',
    followMouse: 'cfm',
    followMouseSensibility: {
        dim: 'cfms',
        next: vector2Json,
    },
    draggableSensibility: {
        dim: 'cds',
        next: vector2Json,
    },
    rangeMax: {
        dim: 'rm',
        next: vector2Json,
    },
    zoomSensibility: 'czs',
    zoomMax: 'cma',
    zoomMin: 'cmi',
    initialView: initialViewJson,
};
projectInterface.camera = cameraJson;

export const defaultCameraValues: ICameraOptions = {
    type: 'arc',
    followMouse: false,
    followMouseSensibility: new Vector2(-1, 0),
    draggableSensibility: Vector2.One(),
    rangeMax: new Vector2(180, 90),
    zoomSensibility: 0.5,
    zoomMax: 4.5,
    zoomMin: 2,
    initialView: DEFAULT_CAMERA_GEOMETRY,
};

/**
 * Manage the movement of the camera along the journey and story
 */
export class SceneryCamera {
    /**
     * @ignore
     */
    private system: SystemLayer

    /**
     * @ignore
     */
    private mouseCatcher: MouseCatcher

    private _currentValues: ICameraOptions = cloneDeep(defaultCameraValues);

    public get currentValues(): ICameraOptions {
        return this._currentValues;
    }

    public set currentValues(value: ICameraOptions) {
        this._currentValues = value;
    }

    /**
     * @param system System of the 3D scene
     * @param mouseCatcher Used when camera has to follow mouse movement
     * @param mouseCatcher Used when mousewheel
     */
    constructor(system: SystemLayer, mouseCatcher: MouseCatcher) {
        this.system = system;
        this.mouseCatcher = mouseCatcher;
        this.system.setCameraGeometry(DEFAULT_CAMERA_GEOMETRY);
        this.mouseCatcher.on(NakerMouseEvent.DragStart, () => {
            this.focusStart.x = this.system.arcCamera.alpha;
            this.focusStart.y = this.system.arcCamera.beta;
        });
    }

    public checkOption(cM: ICameraOptions): void {
        if (cM.initialView !== undefined) this.setInitialView(cM.initialView);
        if (cM.followMouse !== undefined) this.setFollowMouse(cM.followMouse);
        if (cM.followMouseSensibility !== undefined) {
            this.setFollowMouseSensibility(cM.followMouseSensibility);
        }
        if (cM.draggableSensibility !== undefined) {
            this.setDraggableSensibility(cM.draggableSensibility);
        }
        if (cM.rangeMax !== undefined) this.setRangeMax(cM.rangeMax);
        if (cM.zoomSensibility !== undefined) this.setZoomSensibility(cM.zoomSensibility);
        if (cM.zoomMax !== undefined) this.setZoomMax(cM.zoomMax);
        if (cM.zoomMin !== undefined) this.setZoomMin(cM.zoomMin);
    }

    public setDefaultOption(cameraOptions: ICameraOptions): void {
        const keys = Object.keys(cameraOptions);
        keys.forEach((key) => {
            this.currentValues[key] = clone(cameraOptions[key]);
        });
        this.checkOption(cameraOptions);
    }

    public setOption(cameraOptions: ICameraOptions): void {
        this.setInitialView(cameraOptions.initialView);
        this.setFollowMouse(cameraOptions.followMouse);
        this.setFollowMouseSensibility(cameraOptions.followMouseSensibility);
        this.setDraggableSensibility(cameraOptions.draggableSensibility);
        this.setZoomSensibility(cameraOptions.zoomSensibility);
        this.setZoomMax(cameraOptions.zoomMax);
        this.setZoomMin(cameraOptions.zoomMin);
        this.setRangeMax(cameraOptions.rangeMax);
    }

    public reset(): void {
        this.setOption(this.currentValues);
    }

    initialAlpha = 0

    initialBeta = 0

    public setInitialView(initialView: ICameraGeometryPoint): void {
        //! it clones the value to make sure this is independant
        const pos = initialView.position;
        const rot = initialView.rotation;
        const newInitialView = {
            position: new Vector3(pos.x, pos.y, pos.z),
            rotation: new Vector3(rot.x, rot.y, rot.z),
            radius: initialView.radius,
        };
        this.currentValues.initialView = newInitialView;
        const alphaBeta = this.system.getAlphaBetaFromPositionRotation(initialView);
        this.initialAlpha = alphaBeta.alpha;
        this.initialBeta = alphaBeta.beta;
        this.focusRotation = new Vector2(this.initialAlpha, this.initialBeta);
        // Initial View can't be outside limits
        this.checkZoomMinMax();
        this.resetExplorationRotation();
    }

    public resetInitialView(): void {
        this.setInitialView(this.currentValues.initialView);
    }

    public setFollowMouse(followMouse: boolean): void {
        if (followMouse === undefined) followMouse = defaultCameraValues.followMouse;
        this.currentValues.followMouse = followMouse;
        this.checkFollowMouseEventNeeded();
    }

    private followSensibility = new Vector2(0.5, 0.5)

    public setFollowMouseSensibility(sensibility: Vector2): void {
        this.currentValues.followMouseSensibility = sensibility;
        this._setFollowMouseSensibility(sensibility);
    }

    public _setFollowMouseSensibility(
        sensibility: Vector2 = defaultCameraValues.followMouseSensibility
    ): void {
        this.followSensibility.x = sensibility.x;
        this.followSensibility.y = sensibility.y;
        this.checkFollowMouseEventNeeded();
    }

    private checkFollowMouseEventNeeded() {
        if (
            this.currentValues.followMouse
            && (this.currentValues.followMouseSensibility.x
                || this.currentValues.followMouseSensibility.y)
        ) {
            this.resetInitialView();
            this.mouseCatcher.on(NakerMouseEvent.Move, this.followMove, this);
        } else {
            this.resetExplorationRotation();
            this.mouseCatcher.off(NakerMouseEvent.Move, this.followMove);
        }
    }

    private dragSensibility = new Vector2(0.5, 0.5)

    private dragMultiplyFactor = new Vector2(20, 20) // To adjust sensibility

    public setDraggableSensibility(
        sensibility: Vector2 = defaultCameraValues.draggableSensibility
    ): void {
        this.currentValues.draggableSensibility = sensibility;
        this._setDraggableSensibility(sensibility);
    }

    public getDraggableSensibility(): Vector2 {
        return this.dragSensibility.clone();
    }

    public _setDraggableSensibility(draggableSensibility: Vector2): void {
        this.dragSensibility.x = draggableSensibility.x;
        this.dragSensibility.y = draggableSensibility.y;
        if (draggableSensibility.x || draggableSensibility.y) {
            this.resetInitialView();
            this.mouseCatcher.on(NakerMouseEvent.Drag, this.dragMove, this);
        } else {
            this.resetExplorationRotation();
            this.mouseCatcher.off(NakerMouseEvent.Drag, this.dragMove);
        }
    }

    private focusStart = Vector2.Zero()

    private focusRotation = Vector2.Zero()

    private dragMove(mousepos: Vector2) {
        const draggedRotation = mousepos
            .multiply(this.dragSensibility)
            .multiply(this.dragMultiplyFactor);
        this.focusRotation = this.focusStart.add(draggedRotation);
        this.setNewExplorationRotation();
    }

    private followedRotation = Vector2.Zero()

    public followMove(mousepos: Vector2): void {
        this.followedRotation = mousepos.multiply(this.followSensibility);
        this.setNewExplorationRotation();
    }

    alphaBetaVector = Vector2.Zero()

    private setNewExplorationRotation() {
        const followInvertVector = new Vector2(
            this.followedRotation.y,
            this.followedRotation.x
        );
        const dragInvertVector = new Vector2(
            this.focusRotation.y,
            this.focusRotation.x
        );
        this.alphaBetaVector = dragInvertVector.add(followInvertVector);
        this.checkAlphaBetaMax();
    }

    private resetExplorationRotation() {
        this.followedRotation = Vector2.Zero();
        this.setNewExplorationRotation();
    }

    public setZoomSensibility(
        zoom: number = defaultCameraValues.zoomSensibility
    ): void {
        this.currentValues.zoomSensibility = zoom;
        this._setZoomSensibility(zoom);
    }

    private currentZoomSensibility = 0

    private _setZoomSensibility(
        zoom: number = defaultCameraValues.zoomSensibility
    ) {
        this.currentZoomSensibility = zoom;
        if (zoom) {
            this.mouseCatcher.on(NakerMouseEvent.Wheel, this.mouseWheel, this);
        } else {
            this.mouseCatcher.off(NakerMouseEvent.Wheel, this.mouseWheel);
            this.resetExplorationRotation();
        }
    }

    private zoomSensibilityRatio = 0.05

    private currentZoom = 0

    private mouseWheel(eventData: Vector2) {
        const prevent = this.checkPreventBodyScroll(eventData.y);
        if (!prevent) {
            this.currentZoom = this.system.arcCamera.radius
                + this.currentZoomSensibility * eventData.y * this.zoomSensibilityRatio;
            this.system.setRadius(this.currentZoom);
            this.mouseCatcher.preventLastEvent();
        }
    }

    private checkPreventBodyScroll(move: number) {
        const reachedZoomMax = Math.abs(this.system.arcCamera.radius - this.zoomMax) < 0.1;
        const reachedZoomMin = Math.abs(this.system.arcCamera.radius - this.zoomMin) < 0.1;

        // If scroll reach start or end we stop preventing page scroll
        const wrongMaxScroll = reachedZoomMax && move <= 0;
        const wrongMinScroll = reachedZoomMin && move >= 0;
        if (wrongMaxScroll || wrongMinScroll) return true;
        return false;
    }

    public getMaxZoom(): number {
        return Math.round((10 * this.system.visibleWorld) / 2.2) / 10;
    }

    public zoomMax = 0

    public setZoomMax(zoomMax = 0): void {
        zoomMax = Math.min(zoomMax, this.getMaxZoom());
        this.currentValues.zoomMax = zoomMax;
        this._setZoomMax(zoomMax);
        this.checkZoomMinMax();
    }

    private _setZoomMax(zoomMax: number): void {
        this.zoomMax = zoomMax;
        if (this.zoomMax > this.zoomMin) this.zoomMin = this.zoomMax;
        this.checkZoomMinMax();
    }

    public zoomMin = defaultCameraValues.zoomMin

    public setZoomMin(zoomMin = this.getMaxZoom()): void {
        zoomMin = Math.min(zoomMin, this.getMaxZoom());
        this.currentValues.zoomMin = zoomMin;
        this._setZoomMin(zoomMin);
        this.checkZoomMinMax();
    }

    private _setZoomMin(zoomMin: number) {
        this.zoomMin = zoomMin;
        if (this.zoomMin < this.zoomMax) this.zoomMax = this.zoomMin;
        this.checkZoomMinMax();
    }

    private checkZoomMinMax() {
        this.system.arcCamera.lowerRadiusLimit = this.zoomMax;
        this.system.arcCamera.upperRadiusLimit = this.zoomMin;
    }

    public setRangeMax(rangeMax: Vector2 = defaultCameraValues.rangeMax): void {
        this.currentValues.rangeMax = rangeMax;
        this._setRangeMax(rangeMax);
        this.resetInitialView();
    }

    rangeMax: Vector2 = defaultCameraValues.rangeMax

    public _setRangeMax(rangeMax: Vector2): void {
        this.rangeMax = rangeMax;
    }

    private checkAlphaBetaMax() {
        // Math.PI means you can do 360 so no need to limit anything
        const maxX = (this.rangeMax.x >= 180) ? 1000000000 : this.rangeMax.x;
        const alpha = this.limitAngle(
            this.initialAlpha,
            this.alphaBetaVector.y,
            maxX
        );
        const beta = this.limitAngle(
            this.initialBeta,
            this.alphaBetaVector.x,
            this.rangeMax.y
        );
        this.system.setAlphaBetaRadius({ alpha, beta, radius: this.system.arcCamera.radius });
    }

    private limitAngle(
        initialAngle: number,
        dragAngle: number,
        maxAngle: number
    ): number {
        if (this.editorControl) return dragAngle;
        const perc = this.mouseCatcher.dragAnimation.easedPerc;
        const checkMaxAngle = maxAngle * DegreeToRadian;
        const angleGap = initialAngle - dragAngle;
        const angleAbs = Math.abs(angleGap);
        const angleSign = Math.sign(angleGap);
        const angleOutside = angleAbs - checkMaxAngle;
        if (angleOutside > 0) {
            // This slowly limit the angle with an easing between 1 and 1.5
            const limitEase = angleOutside / ((1 + angleOutside) ** 1.5 / 3);
            const limitDrag = this.mouseCatcher.dragging
                ? 1 + limitEase
                : 1 + (1 - perc) * limitEase;
            return initialAngle - angleSign * checkMaxAngle * limitDrag;
        }
        return dragAngle;
    }

    private editorControl = false

    public setEditorControl(editorControl: boolean): void {
        this.editorControl = editorControl;
        if (editorControl) {
            this._setZoomSensibility(0.3);
            this._setZoomMin(this.getMaxZoom());
            this._setZoomMax(0.5);
            this._setRangeMax(new Vector2(180, 90));
            this._setFollowMouseSensibility(new Vector2(0, 0));
            this._setDraggableSensibility(new Vector2(0.5, 0.5));
        } else {
            this.reset();
        }
    }
}
