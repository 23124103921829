import {
    Cell,
    Column, Row, TableBody, TableHeader, TableView
} from '@adobe/react-spectrum';
import React, { FC, useEffect, useState } from 'react';
import { IAnalytics } from 'sharedtypes/analytic';

import { UserTableCell } from './userTableCell';

const PROJECTS_COLUMNS = [
    { name: 'Name', uid: 'name', width: '3fr' },
    { name: 'Type', uid: 'type', width: '1fr' },
    { name: 'Quality', uid: 'quality', width: '1fr' },
    { name: 'Launch Time', uid: 'date', width: '2fr' },
];

interface IUserTableProps {
    data: IAnalytics;
}

export const UserProjectsAnalytics: FC<IUserTableProps> = ({ data }) => {
    const [projects, setProjects] = useState<IAnalytics>([]);

    useEffect(() => {
        // @ts-ignore
        const nData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setProjects(nData.slice(0, 5));
    }, [data]);

    return (
        <TableView width="size-6000" isQuiet aria-label="user-table">
            <TableHeader columns={PROJECTS_COLUMNS}>
                {
                    (column) => (
                        <Column
                            // @ts-ignore
                            width={column.width}
                            key={column.uid}
                            align="start"
                        >
                            {column.name}
                        </Column>
                    )
                }
            </TableHeader>
            <TableBody items={projects}>
                {
                    (it) => (
                        <Row key={it._id}>
                            {(columnKey) => (
                                <Cell>
                                    <UserTableCell key={columnKey} k={columnKey} it={it} />
                                </Cell>
                            )}
                        </Row>
                    )
                }
            </TableBody>
        </TableView>
    );
};
