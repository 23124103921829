import React, {
    FC, useState, useMemo
} from 'react';
import {
    Flex,
    Form,
    TextField,
    Image,
    Text,
    Button,
    ProgressCircle,
    Heading,
    Link,
    useProvider
} from '@adobe/react-spectrum';
import { IUser } from 'sharedtypes';

import { useAuthContext } from '~/Context/contextAuth';
import { ISuccessResponse } from '~/Types';
import { apiSpy, api } from '~/Api';
import { getQueryParam } from '~/Utils/html';
import Google from './authGoogle';
import AuthLayout from './authLayout';

import NAKER_DARK from '~/Assets/image/LOGO_DARK.svg';
import NAKER_LIGHT from '~/Assets/image/LOGO_LIGHT.svg';

interface ILoginProps {
    switchPage: () => void;
}

interface ILoginUser {
    email: string;
    password: string;
    name: string;
}

const Signup: FC<ILoginProps> = ({ switchPage }) => {
    const { colorScheme } = useProvider();
    const { login } = useAuthContext();
    const [user, setUser] = useState<ILoginUser>({
        email: '',
        password: '',
        name: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const passwordExists = useMemo(() => !!(user.password),
        [user.password]);
    const nameExists = useMemo(() => !!(user.name),
        [user.name]);
    const emailExists = useMemo(() => !!(user.email),
        [user.email]);

    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const emailValid = useMemo(() => re.test(user.email), [user.email]);
    const nameValid = useMemo(() => user.name && user.name.length >= 6, [user.name]);
    const passwordValid = useMemo(() => user.password
        && user.password.length >= 6, [user.password]);

    const showError = (text: string) => {
        setError(text);
        setLoading(false);
    };

    const showErrorData = (data: ISuccessResponse) => {
        if (data.message) showError(`${data.message} 😕`);
        else showError("Sorry it didn't work 😕");
    };

    const signupSpyservice = (vector: 'email' | 'google') => {
        const origin = getQueryParam('origin');
        apiSpy.track('Platform Signup', {
            origin,
            vector
        });
    };

    const signupUser = () => {
        setError('');
        if (!emailValid || !nameValid || !passwordValid) return;
        setLoading(true);
        const { name, email, password } = user;
        api.post('user/register', { name, password, email }, null, (data) => {
            if (data.success) {
                signupSpyservice('email');
                setSuccess(
                    'A validation email is on its way to your address, note that it could take a few seconds.'
                );
                setLoading(false);
            } else {
                showErrorData(data);
            }
        });
    };

    const loginUser = (user: IUser) => {
        if (loading) return;
        login(user);
    };

    const handleEmail = (email: string) => setUser({ ...user, email });
    const handlName = (name: string) => setUser({ ...user, name });
    const handlePassword = (password: string) => setUser({ ...user, password });

    return (
        <AuthLayout>
            <Flex direction="column" alignItems="center">
                <div className="align-height">
                    {success ? (
                        <Heading>{success}</Heading>
                    ) : (
                        <Form width="size-3000" height="100%">
                            <Flex
                                direction="column"
                                alignItems="start"
                                gap="size-200"
                            >
                                <Image src={colorScheme === 'dark' ? NAKER_LIGHT : NAKER_DARK} alt="naker-logo" />
                                <Heading>
                                    Join our community of 3D crafters
                                </Heading>
                                <Google
                                    loginUser={loginUser}
                                    currentPage="signup"
                                    isUserDisconnected
                                />
                                <Text alignSelf="center">or</Text>
                                <TextField
                                    validationState={nameValid || !nameExists ? 'valid' : 'invalid'}
                                    aria-label="textfield"
                                    width="100%"
                                    label="Username"
                                    value={user.name || ''}
                                    onChange={handlName}
                                    errorMessage={user.name === '' || !user.name
                                        ? 'Empty input not allowed.'
                                        : 'Username must be at least 6 characters.'}
                                />
                                <TextField
                                    aria-label="textfield"
                                    validationState={emailValid || !emailExists ? 'valid' : 'invalid'}
                                    width="100%"
                                    label="E-mail"
                                    value={user.email || ''}
                                    onChange={handleEmail}
                                    errorMessage={user.email === '' || !user.email
                                        ? 'Empty input not allowed.'
                                        : 'Please enter a valid email address.'}
                                />
                                <TextField
                                    validationState={passwordValid || !passwordExists ? 'valid' : 'invalid'}
                                    aria-label="textfield"
                                    width="100%"
                                    label="Password"
                                    type="password"
                                    value={user.password || ''}
                                    onChange={handlePassword}
                                    errorMessage={user.password === '' || !user.password
                                        ? 'Empty input not allowed.'
                                        : 'Password must be at least 6 characters.'}
                                    // onBlur={(v) => { setPassword(v) }}
                                />
                                <Flex
                                    direction="column"
                                    width="100%"
                                    gap="size-100"
                                >
                                    <Button
                                        variant="accent"
                                        onPress={signupUser}
                                        isDisabled={
                                            loading
                                            || (
                                                (!emailValid || !nameValid || !passwordValid)
                                                && (passwordExists || nameExists || emailExists)
                                            )
                                        }
                                    >
                                        <Flex
                                            direction="row"
                                            gap="size-100"
                                        >
                                            <Text>
                                                Signup
                                            </Text>
                                            {loading ? (
                                                <ProgressCircle
                                                    aria-label="Loading…"
                                                    isIndeterminate
                                                    size="S"
                                                />
                                            ) : null}
                                        </Flex>
                                    </Button>
                                    {error ? (
                                        <span className="login-error colorerror">
                                            {error}
                                        </span>
                                    ) : null}
                                </Flex>
                                <div>
                                    By signing up you agree to our
                                    {' '}
                                    <Link>
                                        <a
                                            href="https://asset.naker.io/legal/Terms_and_Conditions_Naker.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms of Use
                                        </a>
                                    </Link>
                                    {' '}
                                    and
                                    {' '}
                                    <Link>
                                        <a
                                            href="https://asset.naker.io/legal/Privacy_Policy_Naker.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </Link>
                                </div>
                                <Flex
                                    gap="size-100"
                                    marginTop="size-500"
                                >
                                    <Text>You already have an account?</Text>
                                    <Link onPress={switchPage}>
                                        <a>Login</a>
                                    </Link>
                                </Flex>
                            </Flex>
                        </Form>
                    )}
                </div>
            </Flex>
        </AuthLayout>
    );
};

export default Signup;
