import React, { FC } from 'react';
import {
    Flex, DialogTrigger, ActionButton
} from '@adobe/react-spectrum';
import RealTimeCustomerProfile from '@spectrum-icons/workflow/RealTimeCustomerProfile';

import AccountDialog from './accountDialog';

export const AccountButton: FC = () => (
    <Flex direction="column" alignItems="end">
        <DialogTrigger type="popover" placement="bottom">
            <ActionButton isQuiet margin="size-200" width="size-600" height="size-600">
                <RealTimeCustomerProfile width="size-400" height="size-400" />
            </ActionButton>
            <AccountDialog />
        </DialogTrigger>
    </Flex>
);
