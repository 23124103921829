import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider, View, defaultTheme } from '@adobe/react-spectrum';
import { ToastContainer } from 'react-toastify';

import AuthProvider from '~/Context/contextAuth';
import Navigation from './navigation';

ReactDOM.render(
    <BrowserRouter>
        <Provider theme={defaultTheme}>
            <View position="absolute" width="100%" height="100%" backgroundColor="gray-200">
                <AuthProvider>
                    <Navigation />
                </AuthProvider>
                <ToastContainer />
            </View>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
