import React, {
    FC, useEffect, useMemo, useState
} from 'react';
import {
    Flex, View, ProgressCircle, Item, MenuTrigger, ActionButton, Menu, Text, Divider, Heading
} from '@adobe/react-spectrum';
import { useParams } from 'react-router-dom';
import { IUser } from 'sharedtypes';
import { IAnalytics } from 'sharedtypes/analytic';

import { IAnInterval, getCompanyAnalytics } from '~/Api/Analytics';
import { ORANGE_MEDIUM } from '~/Utils/color';
import { getStringFromSeconds } from '~/Services/Rtx/rtxTimeCalculator';
import { ImageCharts } from '../Charts/imageCharts';
import { VideoCharts } from '../Charts/videoCharts';
import { CompanyUserAnalytics } from './companyUser';

export const CompanyDashboard: FC = () => {
    const params = useParams();
    const [company, setCompany] = useState<string>();
    const [int, setInt] = useState<IAnInterval>('month');
    const [images, setImages] = useState<IAnalytics>([]);
    const [videos, setVideos] = useState<IAnalytics>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const serverTimeUsed = useMemo(() => {
        let totalTime = 0;
        images.forEach((im) => {
            totalTime += im.duration;
        });
        videos.forEach((im) => {
            totalTime += im.duration;
        });
        return getStringFromSeconds(totalTime);
    }, [images, videos]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            getCompanyAnalytics(int, params.id, async ({ err, data }) => {
                setLoading(false);
                if (err) {
                    console.error(err);
                } else {
                    setImages(data.images);
                    setVideos(data.videos);
                    setUsers(data.users);
                }
            });
        };
        if (company && int) {
            fetchData();
        }
    }, [int, company]);

    useEffect(() => {
        setCompany(params.id);
    }, []);

    if (loading || !company) {
        return (
            <View>
                <ProgressCircle aria-label="loading" isIndeterminate />
            </View>
        );
    }

    return (
        <View marginStart="size-1000">
            <Flex direction="row" alignItems="center" gap="size-325" marginTop="size-200">
                <Heading level={1}>{`${company} analytics`}</Heading>
            </Flex>
            <Divider size="M" marginBottom="size-400" />
            <View
                height="calc(100vh - 180px)"
                UNSAFE_style={{
                    overflow: 'hidden visible',
                }}
            >
                <Flex direction="row" wrap gap="size-400" alignItems="center">
                    <View>
                        Total images:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {images.length || 0}
                        </Text>
                    </View>
                    <View>
                        Total videos:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {videos.length || 0}
                        </Text>
                    </View>
                    <View>
                        Server time used:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {serverTimeUsed}
                        </Text>
                    </View>
                    <View marginStart="auto">
                        <MenuTrigger>
                            <ActionButton>
                                {`This ${int}`}
                            </ActionButton>
                            <Menu onAction={(key) => setInt(key as IAnInterval)}>
                                <Item key="day">This day</Item>
                                <Item key="week">This week</Item>
                                <Item key="month">This month</Item>
                                <Item key="year">This year</Item>
                            </Menu>
                        </MenuTrigger>
                    </View>
                </Flex>
                <Flex direction="row" wrap gap="size-1000">
                    <ImageCharts data={images} int={int} />
                    <VideoCharts data={videos} int={int} />
                </Flex>
                <CompanyUserAnalytics users={users} />
            </View>
        </View>
    );
};
