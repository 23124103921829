import {
    Cell,
    Column, Row, TableBody, TableHeader, TableView
} from '@adobe/react-spectrum';
import React, { FC } from 'react';
import { IUser } from 'sharedtypes';
import { CompanyUserCell } from './companyUserCell';

const USER_COLUMNS = [
    { name: 'User name', uid: 'name', width: '3fr' },
    { name: 'Email', uid: 'email', width: '3fr' },
    { name: 'Images', uid: 'images_rendered', width: '2fr' },
    { name: 'Videos', uid: 'videos_rendered', width: '2fr' },
];

interface IUserTableProps {
    users: IUser[];
}

export const CompanyUserAnalytics: FC<IUserTableProps> = ({ users }) => (
    <TableView width="calc(size-6000 + size-1000)" height="size-3400" isQuiet aria-label="company-user-table">
        <TableHeader columns={USER_COLUMNS}>
            {
                (column) => (
                    <Column
                        // @ts-ignore
                        width={column.width}
                        key={column.uid}
                        align="start"
                    >
                        {column.name}
                    </Column>
                )
            }
        </TableHeader>
        <TableBody items={users}>
            {
                (it) => (
                    <Row key={it._id}>
                        {(columnKey) => (
                            <Cell>
                                <CompanyUserCell key={columnKey} k={columnKey} it={it} />
                            </Cell>
                        )}
                    </Row>
                )
            }
        </TableBody>
    </TableView>
);
