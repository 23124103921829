import { apiSocket } from '../apiSocket';
import { IAnInterval } from './analytics';

export type ICompany = 'naker' | 'adeo' | 'venum';

export const PARTNERS = [
    {
        name: 'naker',
        emails: ['@naker.io']
    },
    {
        name: 'adeo',
        emails: ['@adeo.com']
    },
    {
        name: 'venum',
        emails: ['@venum.com']
    }
];

export const getCompanyAnalytics = (interval: IAnInterval, name: string, callback): void => {
    const companyId = PARTNERS.findIndex((x) => x.name === name);
    const company = PARTNERS[companyId];
    apiSocket.getSocket().emit('analytics:company', { interval, ...company }, callback);
};
