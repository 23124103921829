import React, {
    FC, useState, Key
} from 'react';
import {
    Divider,
    View,
    ActionButton,
    TextField,
    Flex,
    DialogTrigger,
    Dialog,
    Heading,
    Content,
    Text,
    ButtonGroup,
    Button,
    Footer,
    Image
} from '@adobe/react-spectrum';

import Edit from '@spectrum-icons/workflow/Edit';
import ImageIcon from '@spectrum-icons/workflow/Image';
import Box from '@spectrum-icons/workflow/Box';
import DownloadFromCloud from '@spectrum-icons/workflow/DownloadFromCloud';
import CopyIcon from '@spectrum-icons/workflow/Copy';

import { toast } from 'react-toastify';
import { IDamItem } from 'sharedtypes/socket/damEvents';
import { fetchAndDownload } from '~/Services/Rtx/rtxObservable';
import { renameItem } from '~/Services/Dam/upload';

interface DamTableCell {
    k: Key;
    it: IDamItem;
}

const getMaterialItemName = (url: string) => {
    const arr = url.split('/');
    return arr[arr.length - 1];
};

const onAction = (it: IDamItem) => {
    if (it.type === 'material') {
        const baseColor = getMaterialItemName(it.material.baseColor);
        const normal = getMaterialItemName(it.material.normal);
        const occlusion = getMaterialItemName(it.material.occlusion);
        fetchAndDownload(it.material.baseColor, baseColor);
        fetchAndDownload(it.material.normal, normal);
        fetchAndDownload(it.material.occlusion, occlusion);
    } else {
        fetchAndDownload(it.url, it.name);
    }
};

const copyUrl = (url: string) => {
    toast.success('Url copied !');
    navigator.clipboard.writeText(url);
};

const getItemSize = (size: number) => {
    if (!size || Number.isNaN(size)) {
        return 'Unknown Size';
    }
    if (size < 1000000) {
        return `${Math.round((size / 1000) * 10) / 10} KB`;
    }
    return `${Math.round((size / 1000000) * 10) / 10} MB`;
};

export const DamTableCell: FC<DamTableCell> = ({
    k, it
}) => {
    const [name, setName] = useState(it.name);
    const [tempName, setTempName] = useState(name);

    const updateItemName = (closeFn: () => void) => {
        setName(tempName);
        renameItem(tempName, it.key);
        closeFn();
    };

    const cancel = (closeFn: () => void) => {
        setTempName(name);
        closeFn();
    };

    if (k === 'name') {
        return (
            <DialogTrigger>
                <Flex>
                    <ActionButton
                        aria-label="update-button"
                        isQuiet
                        UNSAFE_style={{
                            paddingRight: '8px'
                        }}
                    >
                        <Flex direction="row" gap="size-100">
                            <Edit />
                            <View maxWidth="size-2000" overflow="hidden">{name}</View>
                        </Flex>
                    </ActionButton>
                </Flex>
                {(close) => (
                    <Dialog width="size-6000" onDismiss={() => { cancel(close); }}>
                        <Heading>
                            Rename
                            {' '}
                            {it.type}
                        </Heading>
                        <Divider />
                        <Content>
                            <Flex direction="row" alignItems="center">
                                <TextField
                                    isQuiet
                                    width="size-3400"
                                    aria-label="asset-rename"
                                    value={tempName}
                                    onChange={setTempName}
                                />
                            </Flex>
                        </Content>
                        <Footer>
                            <ButtonGroup>
                                <Button variant="secondary" onPress={() => cancel(close)}>
                                    Cancel
                                </Button>
                                <Button autoFocus variant="accent" onPress={() => updateItemName(close)}>
                                    Save
                                </Button>
                            </ButtonGroup>
                        </Footer>
                    </Dialog>
                )}
            </DialogTrigger>
        );
    }
    if (k === 'type') {
        if (it.type === 'material') return <ImageIcon />;
        return <Box />;
    }
    if (k === 'dwl') {
        return (
            <ActionButton
                aria-label="download-button"
                isQuiet
                onPress={() => { onAction(it); }}

            >
                <DownloadFromCloud />
            </ActionButton>
        );
    }
    if (k === 'url') {
        return (
            <ActionButton
                aria-label="copy-button"
                onPress={() => { copyUrl(it.url); }}
            >
                <Flex direction="row" gap="size-100">
                    <CopyIcon />
                    <Text>Copy</Text>
                </Flex>
            </ActionButton>
        );
    }

    if (k === 'thumbnail') {
        if (it.type === 'material') {
            return <Image width="size-400" height="size-400" src={it.material.baseColor} />;
        }
        return <Box />;
    }

    if (k === 'upload_date') {
        const date = new Date(it.upload_date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const hour = date.getHours();
        const mins = date.getMinutes();
        return <View key={it.key + k}>{`${day}/${month} - ${hour}:${mins}`}</View>;
    }

    if (k === 'owner') {
        return <View>{it.owner}</View>;
    }

    if (k === 'size') {
        return (
            <View>
                {getItemSize(it.size)}
            </View>
        );
    }

    return (
        <View>{it.url}</View>
    );
};
