import React, {
    FC,
    useEffect,
    useState,
    useCallback
} from 'react';
import {
    Content,
    DialogContainer,
    Dialog,
    Heading,
    Flex
} from '@adobe/react-spectrum';
import { toast } from 'react-toastify';
import {
    IUser, UserList, IProject, IWorker
} from 'sharedtypes';

import { ITeamProps } from '~/Types';
import { useAuthContext } from '~/Context/contextAuth';
import { api, apiSpy } from '~/Api';
import { CardCollaborators } from './cardCollaborators';

interface ICardShareModalProps {
    show: boolean;
    setShow: (bool: boolean) => void;
    project: IProject;
    user: IUser;
}

export const CardShareModal: FC<ICardShareModalProps> = (
    props: ICardShareModalProps
) => {
    const {
        project, user, show, setShow
    } = props;
    const id = project ? project._id : '';
    const { isAdmin } = useAuthContext();
    const [engine, setEngine] = useState('studio');
    const [team, setCurrentTeam] = useState<UserList>([]);
    const [worker, setWorker] = useState<IWorker>();
    const [templateButton, setTemplateButton] = useState(false);
    const [template, setTemplate] = useState(false);
    const [teamIds, setTeamIds] = useState<string[]>([]);
    const [sharedUrl, setSharedUrl] = useState('');
    const [copyText, setCopyText] = useState('Copy URL');
    const [requestSent, setRequestSent] = useState(false);

    type Items = 'facebook' | 'twitter' | 'copy';
    const getTeam = useCallback(() => {
        api.get('project/teamlist', { id: project._id }, (data: ITeamProps) => {
            if (data) {
                const { team, teamIds } = data;
                setCurrentTeam(team);
                setTeamIds(teamIds);
                if (isAdmin) setTemplateButton(true);
            }
        });
    }, [project, user]);

    useEffect(() => {
        if (project) {
            setWorker(project.worker);
            getTeam();
            setSharedUrl(`https://harbor.naker.io/${engine}/${project._id}/`);
            setEngine(project.engine || 'studio');
        }
    }, [project, getTeam, engine]);

    const closeModal = () => {
        setShow(false);
    };

    const shareTwitter = () => {
        const shared = id ? 'project' : 'tool';
        apiSpy.track('Sharing_Social Click', {
            shared,
            engine
        });
        let text;
        if (id) text = `See my new ${engine} made with Naker : `;
        else text = `Check Naker.${engine} tool here : `;
        window.open(
            `http://www.twitter.com/share?url=${encodeURIComponent(
                sharedUrl
            )}&text=${text}`
        );
    };

    const shareFacebook = () => {
        const shared = project._id ? 'project' : 'tool';
        apiSpy.track('Sharing_Social Click', {
            shared,
            engine
        });
        window.open(
            `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                sharedUrl
            )}`
        );
    };

    const copyUrl = () => {
        navigator.clipboard.writeText(sharedUrl);
        setCopyText('Copied');
        setTimeout(() => {
            setCopyText('Copy URL');
        }, 2000);
    };

    const tryUpdate = (filtered_team?: string[], callback?: () => void) => {
        if (requestSent) return;
        setTimeout(() => {
            setRequestSent(false);
        }, 3000);
        if (template === undefined) setTemplate(false);
        const team = filtered_team !== undefined
            ? filtered_team.join(',')
            : teamIds.join(',');
        const body = { team: team || user._id, id, template };
        api.put('project/update', body, (data) => {
            if (!data.success) {
                apiSpy.track('Story Update', {});
                toast.error(
                    'We had an error while updating the project.'
                );
            } else {
                toast.success('Good, your project has been updated. 😃');
            }
            if (callback) callback();
        });
    };

    const setActionKey = (action: Items) => {
        if (action === 'facebook') shareFacebook();
        else if (action === 'twitter') shareTwitter();
        else if (action === 'copy') copyUrl();
    };

    return (
        <Flex>
            <DialogContainer onDismiss={() => closeModal()} isDismissable>
                {show && (
                    <Dialog size="XS">
                        <Heading>Share Project</Heading>
                        <Content>
                            <CardCollaborators
                                team={team}
                                worker={worker}
                                user={user}
                                project={project}
                                teamIds={teamIds}
                                setTeamIds={setTeamIds}
                                update={tryUpdate}
                                getTeam={getTeam}
                            />
                        </Content>
                    </Dialog>
                )}
            </DialogContainer>
        </Flex>
    );
};
