import React, {
    ActionButton,
    Button,
    Flex
} from '@adobe/react-spectrum';
import {
    FC, useState, useMemo
} from 'react';

interface ILibraryPaginationProps {
    pageSize: number;
    totalProjects: number;
    setCurrentPage: (page: number) => void;
    currentPage: number;
}
export const LibraryPagination: FC<ILibraryPaginationProps> = ({
    pageSize, totalProjects, setCurrentPage, currentPage
}) => {
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const switchPage = (event) => {
        setCurrentPage(parseInt(event.target.innerText, 10));
    };

    const handleNextButton = () => {
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit((maxPageNumberLimit + pageSize));
            setMinPageNumberLimit(minPageNumberLimit + pageSize);
        }
        setCurrentPage(currentPage + 1);
    };

    const handlePrevioustButton = () => {
        if ((currentPage - 1) % pageSize === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageSize);
            setMinPageNumberLimit(minPageNumberLimit - pageSize);
        }

        setCurrentPage(currentPage - 1);
    };

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    };

    const LEFT_PAGE = 'LEFT';
    const RIGHT_PAGE = 'RIGHT';

    const pageList = useMemo(() => {
        const totalNumbers = 5;
        const totalBlocks = totalNumbers + 2;
        const totalPages = Math.ceil(totalProjects / pageSize);
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);
            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 1;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }, [pageSize, currentPage, totalProjects]);

    return (
        <Flex gap="size-50">
            {pageList.map((page) => {
                if (page === LEFT_PAGE) {
                    return <Button key={page} variant="cta" isDisabled={currentPage === pageList[0]} onPress={handlePrevioustButton}>&laquo;</Button>;
                }
                if (page === RIGHT_PAGE) {
                    return <Button key={page} variant="cta" isDisabled={currentPage === pageList[pageList.length - 1]} onPress={handleNextButton}>&raquo;</Button>;
                }
                return (
                    <ActionButton key={page} UNSAFE_style={{ cursor: 'pointer' }} onPress={(v) => switchPage(v)} autoFocus={currentPage === page}>
                        {page}
                    </ActionButton>
                );
            })}
        </Flex>
    );
};
