import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';

import { PatternMaterial } from './patternMaterial';

export class PatternMesh extends PatternMaterial {
    public setMesh(mesh: Mesh): void {
        this._setMesh(mesh);
        this.initMaterial();
        this.receiveShadow(true);
        if (mesh.isVisible) this.show(); // call show function to take material options into account
        this._system.addShadowCaster(this.mesh);
    }

    protected _setMesh(mesh: Mesh): void {
        if (this.mesh) mesh.isVisible = this.mesh.isVisible;
        this.initMesh(mesh);
        // Need to set other layer for edit pointofview preview mode
        this.mesh.layerMask = 0x0FFFFFFF | 0x20000000;
        this.flat = false;
        this.mesh.alwaysSelectAsActiveMesh = true;
    }

    public show(): void {
        this._show();
        //* When material uses a texture, we need to update it to make it visible
        this.updateMaterial();
    }

    public hide(): void {
        this._hide();
    }

    protected _show(): void {
        if (this.mesh) this.mesh.isVisible = true;
        this._system.checkActiveMeshes();
        this._system.resetShadows();
    }

    protected _hide(): void {
        if (this.mesh) this.mesh.isVisible = false;
        this._system.checkActiveMeshes();
        this._system.resetShadows();
    }

    public receiveShadow(bool: boolean): void {
        this.mesh.receiveShadows = bool;
    }

    public listenEvent(bool: boolean): void {
        this.mesh.isPickable = bool;
    }

    // Make sur change is needed becasue very heavy calculation
    protected flat = false;

    public setFlatMesh(flat: boolean): void {
        if (flat && !this.flat) this.mesh.convertToFlatShadedMesh();
        else if (!flat && this.flat) this.mesh.forceSharedVertices();
        this.flat = flat;
    }

    // https://doc.babylonjs.com/how_to/optimizing_your_scene
    public optimize(): void {
        this.mesh.freezeWorldMatrix();
        this.mesh.doNotSyncBoundingInfo = true;
        this.mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
    }

    public unOptimize(): void {
        this.mesh.unfreezeWorldMatrix();
        this.mesh.doNotSyncBoundingInfo = false;
        this.mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_STANDARD;
    }

    public setTag(tag: string): void {
        if (tag) {
            this.mesh.isPickable = true;
            this.mesh.accessibilityTag = { description: tag };
        } else {
            this.mesh.isPickable = false;
            this.mesh.accessibilityTag = null;
        }
    }
}
