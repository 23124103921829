/**
 * An observer which will be call at a specific event
 */

export interface IObserver<U> {
    funct: (eventData) => void,
    event?: U,
    scope?,
}

/**
 * Class inpired by BabylonJS observable but more simple for our needs
 * https://github.com/BabylonJS/Babylon.js/blob/master/src/Misc/observable.ts
 */

export class Observable<U, T> {
    private observers = new Array<IObserver<U>>();

    private observableName: string;

    constructor(observableName: string) {
        this.observableName = observableName;
    }

    /**
     * Allow to add a listener on special events
     * @param what the event: start or stop and mouseWheel for now
     * @param funct the function to be called at the event
     * Do not use anonymous function or you won't be able to remove it
     */
    public on(event: U, funct: (eventData: T) => void, scope?, first?: boolean): void {
        if (this.hasObserver(event, funct)) return;
        const newObserver = {
            funct,
            event,
            scope,
        };

        if (first) {
            this.observers.unshift(newObserver);
        } else {
            this.observers.push(newObserver);
        }
    }

    public onChange(funct: (eventData: T) => void, scope?): void {
        this.observers.push({ funct, scope });
    }

    public once(event: U, funct: (eventData: T) => void, scope?): void {
        const execFunc = (eventData) => {
            //* setTimeout need as we loop on oberver object
            setTimeout(() => {
                this.off(event, execFunc);
            }, 0);
            funct(eventData);
        };
        this.on(event, execFunc, scope);
    }

    public off(event: U, funct: (eventData: T) => void): boolean {
        let test = false;
        this.observers.forEach((obs) => {
            if (obs.event === event && obs.funct === funct) {
                const index = this.observers.indexOf(obs);
                if (index !== -1) {
                    this.observers.splice(index, 1);
                    test = true;
                }
            }
        });
        return test;
    }

    private inTheMiddleOfEventCallback: U[] = [];

    public notify(event: U, eventData: T): void {
        this.checkLoop(eventData, () => {
            this.observers.forEach((obs) => {
                if (obs.event === event) {
                    this.notifyOberver(obs, eventData);
                }
            });
        }, event);
    }

    public notifyChange(eventData: T): void {
        this.checkLoop(eventData, () => {
            this.observers.forEach((obs) => {
                this.notifyOberver(obs, eventData);
            });
        });
    }

    private checkLoop(eventData: T, callback: () => void, event: U = null) {
        // Use null to avoid error message in loop test
        if (this.inTheMiddleOfEventCallback.indexOf(event) === -1) {
            this.inTheMiddleOfEventCallback.push(event);
            callback();
            const index = this.inTheMiddleOfEventCallback.indexOf(event);
            this.inTheMiddleOfEventCallback.splice(index, 1);
        } else {
            // infinite loop avoided! report the scenario somehow
            console.error(`Infinite callback loop in observable: ${this.observableName}, Event All`, eventData);
        }
    }

    protected notifyOberver(observer: IObserver<U>, eventData: T): void {
        if (observer.scope) {
            observer.funct.apply(observer.scope, [eventData]);
        } else {
            observer.funct(eventData);
        }
    }

    protected hasObserver(event: U, funct: (eventData: T) => void): boolean {
        let test = false;
        this.observers.forEach((obs) => {
            if (obs.funct === funct && obs.event === event) test = true;
        });
        return test;
    }

    protected hasEventObservers(event: U): boolean {
        let test = false;
        this.observers.forEach((obs) => {
            if (obs.event === event) test = true;
        });
        return test;
    }

    protected hasObservers(): boolean {
        return this.observers.length > 0;
    }

    /**
    * Clear the list of observers
    */
    public clear(): void {
        this.observers = new Array<IObserver<U>>();
    }
}
