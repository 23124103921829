import { ActionButton, Text, View } from '@adobe/react-spectrum';
import React, { FC, Key } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from 'sharedtypes';

import { ORANGE_MEDIUM } from '~/Utils/color';

interface IUserTableCell {
    k: Key;
    it: IUser;
}

export const CompanyUserCell: FC<IUserTableCell> = ({ k, it }) => {
    const navigate = useNavigate();
    const redirectToProfile = (user: IUser) => {
        navigate(`/dashboard/analytics/user/${user._id}`, {
            state: user
        });
    };

    if (k === 'name') {
        return (
            <ActionButton isQuiet onPress={() => redirectToProfile(it)}>
                <Text UNSAFE_style={{ color: ORANGE_MEDIUM, textDecoration: 'underline' }}>
                    {it.name}
                </Text>
            </ActionButton>
        );
    }
    if (k === 'videos_rendered' || k === 'images_rendered') {
        return (
            <View>
                {it[k] || 0}
            </View>
        );
    }
    return (
        <View>
            {it.email}
        </View>
    );
};
