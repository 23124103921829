import React, { FC, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Flex,
    Heading,
    Link,
    ProgressCircle
} from '@adobe/react-spectrum';

import { api, apiSpy } from '~/Api';
import AuthLayout from './authLayout';

const Confirm: FC = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        apiSpy.start();
        const url = window.location.href;
        const tokenArr = /token=([^&]+)/.exec(url);
        if (tokenArr != null) {
            const token = tokenArr[1];
            api.post('user/confirm', { token }, null, (data) => {
                if (data.success) {
                    setSuccess(data.success);
                    apiSpy.track('Email Confirm', {});
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 5000);
                } else {
                    apiSpy.track('Email Confirm Failed', {});
                    setError(true);
                    setTimeout(() => {
                        window.location.href = '/signup';
                    }, 5000);
                }
            });
        } else {
            setError(true);
        }
    }, []);

    return (
        <AuthLayout>
            <Flex direction="column" alignItems="center">
                <div className="align-height">
                    {!success && !error ? (
                        <ProgressCircle
                            aria-label="Loading…"
                            isIndeterminate
                            size="M"
                        />
                    ) : null}
                    {success ? (
                        <Heading>Your account is now activated</Heading>
                    ) : null}
                    {error ? (
                        <Heading>Your token is not valid</Heading>
                    ) : null}
                    <Link>
                        <RouterLink to="/login">
                            Back to Log In
                        </RouterLink>
                    </Link>
                </div>
            </Flex>
        </AuthLayout>
    );
};

export default Confirm;
