import React, {
    useState, useEffect, FC
} from 'react';
import {
    Flex,
    Text,
    ComboBox,
    Item
} from '@adobe/react-spectrum';
import { IProjects } from 'sharedtypes';

import { useAuthContext } from '~/Context/contextAuth';
import ProjectView from './ProjectList/projectView';
import { LibraryPagination } from './Pagination';
import { TDashboardLibrary } from '../IDashboard';
import { DashboardHeader } from '../dashboardHeader';
import { DashboardFooter } from '../dashboardFooter';
import { apiSocket } from '~/Api/apiSocket';
import { ProjectSearchBar } from './SearchBar/projectSearchBar';

interface ILibraryDashboardProps {
    library: TDashboardLibrary;
}

const options = [
    { page: 5 },
    { page: 10 },
    { page: 25 },
    { page: 50 }
];

const updateLibrary = (query: {
    page: number,
    size: number,
    search: string,
    library: string
}, callback: (p: IProjects) => void) => {
    const socket = apiSocket.getSocket();
    if (socket) {
        socket.emit('library:get', query, ({ err, data }) => {
            if (err) {
                return callback([]);
            }
            return callback(data);
        });
    }
};

export const LibraryDashboard: FC<ILibraryDashboardProps> = ({ library }) => {
    const [currentPage, setCurrentPage] = useState<number | undefined>(1);
    const [librarySize, setLibrarySize] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const [projects, setProjects] = useState<IProjects>([]);
    const { isLogged } = useAuthContext();

    useEffect(() => {
        updateLibrary({
            page: currentPage, size: pageSize, search: searchValue, library
        }, (data) => {
            setProjects(data);
        });
    }, [library, pageSize, currentPage, searchValue]);

    useEffect(() => {
        const socket = apiSocket.getSocket();
        if (isLogged && socket) {
            socket.emit('library:count', { library }, ({ err, data }) => {
                if (err) {
                    console.log(err);
                } else {
                    setLibrarySize(data.count);
                }
            });
            setPageSize(10);
            setCurrentPage(1);
            setSearchValue('');
        }
    }, [isLogged, library]);

    useEffect(() => {
        const listener = () => {
            updateLibrary({
                page: currentPage, size: pageSize, search: searchValue, library
            }, (data) => {
                setProjects(data);
            });
        };
        apiSocket.addListener((socket) => {
            socket.on('library:updated', listener);
        });
        return function cleanup() {
            apiSocket.getSocket().off('library:updated', listener);
        };
    }, []);

    return (
        <>
            <DashboardHeader title={library === 'personal'
                ? 'Personal Library'
                : 'Shared Projects'}
            >
                <ProjectSearchBar value={searchValue} setValue={setSearchValue} />
                <ProjectView
                    page={library}
                    projects={projects}
                    currentPage={currentPage}
                />
            </DashboardHeader>
            <DashboardFooter>
                <Flex direction="column" width="100%" alignItems="center">
                    <Flex direction="row" alignItems="center" gap="size-200">
                        <Text>{`Page ${currentPage} of ${Math.ceil(librarySize / pageSize)}`}</Text>
                        <LibraryPagination
                            pageSize={pageSize}
                            totalProjects={librarySize}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                        <ComboBox
                            justifySelf="end"
                            aria-label="project-page"
                            width="size-100"
                            items={options}
                            inputValue={pageSize as unknown as string}
                            selectedKey={pageSize}
                            onSelectionChange={(size: number) => setPageSize(size)}
                        >
                            {(item) => <Item textValue={`${item.page}`} key={item.page}>{item.page}</Item>}
                        </ComboBox>
                    </Flex>
                </Flex>
            </DashboardFooter>
        </>
    );
};
