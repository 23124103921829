import { Route, Routes } from 'react-router-dom';
import React, {
    FC, lazy, Suspense, useEffect
} from 'react';
import { View } from '@adobe/react-spectrum';

import 'react-toastify/dist/ReactToastify.css';

import Auth from './Pages/Auth/auth';
import Confirm from './Pages/Auth/authConfirm';
import Password from './Pages/Auth/authPassword';
import Billing from './Pages/Billing';
/* import BillingSuccess from './Billing/billingSuccessDialog';
import BillingCancelled from './Billing/cancelled'; */

import '~/Assets/main.sass';
import { AnalyticsDashboard } from './Pages/Dashboard/Analytics';
import { UserDashboard } from './Pages/Dashboard/Analytics/User';
import { Companies, CompanyDashboard } from './Pages/Dashboard/Analytics/Company';
import { ScenarioDashboard } from './Pages/Dashboard/Scenario/scenarioDashboard';
import { QueueDashboard } from './Pages/Dashboard/Queue/queueDashboard';
import { DashboardSidebar } from './Pages/Dashboard/SideBar/sideBar';
import { LibraryDashboard } from './Pages/Dashboard/Library';
import { DamDashboard } from './Pages/Dashboard/AssetManager/damDashboard';

const Studio = lazy(() => import('./Pages/Studio/main'));

// in orrder to force reload when 3D is changed
if (module.hot) {
    module.hot.dispose(() => {
        window.location.reload();
        throw new Error('whatever');
    });
}

const LazyStudio: FC = () => (
    <View position="absolute" width="100%" height="100%">
        <Suspense fallback={<div />}>
            <Studio />
        </Suspense>
    </View>
);

const NakerBack: FC = () => {
    useEffect(() => {
        window.location.assign('http://back.naker.io');
    }, []);
    return (<></>);
};

const Navigation: FC = () => (
    <div className="color-change">
        <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/password" element={<Password />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/signup" element={<Auth />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/back" element={<NakerBack />} />
            <Route path="/dashboard" element={<DashboardSidebar />}>
                <Route path="personal" element={<LibraryDashboard library="personal" />} />
                <Route path="shared" element={<LibraryDashboard library="shared" />} />
                <Route path="scenario" element={<ScenarioDashboard />} />
                <Route path="queue" element={<QueueDashboard />} />
                <Route path="analytics">
                    <Route index element={<AnalyticsDashboard />} />
                    <Route path="user/:id" element={<UserDashboard />} />
                    <Route path="companies" element={<Companies />} />
                    <Route path="companies/:id" element={<CompanyDashboard />} />
                    <Route path="*" element={<AnalyticsDashboard />} />
                </Route>
                <Route path="dam">
                    <Route index element={<DamDashboard />} />
                    <Route path="*" element={<DamDashboard />} />
                </Route>
                <Route path="*" element={<LibraryDashboard library="personal" />} />
            </Route>
            <Route path="/billing/*" element={<Billing />} />
            <Route path="/studio" element={<LazyStudio />} />
            <Route path="/studio/:id" element={<LazyStudio />} />
            <Route path="/studio/:id/:panel" element={<LazyStudio />} />
        </Routes>
    </div>
);

export default Navigation;
