/* eslint-disable react/jsx-props-no-spreading */

import React, { FC } from 'react';
import { ActionButton, Text } from '@adobe/react-spectrum';
import { useDropzone } from 'react-dropzone';
import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';

interface UploadButtonProps {
    extensions: string[];
    label: string;
    onDrop: (s: File[]) => void;
}

export const UploadButton: FC<UploadButtonProps> = ({ label, extensions, onDrop }) => {
    const { getRootProps, getInputProps, open } = useDropzone(
        {
            onDrop,
            noKeyboard: true,
            accept: extensions,
            noClick: true
        }
    );

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <ActionButton onPress={() => open()}>
                <UploadToCloud aria-label={`Upload ${label}`} order={1} />
                <Text>{`Upload ${label}`}</Text>
            </ActionButton>
        </div>
    );
};
