import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Mesh } from '@babylonjs/core/Meshes/mesh';

import { SystemLayer } from '../../../System/systemLayer';
import { PatternMesh } from '../Mesh/patternMesh';
import { PatternTransform } from '../patternTransform';

interface IBounds {
    minimum: Vector3
    maximum: Vector3
}
export const checkMeshesBounds = (meshes: Mesh[]): IBounds => {
    let minimum = new Vector3(1000, 1000, 1000);
    let maximum = new Vector3(-1000, -1000, -1000);
    meshes.forEach((m) => {
        const boundingInfo = m.getHierarchyBoundingVectors();
        if (minimum === undefined) {
            maximum = boundingInfo.max;
            minimum = boundingInfo.min;
        } else {
            maximum.maximizeInPlace(boundingInfo.max);
            minimum.minimizeInPlace(boundingInfo.min);
        }
    });
    return { minimum, maximum };
};

export class PatternGroup extends PatternTransform {
    protected scaleParent: TransformNode;

    protected center: Vector3;

    public patternMeshes: PatternMesh[] = [];

    constructor(systemLayer: SystemLayer) {
        super(systemLayer);
        const mesh = new TransformNode('group_parent', systemLayer.scene);
        this.initMesh(mesh);
        this.scaleParent = new TransformNode('group_scale', systemLayer.scene);
        this.scaleParent.parent = mesh;
    }

    public listenEvent(bool: boolean): void {
        this.patternMeshes.forEach((pM) => {
            pM.listenEvent(bool);
        });
    }

    protected maximum: Vector3;

    protected minimum: Vector3;

    protected getCenter(): void {
        if (this.minimum === undefined) return;
        const sum = this.maximum.add(this.minimum);
        this.center = sum.divide(new Vector3(2, 2, 2));
    }

    protected checkBounds(): void {
        if (this.patternMeshes[0] === undefined) return;
        const meshes = this.patternMeshes.map((pM) => pM.mesh);
        const { minimum, maximum } = checkMeshesBounds(meshes);
        this.minimum = minimum;
        this.maximum = maximum;
    }

    // Extend mesh with group
    private setAllPatternMeshesParam(
        what: string,
        value1?: any,
        value2?: any,
        value3?: any,
        value4?: any
    ): void {
        // for functions called during mesh construction
        if (this.patternMeshes === undefined) return;
        this.patternMeshes.forEach((pM) => {
            pM[what](value1, value2, value3, value4);
        });
    }

    // Use parent setenabled to really show/hide group
    public show(): void {
        this.setAllPatternMeshesParam('show');
    }

    public hide(): void {
        this.setAllPatternMeshesParam('hide');
    }

    public deleteMaterial(): void {
        // this.setAllPatternMeshesParam('deleteMaterial');
    }

    public setMaterialProperties(prop: any): void {
        this.setAllPatternMeshesParam('setMaterialProperties', prop);
    }

    public setMaterialProperty(key: string, value: any): void {
        this.setAllPatternMeshesParam('setMaterialProperty', key, value);
    }

    public removeMaterialProperties(): void {
        this.setAllPatternMeshesParam('removeMaterialProperties');
    }

    public removeMaterialProperty(key: string): void {
        this.setAllPatternMeshesParam('removeMaterialProperty', key);
    }

    public setTintColor(color: number[]): void {
        this.setAllPatternMeshesParam('setTintColor', color);
    }

    public resetTintColor(): void {
        this.setAllPatternMeshesParam('resetTintColor');
    }

    public setTextureUrl(type: string, textureUrl: string): void {
        this.setAllPatternMeshesParam('setTextureUrl', type, textureUrl);
    }

    public destroy(): void {
        this._destroyMeshes();
        this.scaleParent.dispose(true);
        this.mesh.dispose(true);
    }

    protected _destroyMeshes(): void {
        // Avoid to destroy because it will delete used materials
        // this.setAllPatternMeshesParam('destroy');
        if (this.patternMeshes) {
            this.patternMeshes.forEach((pM) => {
                pM.mesh.parent = undefined;
                try { // Can create bug some times
                    pM.mesh.dispose();
                } catch (e) {
                    console.log(e);
                }
            });
        }
        this.patternMeshes = [];
        this.maximum = undefined;
        this.minimum = undefined;
    }
}
