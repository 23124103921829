import React, {
    FC, useState
} from 'react';
import { toast } from 'react-toastify';
import { IProjects, IProject } from 'sharedtypes';

import { apiSpy } from '~/Api';
import { useAuthContext } from '~/Context/contextAuth';
import { ProjectListLayout } from './projectListLayout';
import { CardShareModal } from './ProjectCard';
import { TDashboardLibrary } from '../../IDashboard';
import { CONTEXT, context } from '~/Services/Environment/environment';
import { apiSocket } from '~/Api/apiSocket';

interface IProjectViewProps {
    page: TDashboardLibrary;
    currentPage: number;
    projects: IProjects;
}

export const ProjectView: FC<IProjectViewProps> = ({ page, projects, currentPage }) => {
    const {
        user, isPremium, isAdmin
    } = useAuthContext();
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState<IProject>();
    const [requestSent, setRequestSent] = useState(false);

    const requestNewProject = () => {
        const name = 'New Studio';
        const query = {
            id: user._id,
            name,
            premium_level: user.premium_level,
            // Tag and team needed by API
            tags: '',
            team: '',
            engine: 'studio'
        };
        apiSocket.getSocket().emit('project:create', query, ({ err, data }) => {
            setRequestSent(false);
            if (err) {
                toast.error(
                    'Oups we had an issue while creating your project. 🙁'
                );
            } else {
                apiSpy.track('Story Create', { from: 'scratch' });
                // navigate(`/studio/${data._id}`);
                window.location.href = `/studio/${data._id}`;
            }
        });
    };

    const createProject = () => {
        if (requestSent) return;
        setRequestSent(true);
        setTimeout(() => {
            setRequestSent(false);
        }, 3000);

        if (context === CONTEXT.PROD) {
            if (!isPremium && !isAdmin) {
                setTimeout(() => {
                    window.location.href = '/billing';
                }, 100);
            } else {
                requestNewProject();
            }
        } else {
            requestNewProject();
        }
    };

    return (
        <>
            <ProjectListLayout
                currentPage={currentPage}
                projects={projects}
                listType={page}
                createProject={createProject}
                setSelectedProject={setSelectedProject}
                setShowModal={setShowModal}
            />
            <CardShareModal
                show={showModal}
                setShow={setShowModal}
                project={selectedProject}
                user={user}
            />
        </>
    );
};

export default ProjectView;
