//* Draco compression used in every Naker model
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_draco_mesh_compression';
// //* KHR extensions needed for some models
// import '@babylonjs/loaders/glTF/2.0/Extensions';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_lights_punctual';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_clearcoat';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_ior';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_pbrSpecularGlossiness';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_sheen';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_volume';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_specular';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_translucency';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_transmission';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_unlit';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_materials_variants';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_mesh_quantization';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_texture_basisu';
import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_texture_transform';

// import '@babylonjs/loaders';
// import '@babylonjs/loaders/glTF';
// import '@babylonjs/loaders/glTF/2.0';
//* Only ue GLTF2 file now as every import from OBJ or GLTF will be change to GLTF2 in the Editor
import { GLTFLoader } from '@babylonjs/loaders/glTF/2.0/glTFLoader';
import { GLTFFileLoader } from '@babylonjs/loaders/glTF/glTFFileLoader';
import { Tools } from '@babylonjs/core/Misc/tools';

//* For animated models
import '@babylonjs/core/Animations/animatable';

//* Now we only use ENV file transfered in the editor
// import '@babylonjs/core/Misc/dds';
// import '@babylonjs/core/Materials/Textures/Loaders/ddsTextureLoader';
// import '@babylonjs/core/Materials/Textures/hdrcubeTexture';
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader';
import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { Scene } from '@babylonjs/core/scene';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { PBRCustomMaterial } from '@babylonjs/materials/custom/pbrCustomMaterial';

import { EXPORT_FILTER_PREFIX } from './interface';
import { Observable } from '../../Utils/observable';
import { getUrlExtension } from '../../Utils/html';

// * Replace material loader to be able to add custom shader
function Custom(loader) {
    this.name = 'custom';
    this.enabled = true;

    this.createMaterial = function (context, material, babylonDrawMode) {
        // Default PBR settings from BabylonJS GLTF Loader
        loader._babylonScene._blockEntityCollection = !!loader._assetContainer;
        // const babylonMaterial = new PBRMaterial(name, loader._babylonScene);
        const babylonMaterial = new PBRCustomMaterial(`${material.name}_Custom`, loader.babylonScene);
        babylonMaterial._parentContainer = loader._assetContainer;
        loader._babylonScene._blockEntityCollection = false;
        // Moved to mesh so user can change materials on gltf meshes: babylonMaterial.sideOrientation = loader._babylonScene.useRightHandedSystem ? Material.CounterClockWiseSideOrientation : Material.ClockWiseSideOrientation;
        babylonMaterial.fillMode = babylonDrawMode;
        babylonMaterial.enableSpecularAntiAliasing = true;
        babylonMaterial.useRadianceOverAlpha = !loader._parent.transparencyAsCoverage;
        babylonMaterial.useSpecularOverAlpha = !loader._parent.transparencyAsCoverage;
        babylonMaterial.transparencyMode = PBRMaterial.PBRMATERIAL_OPAQUE;
        babylonMaterial.metallic = 1;
        babylonMaterial.roughness = 1;
        return babylonMaterial;
    };
}

// GLTFLoader.RegisterExtension('custom', (loader) => new Custom(loader));

export const MODEL_EXTENSION = ['gltf', 'glb', 'GLTF', 'GLB'];

export const isRightModelExtension = (url: string): boolean => {
    const extension = getUrlExtension(url);
    if (!MODEL_EXTENSION.includes(extension)) {
        return false;
    }
    return true;
};

interface IAsset {
    type: 'material' | 'cubetexture' | 'model',
    url: string
}

export enum LoaderEvent {
    Success,
    Error,
    Progress,
    Finish,
}

interface ILoaderEventData {
    remaining: number;
    total: number;
    type?: IAsset['type'],
    success?: boolean,
    url?: string,
    error?: string,
}

/**
 * For the model asset, the url path must be splited
 * @param url Url of the model
 */
const getModelPath = (url: string) => {
    const path = { file: '', folder: '' };
    const urlsplit = url.split('/');
    path.file = urlsplit.pop();
    path.folder = `${urlsplit.join('/')}/`;
    return path;
};

const hideMeshes = (meshes: Mesh[]) => {
    meshes.forEach((mesh) => {
        mesh.isVisible = false;
        mesh.isPickable = false;
    });
};

const ignoreMeshes = (meshes: Mesh[]) => {
    meshes.forEach((mesh) => {
        mesh.name = EXPORT_FILTER_PREFIX + mesh.name;
    });
};

/**
 * Clone the main model parent in order to duplicate a model
 * @param modelParents List of model parents
 */
const getClonedParentModel = (modelParents: Mesh[]) => {
    const newModelParents: Mesh[] = [];
    modelParents.forEach((modelParent) => {
        // Clone meshes on order to have a new model
        newModelParents.push(modelParent.clone());
    });
    return newModelParents;
};

/**
 * Loop which get the last parent of a Mesh
 * @param mesh mesh which parent need to be find
 */
const getMeshRootParent = (mesh: AbstractMesh) => {
    while (mesh.parent) {
        mesh = mesh.parent;
    }
    return mesh;
};

/**
 * Look for all the main model parent
 * @param meshes List of model meshes
 */
const getModelParents = (meshes: AbstractMesh[]) => {
    const mainParentListId: string[] = [];
    const mainParentList: any[] = [];
    meshes.forEach((mesh) => {
        const rootParent = getMeshRootParent(mesh);
        if (mainParentListId.indexOf(rootParent.id) === -1) {
            mainParentListId.push(rootParent.id);
            mainParentList.push(rootParent);
        }
    });
    return mainParentList;
};

/**
 * Check if model uses instancedMesh
 * @param meshes List of model meshes
 */
const checkInstanceInModel = (meshes: Mesh[]) => {
    let test = false;
    meshes.forEach((mesh) => {
        if (mesh instanceof InstancedMesh) test = true;
    });
    return test;
};

/**
 * Manage the loading of any asset type from cubetexture, models, etc
 */

export class ToolLoader extends Observable<LoaderEvent, ILoaderEventData> {
    /**
     * @ignore
     */
    private _scene: Scene;

    private gltfLoader: GLTFLoader

    /**
     * List the current assets loading by type
     */
    private remainingLoad: any;

    private totalLoad: any;

    /**
     * Creates a new loader
     * @param scene AssetsManager need to know to what scene the assets will be loaded
     */
    constructor(scene: Scene) {
        super('Loader');
        this._scene = scene;
        this.reset();
        const gLTFFileLoader = new GLTFFileLoader();
        this.gltfLoader = new GLTFLoader(gLTFFileLoader);
        this.gltfLoader._babylonScene = scene;
    }

    /**
     * The list of all possible asset type
     */
    private assetTypeList = ['material', 'cubetexture', 'model', 'upload'];

    /**
     * Store the functions waiting for a callback when asset will be loaded
     */
    private successes: any = {};

    /**
     * Get one asset, before loading it will check if the asset doesn't already exist
     * @param type The type of asset to be loaded
     * @param url The path to where the asset must be loaded
     * @param callback Function called once loading is over
     */
    public getAsset(type: IAsset['type'], url: string, callback?: (asset) => void): void {
        if (url && url.length !== 0) {
            const name = type + url;
            if (this.successes[name] !== undefined) {
                if (callback) this.successes[name].push(callback);
            } else {
                this.successes[name] = [];
                if (callback) this.successes[name].push(callback);
                this.remainingLoad[type]++;
                this.totalLoad[type]++;
                this.loadAsset(type, url);
            }
        } else {
            console.error('missing asset url when loading', type);
        }
    }

    public getCubeTexture(url: string, callback: (asset) => void): void {
        this.getAsset('cubetexture', url, callback);
    }

    public getModel(url: string, callback: (asset) => void): void {
        this.getAsset('model', url, callback);
    }

    /**
     * Load one asset
     * @param type The type of asset to be loaded
     * @param url The path to where the asset must be loaded
     * @param callback Function called once loading is over
     */
    public loadAsset(type: IAsset['type'], url: string): void {
        const name = type + url;
        if (type === 'model') {
            this.loadModel(url, name, (success, asset) => {
                if (success) this.success(type, url, name, asset);
                else this.error(type, name, asset);
            });
        } else if (type === 'cubetexture') {
            this.loadCubeTexture(url, name, (success, asset) => {
                if (success) this.success(type, url, name, asset);
                else this.error(type, name, asset);
            });
        } else if (type === 'material') {
            this.loadMaterial(url, name, (success, asset) => {
                if (success) this.success(type, url, name, asset);
                else this.error(type, name, asset);
            });
        }

        // this.getFileSize(type, url);
    }

    /**
     * @ignore
     */
    public loadModel(url: string, name: string, callback: (success: boolean, asset) => void): void {
        if (!isRightModelExtension(url)) {
            this.error('model', `${name} - ${url}`, 'Naker only accept GLTF or GLB file for models');
            return;
        }

        const modelpath = getModelPath(url);
        SceneLoader.ImportMesh('', modelpath.folder, modelpath.file, this._scene, (meshes, particleSystems, skeletons, animationGroups) => {
            callback(true, { loadedMeshes: meshes, loadedAnimationGroups: animationGroups });
        }, null, (scene, message) => {
            callback(false, message);
        });
    }

    public loadMaterial(
        url: string,
        name: string,
        callback: (success: boolean, asset) => void
    ): void {
        Tools.LoadFile(url, (data: string) => {
            const gltfMaterial = JSON.parse(data);
            // console.log(gltfMaterial);
            const material = this.gltfLoader.createMaterial(name, gltfMaterial, 0);
            callback(true, material);
        });
    }

    /**
     * @ignore
     */
    public loadCubeTexture(
        url: string,
        name: string,
        callback: (success: boolean, asset) => void
    ): void {
        const extension = getUrlExtension(url);
        if (extension !== 'env') {
            this.error('cubetexture', name, 'Naker only accept ENV file for environement texture');
            return;
        }
        const texture = new CubeTexture(url, this._scene, null, false, null, () => {
            callback(true, { texture });
        });
    }

    /**
     * When an asset finish loading, we check the asset data ad return the result to the callback
     * @param type The type of asset successfully loaded
     * @param url The path to where the asset must be loaded
     * @param name Name of the asset
     * @param task Object which contains the asset data
     */
    private success(
        type: IAsset['type'],
        url: string,
        name: string,
        asset
    ) {
        try {
            // Models use different success function to handle animations
            if (type === 'model') {
                this.modelSuccess(url, name, asset);
            } else {
                this.successes[name].forEach((success) => {
                    success(asset);
                });
            }
            // Must delete success url or loading the same file later will not work
            delete this.successes[name];
            this.remainingLoad[type]--;
            this.notify(LoaderEvent.Success, {
                type, success: true, url, remaining: this.remaining, total: this.total
            });
            this.checkFinished();
        } catch (e) {
            this.error(type, name, e);
        }
    }

    /**
     * When an asset didn't load correctly, we send a false result to the waiting callback
     * @param type The type of asset not successfully loaded
     * @param name Name of the asset
     * @param error Object which contains the error
     */
    private error(type: IAsset['type'], name: string, message: string) {
        // console.log('error', type, url)
        // console.log(error.errorObject.exception.message)
        // Some loading get several error and sometimes success is undefined
        if (this.successes[name]) {
            this.successes[name].forEach((success) => {
                success(false);
            });
        }

        // Must delete success url or loading the same file later will not work
        delete this.successes[name];
        // Means there has been a success event before and that the error comes from this success
        // So we log the error or we won't be able to see where the issue is coming from
        if (!this.remainingLoad[type]) {
            // Do nothing to avoid callback look as success already sent event
            console.error('Loader error occured after success');
        } else {
            this.remainingLoad[type]--;
            this.checkFinished();
            this.notify(LoaderEvent.Error, {
                type, success: false, error: message, remaining: this.remaining, total: this.total
            });
        }
        console.error('Loader error', type, message);
    }

    remaining = 0;

    total = 0;

    public checkFinished(): boolean {
        this.remaining = 0;
        this.total = 0;
        const keys = Object.keys(this.remainingLoad);
        keys.forEach((key) => {
            this.remaining += this.remainingLoad[key];
            this.total += this.totalLoad[key];
        });

        if (!this.remaining) {
            this.reset();
            this.notify(LoaderEvent.Finish, { remaining: this.remaining, total: this.total });
            return true;
        }
        this.notify(LoaderEvent.Progress, { remaining: this.remaining, total: this.total });
        return false;
    }

    /**
     * Initiate the loading types
     */
    private reset(): void {
        this.remainingLoad = {};
        this.totalLoad = {};
        this.assetTypeList.forEach((asset) => {
            this.remainingLoad[asset] = 0;
            this.totalLoad[asset] = 0;
        });
    }

    /**
     * For the models we differenciate several model type on success
     * => normal, animated and instanciated
     * @param url The path to where the asset must be loaded
     * @param name Name of the asset
     * @param task Object which contains the asset data
     */
    private modelSuccess(url: string, name: string, task) {
        const meshes = task.loadedMeshes;
        if (meshes === undefined) {
            console.warn('Missing meshes in model file');
        } else {
            const animations = task.loadedAnimationGroups;
            hideMeshes(meshes);
            if (animations.length !== 0) {
                // this.ignoreMeshes(meshes); // Ignore because we will reload it
                this.modelSuccessWithAnimation(url, name, task);
            } else if (checkInstanceInModel(task.loadedMeshes)) {
                this.modelSuccessWithInstance(url, name, task);
            } else {
                this.modelSuccessWithoutAnimation(meshes, name);
            }
        }
    }

    /**
     * When model have animation we can't clone it and keep animation attach to meshes
     * So we reload it in order to make sure every model as its own animations
     * @param url The path to where the asset must be loaded
     * @param name Name of the asset
     * @param task Object which contains the asset data
     */

    // See topic here: https://forum.babylonjs.com/t/how-to-clone-a-glb-model-and-play-seperate-animation-on-each-clone/2351/10
    private modelSuccessWithAnimation(url: string, name: string, task) {
        const successes = this.successes[name];
        const modelParents = getModelParents(task.loadedMeshes);
        successes[0](modelParents, task.loadedAnimationGroups);
        if (successes.length === 1) return;
        const modelpath = getModelPath(url);
        // Animation can't be duplicated so we have to download model everytime
        successes.slice(1, successes.length).forEach((success) => {
            SceneLoader.ImportMesh(
                null,
                modelpath.folder,
                modelpath.file,
                this._scene,
                (meshes, particleSystems, skeletons, animationGroups) => {
                    const modelParents = getModelParents(meshes);
                    success(modelParents, animationGroups);
                }
            );
        });
    }

    /**
     * When model have instance we can't clone it
     * So we reload it in order to make sure it works
     * @param url The path to where the asset must be loaded
     * @param name Name of the asset
     * @param task Object which contains the asset data
     */
    private modelSuccessWithInstance(url: string, name: string, task) {
        const successes = this.successes[name];
        const modelParents = getModelParents(task.loadedMeshes);
        successes[0](modelParents);
        if (successes.length === 1) return;
        const modelpath = getModelPath(url);
        // Animation can't be duplicated so we have to download model everytime
        successes.slice(1, successes.length).forEach((success) => {
            SceneLoader.ImportMesh(
                null,
                modelpath.folder,
                modelpath.file,
                this._scene,
                (meshes, particleSystems, skeletons) => {
                    const modelParents = getModelParents(meshes);
                    success(modelParents);
                }
            );
        });
    }

    /**
     * Normal success function for model wihtou instance or animations
     * @param url The path to where the asset must be loaded
     * @param name Name of the asset
     * @param task Object which contains the asset data
     */
    private modelSuccessWithoutAnimation(meshes: Mesh[], name: string) {
        const successes = this.successes[name];
        // If no animation we save meshes for easy and fast model duplication
        const modelParents = getModelParents(meshes);
        successes[0](modelParents);
        if (successes.length === 1) return;

        successes.slice(1, successes.length).forEach((success) => {
            //! Cloning parent will change the children name which is annoying for our UI
            //! 03/08/22 Do not clone as this less performant
            //! and we never use twice the same model now
            const newModelParents = getClonedParentModel(modelParents);
            success(newModelParents);
        });
    }
}
