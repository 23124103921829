import React, { FC } from 'react';
import { IProjects, IProject } from 'sharedtypes';

import { useAuthContext } from '~/Context/contextAuth';
import { Card } from './ProjectCard';

import { TDashboardLibrary } from '../../IDashboard';
import env from '~/Services/Environment/environment';

interface IProjectListProps {
    projects: IProjects;
    listType: TDashboardLibrary;
    setSelectedProject: (p: IProject) => void;
    setShowModal: (b: boolean) => void;
}

const FAKE_PROJECT: IProject = {
    _id: 'rere',
    designer: 'pichou',
    name: 'local project',
    team: [],
    //* To test worker screen locally
    worker: {
        id: '',
        working: false,
        name: 'pich',
        socket: ''
    }
};

export const ProjectList: FC<IProjectListProps> = ({
    projects, listType, setSelectedProject, setShowModal
}) => {
    const { user } = useAuthContext();
    const selectProject = (project: IProject) => (): void => {
        setSelectedProject(project);
        setShowModal(true);
    };

    return (
        <>
            {!env.saving //* For local test purpose
                && (
                    <>
                        {[0, 1, 2, 3, 4, 5, 6].map((v) => (
                            <Card
                                key={FAKE_PROJECT._id + v}
                                project={FAKE_PROJECT}
                                selectProject={selectProject(FAKE_PROJECT)}
                                user="pichou"
                                designer={FAKE_PROJECT.designer}
                            />
                        ))}
                    </>
                )}
            {listType === 'personal'
                ? projects.map(
                    (project) => project.designer === user._id && (
                        <Card
                            key={project._id}
                            project={project}
                            selectProject={selectProject(project)}
                            user={user._id}
                            designer={project.designer}
                        />
                    )
                )
                : projects.map(
                    (project) => project.team.length > 1 && (
                        <Card
                            key={project._id}
                            project={project}
                            selectProject={selectProject(project)}
                            user={user._id}
                            designer={project.designer}
                        />
                    )
                )}
        </>
    );
};
