import { IResolution } from 'sharedtypes/math';
import { IScenario, IScenarioImage, IScenarioVideo, IScenarioModel } from 'sharedtypes/rtx/scenario';

import { IStateImage } from '../Image/imageTypes';
import { IStateVideo } from '../Video/videoTypes';
import { IStateModel } from '../Model/modelTypes';

const SERVER_START_TIME = 15;

export const getStringFromSeconds = (secondsNeeded: number): string => {
    const hours = Math.floor(secondsNeeded / 3600);
    const secondesLeftAfterHour = secondsNeeded - hours * 3600;
    const minutes = Math.floor(secondesLeftAfterHour / 60);
    const seconds = secondesLeftAfterHour - minutes * 60;
    if (hours > 0) return `${hours} hour and ${minutes} minutes`;
    if (minutes > 0) return `${minutes} minutes and ${seconds} seconds`;
    return `${seconds} seconds`;
};

const getPixelsToRender = (resolutions: IResolution[]) => {
    let totalPixel = 0;
    resolutions.forEach((resolution) => {
        const pixel = resolution.width * resolution.height;
        totalPixel += pixel;
    });
    return totalPixel;
};

const getTotalImage = (imageState: IStateImage): number => {
    const {
        povs, variants, resolutions, environments
    } = imageState;
    return resolutions.length * variants.length * povs.length * environments.length;
};

const getRenderTimePerImage = (resolutions: IResolution[]): number => {
    const fullPixel = getPixelsToRender(resolutions);
    // Don't know why it can add long decimal otherwise
    return Math.round(fullPixel / 200000);
};

const getRenderTimeImage = (imageState: IStateImage): number => {
    const { povs, variants, resolutions } = imageState;
    const seconds = getRenderTimePerImage(resolutions);
    // Don't know why it can add long decimal otherwise
    return seconds * variants.length * povs.length;
};

const getRenderTimePerVideo = (videoState: IStateVideo): number => {
    const {
        resolution, fps, duration
    } = videoState;
    const seconds = getRenderTimePerImage([resolution]);
    const images = parseFloat(fps) * parseFloat(duration);
    return seconds * images;
};

const getRenderTimeVideoWithVariants = (videoState: IStateVideo): number => {
    const { variants } = videoState;
    return getRenderTimePerVideo(videoState) * variants.length;
};

const getTotalVideo = (videoState: IStateVideo): number => {
    const { variants } = videoState;
    return variants.length;
};

export const getRenderTimePerModel = (modelState: IStateModel): number => {
    const { formats } = modelState;
    const modelToBuild = formats.length;
    return modelToBuild * 20;
};

export const getTimeStringVideo = (videoState: IStateVideo): string => {
    const totalVideo = getTotalVideo(videoState);
    let renderSeconds = getRenderTimeVideoWithVariants(videoState);
    renderSeconds += SERVER_START_TIME;
    const renderTime = getStringFromSeconds(renderSeconds);
    return `${totalVideo} video(s) to render.\nEstimated ${renderTime} required.`;
};

export const getTimeStringImage = (imageState: IStateImage): string => {
    const totalImage = getTotalImage(imageState);
    let renderSeconds = getRenderTimeImage(imageState);
    renderSeconds += SERVER_START_TIME;
    const renderTime = getStringFromSeconds(renderSeconds);
    return `${totalImage} image(s) to render.\nEstimated ${renderTime} required.`;
};

export const getTimeStringModel = (modelState: IStateModel): string => {
    const { variants, formats } = modelState;
    const rendererModel = getRenderTimePerModel(modelState);
    let renderSeconds = variants.length * rendererModel;
    renderSeconds += SERVER_START_TIME;
    const renderTime = getStringFromSeconds(renderSeconds);
    const modelToBuild = variants.length * formats.length;
    return `${modelToBuild} model(s) to create.\nEstimated ${renderTime} required.`;
};

export const getTimeStringScenarios = (variants: number, scenarios: IScenario[]): string => {
    let totalImage = 0;
    let totalVideo = 0;
    let totalModel = 0;
    let totalSeconds = 0;
    scenarios.forEach((scenario: IScenario) => {
        if (scenario.type === 'image') {
            const state = scenario.state as IScenarioImage;
            totalImage += state.povs.length;
            totalSeconds += getRenderTimePerImage(state.resolutions);
        } else if (scenario.type === 'video') {
            const state = scenario.state as IScenarioVideo;
            totalVideo++;
            totalSeconds += getRenderTimePerVideo(state);
        } else if (scenario.type === 'model') {
            const state = scenario.state as IScenarioModel;
            totalModel++;
            totalSeconds += getRenderTimePerModel(state);
        }
    });
    totalImage *= variants;
    totalVideo *= variants;
    totalModel *= variants;
    totalSeconds *= variants;
    totalSeconds += SERVER_START_TIME;
    const renderTime = getStringFromSeconds(totalSeconds);
    return `${totalImage} image(s), ${totalVideo} video(s) and ${totalModel} model(s) to render.\nEstimated ${renderTime} required.`;
};
