import { toast } from 'react-toastify';
import { IDamMaterialUpload } from 'sharedtypes/socket/damEvents';
import { apiSocket } from '~/Api/apiSocket';

const checkUploadedMaterial = (files: File[]): IDamMaterialUpload => {
    if (files.length !== 3) {
        throw new Error('A PBR material must contain 3 files');
    }
    const baseColorFile = files.find((x) => x.name.includes('baseColor'));
    if (!baseColorFile) throw new Error('Missing base color file');
    const normalFile = files.find((x) => x.name.includes('normal'));
    if (!normalFile) throw new Error('Missing normal map file');
    const occlusionFile = files.find((x) => x.name.includes('occlusionRoughnessMetallic'));
    if (!occlusionFile) throw new Error('Missing occlusion file');
    return {
        baseColor: {
            // @ts-ignore
            file: baseColorFile,
            name: baseColorFile.name,
        },
        normal: {
            // @ts-ignore
            file: normalFile,
            name: normalFile.name
        },
        occlusion: {
            // @ts-ignore
            file: occlusionFile,
            name: occlusionFile.name
        },
    };
};

export const uploadModel = (files: File[]): void => {
    apiSocket.getSocket().emit('dam:upload_model', {
        // @ts-ignore
        file: files[0], mimeType: 'application/octet-stream', type: 'model', ext: 'glb'
    }, () => { });
};

export const uploadMaterial = (files: File[]): void => {
    try {
        const material = checkUploadedMaterial(files);
        apiSocket.getSocket().emit('dam:upload_material', {
            ...material
        }, (x) => {
            console.log(x);
        });
    } catch (err) {
        console.log(err);
        toast.error(err as string);
    }
};

export const renameItem = (name: string, key: string): void => {
    apiSocket.getSocket().emit('dam:update', {
        key, body: { name }
    }, (res) => {
        console.log(res);
    });
};
