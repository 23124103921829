import React, { FC } from 'react';
import { View } from '@adobe/react-spectrum';
import {
    BarChart, XAxis, Rectangle, Bar, Tooltip, YAxis
} from 'recharts';

import { IAnIntervalData } from '~/Api/Analytics';
import { ORANGE_MEDIUM } from '~/Utils/color';

interface IChartProps {
    data: IAnIntervalData;
}

export const AnalyticsChart: FC<IChartProps> = ({
    data
}) => (
    <View width="size-4000">
        <BarChart
            width={450}
            height={200}
            data={data}
            margin={{
                top: 5, right: 0, left: -35, bottom: 5
            }}
        >
            <Bar
                dataKey="nb"
                stroke={ORANGE_MEDIUM}
                fill={`${ORANGE_MEDIUM}50`}
                activeBar={<Rectangle fill={ORANGE_MEDIUM} />}
            />
            <XAxis dataKey="simple" />
            <YAxis />
            <Tooltip cursor={false} />
        </BarChart>
    </View>
);
