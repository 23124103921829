import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import {
    ComboBox,
    View,
    Item,
    Text,
    Flex
} from '@adobe/react-spectrum';
import { IUser, UserList } from 'sharedtypes';

import { useAuthContext } from '~/Context/contextAuth';
import { ITeamProps } from '~/Types';
import { DeleteButton } from '~/Components/Delete/deleteButton';
import { apiSocket } from '~/Api/apiSocket';

export const CardCollaborators: FC<ITeamProps> = (
    props: ITeamProps
) => {
    const {
        team,
        worker,
        user,
        project,
        teamIds,
        setTeamIds,
        update,
        getTeam
    } = props;
    const { isPremium } = useAuthContext();

    const [searchTimeout, setSearchTimeout] = useState<
        NodeJS.Timeout | undefined
    >();
    const [searching, setSearching] = useState(false);
    const [suggestions, setSuggestions] = useState<UserList>([]);

    const searchMembers = (value) => {
        if (value && !searching) {
            setSearching(true);
            apiSocket.getSocket().emit('user:search', { name: value }, ({ err, data }) => {
                setSearching(false);
                if (err) {
                    toast.error(
                        'Oups we had an issue while searching user. 🙁'
                    );
                } else {
                    setSuggestions(data);
                }
            });
        }
    };

    const addToTeam = (member: IUser) => {
        if (!member._id) return;
        if (teamIds.indexOf(member._id) !== -1) return;
        if (member._id === user._id) {
            toast.error('You are already on this team');
            return;
        }
        if (isPremium) {
            if (
                (member.premium_level
                    && (member.premium_level as unknown as string) !== 'none')
                || member.admin
            ) {
                teamIds.push(member._id);
                setTeamIds(teamIds);
                update(undefined, () => {
                    getTeam();
                });
            } else {
                toast.error(
                    'You cannot share your project with a non-premium member'
                );
            }
        } else {
            setTimeout(() => {
                window.location.href = '/billing';
            }, 100);
        }

        // if (teamIds.length > 4) {
        //     setSearchValue('Your team is full!');
        //     setReadOnlyInput(true);
        // }
    };
    const removeFromTeam = (member: IUser) => {
        const filtered = teamIds.filter((id: string) => id !== member._id);
        setTeamIds(filtered);
        update(filtered, () => {
            getTeam();
        });
    };

    const onSelectionChange = (key) => {
        suggestions.forEach((member) => {
            if (member._id === key) {
                addToTeam(member);
            }
        });
    };

    const onInputChange = (value) => {
        clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => searchMembers(value), 500));
    };

    return (
        <Flex direction="column" alignItems="start">
            {project.designer === user._id && (
                <ComboBox
                    label="Search collaborator"
                    items={suggestions}
                    onInputChange={onInputChange}
                    onSelectionChange={onSelectionChange}
                    allowsCustomValue
                >
                    {(item) => <Item textValue={item.name} key={item._id}>{item.name}</Item>}
                </ComboBox>
            )}
            <View marginTop="size-400">
                <Flex
                    direction="column"
                    gap="size-100"
                >
                    {team
                        && team.map((member) => (
                            <Flex
                                key={member._id}
                                direction="row"
                                alignItems="center"
                                width="size-2400"
                            >
                                <Text>
                                    {project.designer === member._id
                                        ? `${member.name} (Owner)`
                                        : member.name}
                                </Text>
                                <View flex />
                                {(() => {
                                    if (worker && worker.name === member.name && worker.working) {
                                        return <Text>Working</Text>;
                                    } if (
                                        project.designer === user._id
                                        && project.designer !== member._id
                                    ) {
                                        return (
                                            <DeleteButton
                                                title="collaborator"
                                                onDelete={() => removeFromTeam(member)}
                                            />
                                        );
                                    }
                                    return <></>;
                                })()}

                            </Flex>
                        ))}
                </Flex>
            </View>
        </Flex>
    );
};
