import { Color3 } from '@babylonjs/core/Maths/math';
import '@babylonjs/core/Materials/standardMaterial';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';
import { PBRCustomMaterial } from '@babylonjs/materials/custom/pbrCustomMaterial';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { SerializationHelper } from '@babylonjs/core/Misc/decorators';

import { TTexture, checkAwsIssue } from '../../../../Utils/dam';
import { deg2rad } from '../../../../Utils/math';
import { IMaterialOptions } from '../../../Tool/toolMaterial';
import { PatternColor } from './patternColor';

const setAsGltfMaterial = (material: PBRMaterial) => {
    // material.albedoColor = Color3.White();
    material.roughness = 1;
    material.metallic = 1;
    material.useMetallnessFromMetallicTextureBlue = true;
    material.useRoughnessFromMetallicTextureGreen = true;
    material.useRoughnessFromMetallicTextureAlpha = false;
    // material.subSurface.isRefractionEnabled = true;
};

const PBR_TEXTURES = ['diffuse', 'albedo', 'metallicRoughness', 'refraction', 'reflectivity', 'microSurface', 'bump', 'emissive', 'opacity', 'ambient', 'lightmap', 'detailMap'];
const materialUseTexture = (material: PBRMaterial): boolean => {
    let test = false;
    PBR_TEXTURES.forEach((t) => {
        const texture = material[`${t}Texture`];
        if (texture && texture.isReady()) {
            test = true;
        }
    });
    return test;
};

//* Idea to clone PBRCustom from this topic: https://forum.babylonjs.com/t/custommaterial-clone-not-currently-working/12743
PBRCustomMaterial.prototype.clone = function (name) {
    const result = SerializationHelper.Clone(
        () => new PBRCustomMaterial(name, this.getScene()),
        this
    );
    return result;
};

export const clonePBRCustom = (material: PBRCustomMaterial): PBRCustomMaterial => {
    // Inspired from https://forum.babylonjs.com/t/pbrcustommaterial-support-in-gltf-loader/15618/18
    const mat = material.clone(null) as PBRCustomMaterial;
    return mat;
    if (mat.AddAttribute) {
        mat.needAlphaBlendingForMesh = () => true;

        const text = new Texture('https://playground.babylonjs.com/textures/rock.png');

        // If material uses only one texture, uv attribute will be already defined
        if (materialUseTexture(mat)) {
            mat.Vertex_Definitions('varying vec2 vUV;');
        } else {
            mat.AddAttribute('uv');
            mat.Vertex_Definitions(`
                attribute vec2 uv;
                varying vec2 vUV;
            `);
        }

        mat.Vertex_MainEnd('vUV = uv;');
        mat.Fragment_Definitions('varying vec2 vUV;');

        mat.AddUniform('rocktexture', 'sampler2D', text);
        mat.AddUniform('alphaTransition', 'float', 1);

        mat.Fragment_MainBegin('vec4 alphaText = texture2D(rocktexture, vUV);\n');
        mat.Fragment_Custom_Alpha(`
                float alphaT = alphaText.r * (5. * alphaTransition);
                alpha = (alphaT - 0.5) * 5.;
            `);
    }
    return mat;
};

const setTextureScalRot = (texture: Texture, scale: number, rotation: number) => {
    if (texture) {
        texture.vScale = scale;
        texture.uScale = scale;
        texture.wAng = deg2rad(rotation);
    }
};

// This is the common variable shared between different content
// to set the maxSimultaneousLights properties of materials
export const maxLightforMaterial = 10;

interface IMaterialChangeable {
    alpha?: number,
    metallic?: number,
    roughness?: number,
    emissive?: number,
    bumpHeight?: number,
    textureScaling?: number,
    textureRotation?: number,
    tintColor?: number[]
}

interface IPatternTexture {
    albedoTexture?: Texture,
    bumpTexture?: Texture,
    metallicTexture?: Texture,
}

export class PatternMaterial extends PatternColor {
    public initMaterial(): void {
        if (!this.mesh) return;

        if (this.mesh.material) {
            const material = this.mesh.material.clone(this.mesh.material.name) as PBRCustomMaterial;
            this.material = material;
            if (material.albedoTexture && !material.opacityTexture) {
                material.opacityTexture = material.albedoTexture.clone();
            }
        } else {
            this.deleteMaterial();
            this.material = this.createPBRMaterial();
        }
        this.mesh.material = this.material;
        this.setMaxLights(maxLightforMaterial);
    }

    public applyMaterial(material: IMaterialOptions): void {
        if (material.tintColor !== undefined) this.setTintColor(material.tintColor);
        else this.resetTintColor();

        if (material.albedoTexture !== undefined && material.albedoTexture !== null) {
            this.setTextureUrl('albedoTexture', material.albedoTexture);
        } else {
            this.setTextureUrl('albedoTexture', null);
            delete material.textureScaling;
            delete material.textureRotation;
        }
        if (material.damName) {
            setAsGltfMaterial(this.material);
        }
        this.setTextureUrl('metallicTexture', material.metallicTexture);
        this.setTextureUrl('bumpTexture', material.bumpTexture);

        this.removeMaterialProperties();
        this.setMaterialProperties(material);
    }

    private createPBRMaterial(): PBRMaterial {
        const material = new PBRCustomMaterial(`pbr${this.key}`, this._system.scene);
        setAsGltfMaterial(material);
        return material;
    }

    private setMaxLights(maxLights: number) {
        if (this.material) {
            this.material.maxSimultaneousLights = maxLights;
            // Or object is still visible
            if (maxLights === 0) this.material.directIntensity = 0;
            else this.material.directIntensity = 1;
        }
    }

    protected deleteMaterial(): void {
        if (this.material) this.material.dispose(true, true);
    }

    public setMaterialProperties(prop: IMaterialChangeable): void {
        const keys = Object.keys(prop);
        keys.forEach((key) => {
            this.setMaterialProperty(key, prop[key]);
        });
    }

    private changeParameters = [
        'alpha',
        'metallic',
        'roughness',
        'emissive',
        'bumpHeight',
        'textureScaling',
        'textureRotation'
    ];

    private originalProperties: IMaterialOptions = {}

    public setMaterialProperty(key: string, value: any): void {
        if (this.changeParameters.includes(key)) {
            if (value !== undefined && value !== null) {
                if (key === 'textureScaling') this.setTextureScaling(value);
                else if (key === 'textureRotation') this.setTextureRotation(value);
                else if (key === 'bumpHeight') this.setTextureBump(value);
                else if (key === 'emissive') this.setEmissiveColor(new Color3(value, value, value));
                else {
                    if (this.originalProperties[key] === undefined) {
                        this.originalProperties[key] = this.material[key];
                    }
                    this.material[key] = value;
                }
            } else {
                this.removeMaterialProperty(key);
            }
        }
    }

    public removeMaterialProperties(): void {
        if (this.material) {
            const keys = Object.keys(this.originalProperties);
            keys.forEach((key) => {
                this.removeMaterialProperty(key);
            });
        }
        //! do not erase current origin as it will always be the same
        // this.originalProperties = {};
    }

    protected removeMaterialProperty(key: string): void {
        const prop = this.originalProperties[key];
        if (prop !== undefined) {
            this.material[key] = prop;
            if (key === 'bumpHeight') this.setTextureBump(this.originalProperties[key]);
            if (key === 'textureScaling') this.setTextureScaling(this.originalProperties[key]);
            if (key === 'textureRotation') this.setTextureRotation(this.originalProperties[key]);
            if (key === 'emissive') this.setEmissiveColor(Color3.Black());
        }
    }

    private originalTextures = {};

    //* Used to avoiid loading texture twice
    private currentTextures = {};

    public setTextureUrl = (type: TTexture, textureUrl: string): void => {
        if (textureUrl) {
            this.putNewTexture(type, textureUrl);
        } else {
            this.putOriginalTexture(type);
        }
    }

    private EMPTY_TEXTURE_KEY = 'empty'

    private putNewTexture(type: TTexture, textureUrl: string) {
        if (textureUrl === this.currentTextures[type]) return;
        this.currentTextures[type] = textureUrl;

        const texture = this.material[type] as Texture;
        const originalTexture = this.originalTextures[type];
        // console.log(textureUrl, texture);
        // Can't load from url because of this S3/Chrome issue
        // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
        // We must add a query so that it erases the cache

        textureUrl = checkAwsIssue(textureUrl);
        // Save original texture in case we need to come back to it
        if (!originalTexture && texture) {
            this.originalTextures[type] = texture.url;
            this.savedTexture[type] = texture;
        } else {
            // So that we know original texture is empty
            this.originalTextures[type] = this.EMPTY_TEXTURE_KEY;
        }

        this.updateTextureUrl(type, textureUrl);
    }

    private updateTextureUrl(type: TTexture, url: string) {
        const texture = this.getTextureByType(type);
        // Do not load again if already used
        if (texture && texture.url !== url) {
            if (texture.url) {
                //! Nee to clone it so that it stays visible while loadinig
                const cloneTexture = texture.clone();
                cloneTexture.updateURL(url, null, () => {
                    // In case scale has change
                    cloneTexture.uScale = texture.uScale;
                    cloneTexture.vScale = texture.vScale;
                    texture.dispose();
                    this.material[type] = cloneTexture;
                    this.updateMaterial();
                    this._system.scene.render();
                });
            } else if (url) {
                this.material[type] = texture;
                texture.updateURL(url, null, () => {
                    this.updateMaterial();
                    this._system.scene.render();
                });
            } else {
                delete this.material[type];
            }
        }
    }

    private savedTexture: IPatternTexture = {}

    private getTextureByType(type: TTexture): Texture {
        if (this.savedTexture[type]) {
            return this.savedTexture[type];
        }
        const texture = new Texture(
            null,
            this._system.scene,
            false,
            false,
            Texture.TRILINEAR_SAMPLINGMODE,
            // () => { callback(texture); },
            // (message) => { console.error(`Naker texture: ${message}`); }
        );
        this.savedTexture[type] = texture;
        return texture;
    }

    private putOriginalTexture(type: TTexture) {
        const texture = this.material[type] as Texture;
        const originalTexture = this.originalTextures[type];
        if (texture && texture.url !== originalTexture) {
            if (originalTexture === this.EMPTY_TEXTURE_KEY) {
                this.updateTextureUrl(type, null);
                this.material[type] = undefined;
            } else {
                this.updateTextureUrl(type, originalTexture);
            }
        }
        delete this.currentTextures[type];
    }

    private setTextureBump(bump = 1) {
        const bumpT = this.material.bumpTexture as Texture;
        if (bumpT) {
            if (this.originalProperties.bumpHeight === undefined) {
                this.originalProperties.bumpHeight = bumpT.level;
            }
            //! Do not usse albedoT or it won't work
            // bumpT.level = bump;
            this.material.bumpTexture.level = bump;
        }
    }

    private textureScaling = 1;

    private setTextureScaling(scale = null): void {
        this.textureScaling = scale;
        const { albedoTexture } = this.material;
        if (albedoTexture) {
            if (this.originalProperties.textureScaling === undefined) {
                this.originalProperties.textureScaling = albedoTexture.vScale;
            }
            this.updateMaterial();
        }
    }

    private textureRotation = 0;

    private setTextureRotation(deg = null): void {
        this.textureRotation = deg;
        const { albedoTexture } = this.material;
        if (albedoTexture && deg) {
            if (this.originalProperties.textureRotation === undefined) {
                this.originalProperties.textureRotation = albedoTexture.wAng;
            }
            this.updateMaterial();
        }
    }

    private updateTexturesScalRot() {
        const { albedoTexture, bumpTexture, metallicTexture } = this.material;
        setTextureScalRot(albedoTexture as Texture, this.textureScaling, this.textureRotation);
        setTextureScalRot(bumpTexture as Texture, this.textureScaling, this.textureRotation);
        setTextureScalRot(metallicTexture as Texture, this.textureScaling, this.textureRotation);
    }

    protected updateMaterial(): void {
        if (this.mesh) {
            this.mesh._unFreeze();
            this.mesh._freeze();
            this.updateTexturesScalRot();
        }
        // Material refresh not working
        // this.material.unfreeze();
        // this.material.freeze();
    }

    public destroy(): void {
        this._destroy();
    }

    protected _destroy(): void {
        this.deleteMaterial();
        // In case of PatternDecal, we don't always have a mesh
        if (this.mesh) this.mesh.dispose();
    }
}
