import { Color3, Vector3, Matrix } from '@babylonjs/core/Maths/math';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

import { SystemLayer } from '../../System/systemLayer';
import { PatternTransform } from './patternTransform';

export class PatternLight extends PatternTransform {
    public light: DirectionalLight;

    protected _system: SystemLayer

    constructor(systemLayer: SystemLayer) {
        super(systemLayer);
        this._system = systemLayer;
        const mesh = new TransformNode(`${this.key}light_node`, this._system.scene);
        this.initMesh(mesh);
        this.initLight();
    }

    public initLight(): void {
        this.removeShadowGenerator();
        if (this.light) this.light.dispose();
        this.light = new DirectionalLight(this.key, new Vector3(0, -1, 0), this._system.scene);
        this.light.direction = new Vector3(0, -1, 0);
        this.light.setEnabled(true);
        this.light.parent = this.mesh;

        // this.light.shadowFrustumSize = 0.001;
        // this.light.shadowOrthoScale = 0;
        //! To avoid seing th edge of the blur, we need to pprevent autoUpdateExtends
        // See https://forum.babylonjs.com/t/best-shadow-parameters-for-specific-scene/19722/14
        this.light.autoUpdateExtends = false;
        const ortho = 1;
        this.light.orthoLeft = -ortho;
        this.light.orthoRight = ortho;
        this.light.orthoTop = ortho;
        this.light.orthoBottom = -ortho;
    }

    private intensity = 0;

    public setIntensity(intensity: number): void {
        this.intensity = intensity;
        this.setPower();
    }

    private specularIntensity = 0;

    public setSpecularIntensity(intensity: number): void {
        this.specularIntensity = intensity;
        this.checkSpecular();
    }

    // Replace setAngleAxis to set light direction too
    public setAngleAxis(angles: Vector3): void {
        if (!this.light) return;
        this._setAngleAxis(angles);
        this.checkLightPosition();
        this._system.resetShadows();
    }

    private checkLightPosition() {
        // In order to have the best shadow, directionnal light need to be in a specific position
        const matr = new Matrix();
        const center = this._system.parentCamera.position;
        const scale = this._system.visibleWorld / 10;
        this.mesh.rotationQuaternion.toRotationMatrix(matr);
        const direction = Vector3.TransformCoordinates(new Vector3(0, -1, 0), matr);
        const position = direction.normalize().scale(-scale).add(center);
        this.mesh.position = position;
    }

    public setColor(color: number[] = [0, 0, 0]): void {
        const babyloncolor = Color3.FromInts(color[0], color[1], color[2]);
        this.light.diffuse = babyloncolor;
        this.checkSpecular();
    }

    private checkSpecular() {
        if (!this.light.diffuse) return;
        const specInt = this.specularIntensity;
        const specularColor3 = new Color3(specInt, specInt, specInt);
        this.light.setEnabled(false);
        // this.light.specular = this.light.diffuse.multiply(specularColor3);
        this.light.specular = specularColor3;
        this._system.checkActiveMeshes();
        this.light.setEnabled(true);
    }

    private directionalRatio = 0.01;

    private spotRatio = 2;

    private pointRatio = 1;

    private setPower() {
        this.light.intensity = this.directionalRatio * this.intensity;
        this.light.shadowMinZ = 0;
        this.light.shadowMaxZ = 0.5 * this._system.visibleWorld;
    }

    private shadowGenerator: ShadowGenerator;

    //! Must be a power of 2
    // https://forum.babylonjs.com/t/issues-between-shadowgenerator-and-ios-osx/795/20
    private shadowSize = 2048

    private addShadowGenerator() {
        this.removeShadowGenerator();
        this.shadowGenerator = new ShadowGenerator(this.shadowSize, this.light);
        this.shadowGenerator.enableSoftTransparentShadow = true;
        this.shadowGenerator.blurBoxOffset = 2;

        this.shadowGenerator.useKernelBlur = false;
        this.shadowGenerator.useBlurExponentialShadowMap = false;
        this.shadowGenerator.usePercentageCloserFiltering = false;

        if (this.groundLight) {
            this.shadowGenerator.useKernelBlur = true;
            this.shadowGenerator.useBlurExponentialShadowMap = true;
            // this.shadowGenerator.useBlurCloseExponentialShadowMap = true;
            this.shadowGenerator.depthScale = 1;
            // * Really depends on the scene
            // Hard to have shadows parameters working all the time
            this.shadowGenerator.bias = 0.1;
        } else {
            this.shadowGenerator.usePercentageCloserFiltering = true;
            this.shadowGenerator.depthScale = 50;
            // * Really depends on the scene
            // Hard to have shadows parameters working all the time
            this.shadowGenerator.bias = 0.001;
        }

        // this.shadowGenerator.forceBackFacesOnly = true;
        this.shadowGenerator.filteringQuality = ShadowGenerator.QUALITY_LOW;
        this._system.addShadowGenerator(this.shadowGenerator);
    }

    private removeShadowGenerator() {
        if (this.shadowGenerator !== undefined) {
            this._system.removeShadowGenerator(this.shadowGenerator);
            this.shadowGenerator = undefined;
        }
    }

    private groundLight = false

    public setGroundLight(): void {
        this.groundLight = true;
        this.setSpecularIntensity(0);
    }

    public setShadowDarkness(darkness?: number): void {
        if (darkness) {
            if (!this.shadowGenerator) this.addShadowGenerator();
            this.shadowGenerator.setDarkness(1 - darkness);
        } else if (this.shadowGenerator) {
            this.removeShadowGenerator();
        }
        this.setShadowSoftness(this.shadowSoftness);
    }

    // private checkMapSize() {
    //     // Kernel compensate the need for shadow quality
    //     // To keep a fluide experience, we decrease the mapsize when kernel increase
    //     //! Create bia on the shadow when changing mapSize
    //     //! better to keep 768
    // if(this.shadowGenerator) {
    //     this.shadowGenerator.mapSize = Math.round(this.shadowSize / (1 + 2 * this.shadowSoftness) / 500) * 500;
    // }
    // }

    private sofnessEnabled = false

    public setSofnessEnabled(sofnessEnabled: boolean): void {
        this.sofnessEnabled = sofnessEnabled;
    }

    private shadowSoftness = 0;

    private blurScale = 20;

    public setShadowSoftness(softness: number): void {
        this.shadowSoftness = softness;
        if (!this.sofnessEnabled || !this.light.isEnabled()) return;
        if (this.shadowGenerator !== undefined) {
            const kernelQuality = this._system.visibleWorld * this.blurScale;
            const kernel = softness * kernelQuality;
            this.shadowGenerator.blurKernel = 1 + kernel;
            // this.checkMapSize();
        }
        this._system.checkActiveMeshes();
        this._system.resetShadows();
    }

    public show(): void {
        if (this.light) this.light.setEnabled(true);
        this.setShadowSoftness(this.shadowSoftness);
        // Make sure parent is scaled or light is buggy
        this.mesh.scaling = Vector3.One();
        this._show();
    }

    public hide(): void {
        if (this.light) this.light.setEnabled(false);
        this._hide();
    }

    public destroy(): PatternLight {
        this.mesh.dispose();
        this.light.dispose();
        this.removeShadowGenerator();
        this._system.checkActiveMeshes();
        return this;
    }
}
