import React, { FC, useState } from 'react';
import {
    Flex,
    Heading,
    Text,
    View,
    StatusLight,
    TableView,
    TableHeader,
    TableBody,
    Column,
    Row,
    Cell,
    Picker,
    Item
} from '@adobe/react-spectrum';
import filter from 'lodash/filter';

import { useAuthContext } from '~/Context/contextAuth';
import { checkCsvFile, IVariantImport } from '~/Services/Scenario/scenarioCsv';
import { UploadButton } from '~/Components/Upload/uploadButton';
import { DAM_LIST, setUserDam } from '~/Utils/dam';

export interface IScenarioModelsProp {
    onSelect: (variants: IVariantImport[]) => void;
}

export const ScenarioModels: FC<IScenarioModelsProp> = ({
    onSelect,
}: IScenarioModelsProp) => {
    const { isAdmin } = useAuthContext();
    const [items, setItems] = useState<IVariantImport[]>(null);
    const [disabledItems, setDisabledItems] = useState<string[]>([]);
    const [csvError, setCsvError] = useState<string>();
    const modelcolumns = [
        { name: 'ID', key: 'id', width: 200 },
        { name: 'Name', key: 'name', width: 200 },
        { name: 'error', key: 'error' }
    ];

    const onDrop = (files) => {
        checkCsvFile(files, (varArr) => {
            setItems(varArr);
            const errorVariant = filter(varArr, (i) => i.error);
            const disabled = errorVariant.map((i) => i.id);
            setDisabledItems(disabled);
        }, (e) => {
            setCsvError(e);
        });
    };

    const selectionChange = (keys: Set<string> | string) => {
        if (keys === 'all') {
            onSelect(items);
        } else {
            const selectedItems = filter(items, (i: IVariantImport) => keys.has(i.id));
            onSelect(selectedItems);
        }
    };

    return (
        <Flex direction="column" gap="size-100">
            <Flex direction="row" alignItems="end">
                <Heading level={3} marginBottom="0">Select Products</Heading>
                <View flex />
                <Flex direction="row" alignItems="end" gap="size-100">
                    {csvError
                        && (
                            <Text>{csvError}</Text>
                        )}
                    <UploadButton
                        extensions={['.csv']}
                        label="CSV"
                        onDrop={onDrop}
                    />
                    {isAdmin
                    && (
                        <Picker
                            label="Set User DAM (Admin only)"
                            width="size-2000"
                            items={DAM_LIST}
                            onSelectionChange={setUserDam}
                        >
                            {(item) => <Item key={item.damName}>{item.damName}</Item>}
                        </Picker>
                    )}
                </Flex>
            </Flex>

            {items
                && (
                    <TableView
                        aria-label="Model list"
                        selectionMode="multiple"
                        onSelectionChange={selectionChange}
                        disabledKeys={disabledItems}
                        isQuiet
                    >
                        <TableHeader columns={modelcolumns}>
                            {(column) => (
                                <Column width={column.width}>
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={items}>
                            {(model) => (
                                <Row key={model.id}>
                                    {(key) => {
                                        if (key === 'error') {
                                            return (
                                                <Cell>
                                                    <StatusLight variant={model.error ? 'negative' : 'positive'}>
                                                        {model.error || 'No error'}
                                                    </StatusLight>
                                                </Cell>
                                            );
                                        } return (<Cell>{model[key]}</Cell>);
                                    }}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                )}
        </Flex>

    );
};
