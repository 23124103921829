import React, {
    FC, useState, useEffect, ReactNode,
} from 'react';
import {
    ActionButton, Flex, Grid, Heading, Text, View, Divider, DialogContainer
} from '@adobe/react-spectrum';
import { toast } from 'react-toastify';

import { LocalStorage, api } from '~/Api';
import { IBilling } from '~/Types';
import { useAuthContext } from '~/Context/contextAuth';
import BillingConfirmationDialog from './billingConfirmationDialog';
import BillingSuccessDialog from './billingSuccessDialog';
import './billing.css';

interface ITemplateProps {
    heading?: string
    children?: ReactNode
}

const Template: FC<ITemplateProps> = ({
    heading,
    children,
}: ITemplateProps) => (
    <Flex direction="column" rowGap="size-600">
        {heading && (
            <View
                borderColor="dark"
                backgroundColor="gray-400"
                borderRadius="large"
            >
                <Flex direction="column" alignItems="center">
                    <Heading level={2}>{heading}</Heading>
                </Flex>
            </View>
        )}

        {children}
    </Flex>
);

interface ISubscription {
    plan: string
}
const Billing: FC = () => {
    const [publishableKey, setPublishableKey] = useState('');
    const [userConnected, setUserConnected] = useState(false);
    const [userStatus, setUserStatus] = useState('none');
    const [subscriptionPlan, setSubscriptionPlan] = useState<ISubscription>();
    const [isOpen, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const authContext = useAuthContext();
    const contextUser = authContext.user;

    const removeSubscription = () => {
        api.delete('billing/subscription', {}, (data) => {
            toast.success(`${data}. Refreshing the page...`);
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        });
    };

    const update = (value: string) => {
        setSubscriptionPlan({ ...subscriptionPlan, plan: value });
    };

    const updateSubscription = (() => {
        if (isOpen) {
            fetch('https://api.airtable.com/v0/appSASf6ZunmTjGAN/Billing%20Request', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer keyvYd17RuctTa2Ln',
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    records: [{
                        fields: {
                            Name: contextUser.name,
                            Email: contextUser.email,
                            Forfait: subscriptionPlan.plan
                        }
                    }]
                })
            }).catch((error) => console.error(error));
        }
    });

    useEffect(() => {
        if (!userConnected) {
            const userCookie = LocalStorage.get();
            if (userCookie === undefined) window.location.href = '/login';
            else setUserConnected(true);
        }
        if (userConnected && !publishableKey) {
            setPublishableKey('void');
            api.get('billing/setup', {}, (json: IBilling) => {
                const { publishableKey } = json;
                setPublishableKey(publishableKey);
                const { userStatus } = json;

                if (userStatus && userStatus !== 'none') {
                    setUserStatus(userStatus);
                }
            });
        }
    }, [
        setUserConnected,
        publishableKey,
        userConnected,
        update
    ]);

    useEffect(() => {
        updateSubscription();
    }, [subscriptionPlan, isOpen]);

    const Photography = () => (
        <Template heading="Virutal Photography">
            <div
                id="starter-plan-btn"
                role="button"
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                    update('Virtual Photography Starter');
                    setConfirm(true);
                }}
                tabIndex={0}
            >
                <View
                    backgroundColor="gray-100"
                    padding="size-200"
                    borderRadius="large"
                >
                    <Flex direction="row" alignItems="center" gap="size-1000">
                        <Heading level={2}>Starter</Heading>
                        <View width="100%">
                            <Flex direction="column" alignItems="end">
                                <Heading level={1} UNSAFE_className="price">300€</Heading>
                                <Text>per 100 Packshots</Text>
                            </Flex>
                        </View>
                    </Flex>
                </View>
            </div>
            <div
                role="button"
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                    update('Virtual Photography Booster');
                    setConfirm(true);
                }}
                tabIndex={0}
            >
                <View
                    backgroundColor="gray-100"
                    padding="size-200"
                    borderRadius="large"
                >
                    <Flex direction="row" alignItems="center" gap="size-1000">
                        <Heading level={2}>Booster</Heading>
                        <View width="100%">
                            <Flex direction="column" alignItems="end">
                                <Heading level={1} UNSAFE_className="price">500€</Heading>
                                <Text>per 150 Packshots</Text>
                            </Flex>
                        </View>
                    </Flex>
                </View>
            </div>
        </Template>
    );

    const Viewer = () => (
        <Template heading="Ecommerce Viewer">
            <div
                role="button"
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                    update('Ecommerce Viewer Starter');
                    setConfirm(true);
                }}
                tabIndex={0}
            >
                <View
                    backgroundColor="gray-100"
                    padding="size-200"
                    borderRadius="large"
                >
                    <Flex direction="row" alignItems="center" gap="size-1000">
                        <Heading level={2}>Starter</Heading>
                        <View width="100%">
                            <Flex direction="column" alignItems="end">
                                <Heading level={1} UNSAFE_className="price">100€</Heading>
                                <Text>per 10 000 views</Text>
                            </Flex>
                        </View>
                    </Flex>
                </View>
            </div>
            <div
                role="button"
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                    update('Ecommerce Viewer Booster');
                    setConfirm(true);
                }}
                tabIndex={0}
            >
                <View
                    backgroundColor="gray-100"
                    padding="size-200"
                    borderRadius="large"
                >
                    <Flex direction="row" alignItems="center" gap="size-1000">
                        <Heading level={2}>Booster</Heading>
                        <View width="100%">
                            <Flex direction="column" alignItems="end">
                                <Heading level={1} UNSAFE_className="price">500€</Heading>
                                <Text>per 50 000 views</Text>
                            </Flex>
                        </View>
                    </Flex>
                </View>
            </div>
        </Template>
    );

    const UnsubscribeButton = () => (
        <View borderRadius="regular" borderWidth="thin">
            <ActionButton
                onPress={() => removeSubscription()}
            >
                <Text>CLICK HERE TO UNSUBSCRIBE</Text>
            </ActionButton>
        </View>
    );

    return (
        <>
            <View backgroundColor="gray-200" padding="size-100">
                <Flex direction="column" height="100%" alignItems="center">
                    <View padding="size-200">
                        <Heading level={1}>
                            You are currently
                            {' '}
                            {userStatus === 'none' ? 'not subscribed' : `a ${userStatus}`}
                        </Heading>
                        <Heading level={2}>Choose a subscription plan</Heading>
                    </View>
                    <Grid
                        height="100vh"
                        rows={['size-1600', 'size-1600', 'size-1600', 'size-1600']}
                    >
                        <Flex direction="row" gap="size-600">

                            <Photography />
                            <Divider orientation="vertical" height="size-6000" />
                            <Viewer />
                        </Flex>
                    </Grid>
                </Flex>
            </View>
            <UnsubscribeButton />
            <DialogContainer onDismiss={() => setConfirm(false)} isDismissable>
                {confirm
                    && (
                        <BillingConfirmationDialog
                            setOpen={setOpen}
                            setClose={() => { setOpen(false); setConfirm(false); }}
                            plan={subscriptionPlan.plan}
                            open={confirm}
                        />
                    )}
            </DialogContainer>
            <DialogContainer onDismiss={() => setOpen(false)} isDismissable>
                {isOpen && <BillingSuccessDialog setOpen={setOpen} open={isOpen} />}
            </DialogContainer>
        </>
    );
};

export default Billing;
