import React, { FC } from 'react';
import { Heading, Link } from '@adobe/react-spectrum';
import { Link as RouterLink } from 'react-router-dom';
import { DashboardHeader } from './dashboardHeader';

export const DashboardPremium: FC = () => (
    <DashboardHeader title="Subscription required">
        <Link isQuiet marginTop="size-50">
            <RouterLink
                className="dashboard-unsubscribed-message"
                to="/billing"
            >
                <Heading>
                    To use Naker Tools and Features, you need to be subscribed.
                    Click here to upgrade.
                </Heading>
            </RouterLink>
        </Link>
    </DashboardHeader>
);
