import React, { FC } from 'react';
import {
    Text, Button,
    Heading, Content, IllustratedMessage
} from '@adobe/react-spectrum';
import { DropZone } from '@react-spectrum/dropzone';
import { FileTrigger } from 'react-aria-components';

import Upload from '@spectrum-icons/illustrations/Upload';
import { toast } from 'react-toastify';

interface IDropzoneProps {
    title: string;
    onDrop: (files: File[]) => void;
    fileTypes: string[];
    acceptedTypes: string[];
    allowsMultiple?: boolean;
}

export const DamDropzone: FC<IDropzoneProps> = ({
    title, onDrop, fileTypes, acceptedTypes, allowsMultiple
}) => {
    const handleMultipleDrops = (files: File[]) => {
        if (acceptedTypes.includes('glb') && files.length > 1) {
            return toast.error('Only one model can be uploaded at a time.');
        }
        return onDrop(files);
    };

    const handleDrop = async (drop) => {
        const item = drop.items[0];
        const items: File[] = [];
        for (let i = 0; i < drop.items.length; i += 1) {
            items.push(await item.getFile());
        }
        return handleMultipleDrops(items);
    };

    const handleBrowse = (drop: FileList) => {
        const items: File[] = [];
        for (let i = 0; i < drop.length; i += 1) {
            items.push(drop.item(i));
        }
        handleMultipleDrops(items);
    };

    return (
        <DropZone
            width="size-3400"
            getDropOperation={(types) => {
                for (let i = 0; i < acceptedTypes.length; i++) {
                    if (types.has(acceptedTypes[i])) { return 'copy'; }
                }
                return 'cancel';
            }}
            onDrop={handleDrop}
        >
            <IllustratedMessage>
                <Upload />
                <Heading>
                    <Text slot="label">
                        {title}
                    </Text>
                </Heading>
                <Content>
                    <FileTrigger
                        onSelect={handleBrowse}
                        acceptedFileTypes={fileTypes}
                        allowsMultiple={allowsMultiple}
                    >
                        <Button variant="primary">Browse</Button>
                    </FileTrigger>
                </Content>
            </IllustratedMessage>
        </DropZone>
    );
};

DamDropzone.defaultProps = {
    allowsMultiple: false
};
