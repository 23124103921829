import * as Sentry from '@sentry/browser';
import { IUser } from 'sharedtypes';

import { CONTEXT, context } from '~/Services/Environment/environment';

// Code to force typescript allow Intercom function
declare global {
    interface Window { Intercom: any; }
}

interface IOptions {
    origin?: string;
    shared?: string;
    engine?: string;
    from?: string;
    vector?: string;
}

export class ApiSpy {
    constructor() {
        this.track('view');
    }

    intercomKey = 'sz0pojg8'

    public identify(user: IUser): void {
        // We don't load intercom when local
        if (!window.Intercom) return;
        if (context === CONTEXT.PROD) {
            window.Intercom('boot', {
                app_id: this.intercomKey,
                name: user.name, // Full name
                email: user.email, // Email address
                user_id: user._id // Email address
                // created_at: “<%= current_user.created_at.to_i %>” // Signup date as a Unix timestamp
            });
        }
    }

    public start(): void {
        if (context === CONTEXT.PROD) {
            window.Intercom('boot', {
                app_id: this.intercomKey
            });
        }
    }

    public track(event: string, options?: IOptions): void {
        if (context === CONTEXT.PROD) {
            window.Intercom('trackEvent', event, options);
        }
    }

    private sentryId = 'https://64462014944f48b3bc58327feb758f7c@sentry.io/1522591';

    public startSentry(): void {
        Sentry.init({
            dsn: this.sentryId
        });
    }
}

const apiSpy = new ApiSpy();
export default apiSpy;
