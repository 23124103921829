import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import './auth.sass';
import { Flex, Image, View } from '@adobe/react-spectrum';

interface IAuthLayoutProps {
    children: ReactElement;
}

const AuthLayout: FC<IAuthLayoutProps> = ({ children }: IAuthLayoutProps) => {
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        setImageWidth(window.innerHeight);
    }, []);

    return (
        <Flex direction="row" position="absolute" height="100%" width="100%">
            <View
                backgroundColor="gray-100"
                height="100%"
                width={`calc(100% - ${imageWidth}px)`}
            >
                {children}
            </View>
            <Image
                right="0px"
                height="100%"
                width={imageWidth}
                position="absolute"
                src="https://d2hiu5ed38be9r.cloudfront.net/Login-min.jpg"
            />
        </Flex>
    );
};

export default AuthLayout;
