import {
    IEasingFunction,
    EasingFunction
} from '@babylonjs/core/Animations/easing';

export enum Ease {
    Linear,
    Circle,
    Back,
    Bounce,
    Cubic,
    Elastic,
    Exponential,
    Power,
    Quadratic,
    Quartic,
    Quintic,
    Sine,
    BezierCurve,
}

export enum EaseMode {
    In,
    Out,
    InOut,
}

export class LinearEase extends EasingFunction implements IEasingFunction {
    /** @hidden */
    public easeInCore(gradient: number): number {
        return gradient;
    }
}
