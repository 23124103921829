import { View } from '@adobe/react-spectrum';
import Camera from '@spectrum-icons/workflow/Camera';
import MovieCamera from '@spectrum-icons/workflow/MovieCamera';
import React, { FC, Key } from 'react';
import { IAnalytic } from 'sharedtypes/analytic';

import { getCleanDate } from '~/Api/Analytics';

interface IUserTableCell {
    k: Key;
    it: IAnalytic;
}

export const UserTableCell: FC<IUserTableCell> = ({ k, it }) => {
    if (k === 'name') {
        if (it.project && it.project.name) {
            return (
                <View>
                    {it.project.name}
                </View>
            );
        }
        return (
            <View>
                {it._id}
            </View>
        );
    }
    if (k === 'type') {
        if (it.type === 'image') return <Camera />;
        return <MovieCamera />;
    }
    if (k === 'quality') {
        return (
            <View>{it.settings.resolution}</View>);
    }
    if (k === 'date') {
        return <View>{getCleanDate(it.created_at)}</View>;
    }
    return (
        <View>
            {it.duration}
        </View>
    );
};
