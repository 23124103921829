import React, { FC } from 'react';
import {
    Flex,
    View,
    Content,
    Heading,
    Dialog,
    Text,
    Button,
    ActionButton,
} from '@adobe/react-spectrum';
import LogOut from '@spectrum-icons/workflow/LogOut';

import { useAuthContext } from '~/Context/contextAuth';
import AccountProfile from './accountProfile';

const AccountDialog: FC = () => {
    const { user, isPremium, logout } = useAuthContext();

    return (
        <Dialog size="XS">
            <Content>
                <AccountProfile />
                <Flex direction="row" gap="size-100" alignItems="center">
                    <Heading level={4}>
                        Level :
                        &nbsp;
                        <Text>
                            {isPremium
                                ? user.premium_level
                                : 'Unsubscribed'}
                        </Text>
                    </Heading>
                    <View flex />
                    <ActionButton onPress={() => window.open('/billing')}>Change Subscription</ActionButton>
                </Flex>
                <Button variant="negative" onPress={() => logout()}>
                    <Text>Disconnect</Text>
                    <LogOut />
                </Button>
            </Content>
        </Dialog>
    );
};

export default AccountDialog;
