import React, {
    FC, useState, useMemo
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Flex,
    Form,
    TextField,
    Image,
    Text,
    Button,
    ProgressCircle,
    Heading,
    Link,
    useProvider
} from '@adobe/react-spectrum';
import { IUser } from 'sharedtypes';

import { useAuthContext } from '~/Context/contextAuth';
import { api } from '~/Api';
import Google from './authGoogle';
import AuthLayout from './authLayout';

import NAKER_DARK from '~/Assets/image/LOGO_DARK.svg';
import NAKER_LIGHT from '~/Assets/image/LOGO_LIGHT.svg';
import { ISuccessResponse } from '~/Types';

interface ILoginProps {
    switchPage: () => void;
}

interface ILoginUser {
    email: string;
    password: string;
    name: string;
}

const Login: FC<ILoginProps> = ({ switchPage }) => {
    const { colorScheme } = useProvider();
    const { login } = useAuthContext();
    const [user, setUser] = useState<ILoginUser>({
        email: '',
        password: '',
        name: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const passwordExists = useMemo(() => !!(user.password),
        [user.password]);
    const nameExists = useMemo(() => !!(user.name),
        [user.name]);
    const nameValid = useMemo(() => user.name && user.name.length >= 6, [user.name]);
    const passwordValid = useMemo(() => user.password
        && user.password.length >= 6, [user.password]);

    const showError = (text: string) => {
        setError(text);
        setLoading(false);
    };

    const showErrorData = (data: ISuccessResponse) => {
        if (data.message) showError(`${data.message} 😕`);
        else showError("Sorry it didn't work 😕");
    };

    const loginUser = (user: IUser) => {
        if (loading) return;
        login(user);
    };

    const tryLogin = () => {
        setError('');
        if (!nameValid || !passwordValid) return;
        setLoading(true);
        const { name, password } = user;
        api.post('user/login', { name, password }, null, (data) => {
            if (data.success) {
                showError('');
                loginUser(data);
            } else {
                showErrorData(data);
            }
        });
    };

    return (
        <AuthLayout>
            <Flex direction="column" alignItems="center">
                <div className="align-height">
                    <Form width="size-3000" height="100%">
                        <Flex
                            direction="column"
                            alignItems="start"
                            gap="size-200"
                        >
                            <Image src={colorScheme === 'dark' ? NAKER_LIGHT : NAKER_DARK} alt="naker-logo" />
                            <Heading>Good to see you again</Heading>
                            <Google
                                loginUser={loginUser}
                                currentPage="login"
                                isUserDisconnected
                            />
                            <Text alignSelf="center">or</Text>
                            <TextField
                                name="name"
                                validationState={nameValid || !nameExists ? 'valid' : 'invalid'}
                                aria-label="textfield"
                                width="100%"
                                label="Username or E-mail"
                                value={user.name || ''}
                                onChange={(v) => {
                                    showError('');
                                    setUser({ ...user, name: v });
                                }}
                                errorMessage={user.name === '' || !user.name
                                    ? 'Empty input not allowed.'
                                    : 'Username must be at least 6 characters.'}
                            />
                            <TextField
                                validationState={passwordValid || !passwordExists ? 'valid' : 'invalid'}
                                aria-label="textfield"
                                width="100%"
                                label="Password"
                                type="password"
                                value={user.password || ''}
                                onChange={(v) => {
                                    showError('');
                                    setUser({ ...user, password: v });
                                }}
                                errorMessage={user.password === '' || !user.password
                                    ? 'Empty input not allowed.'
                                    : 'Password must be at least 6 characters.'}
                            />
                            <Flex
                                direction="column"
                                width="100%"
                                gap="size-100"
                            >
                                <Button
                                    variant="accent"
                                    onPress={tryLogin}
                                    isDisabled={(!nameValid || !passwordValid)
                                                && (passwordExists || nameExists)}
                                >
                                    <Flex
                                        direction="row"
                                        gap="size-100"
                                    >
                                        <Text>Login</Text>
                                        {loading ? (
                                            <ProgressCircle
                                                aria-label="Loading…"
                                                isIndeterminate
                                                size="S"
                                            />
                                        ) : null}
                                    </Flex>
                                </Button>
                                {error ? (
                                    <span className="login-error colorerror">
                                        {error}
                                    </span>
                                ) : null}
                            </Flex>
                            <Link>
                                <RouterLink to="/password">
                                    <Text>
                                        Forgot Password?
                                    </Text>
                                </RouterLink>
                            </Link>
                            <Flex
                                gap="size-100"
                                marginTop="size-500"
                            >
                                <Text>You don&apos;t have an account?</Text>
                                <Link onPress={() => switchPage()}>
                                    <a>Signup</a>
                                </Link>
                            </Flex>
                        </Flex>
                    </Form>
                </div>
            </Flex>
        </AuthLayout>
    );
};

export default Login;
