import find from 'lodash/find';
import { getRandomString } from './math';

export type TTexture = 'albedoTexture' | 'bumpTexture' | 'metallicTexture'
type TQuality = 'low' | 'high';
export type TDam = 'default' | 'adeo';

export interface IDamTextures {
    albedoTexture?: string,
    bumpTexture?: string,
    metallicTexture?: string,
}

interface IDamModel {
    name: TDam,
    mainUrl?: string,
    textures: IDamTextures,
    qualities: {
        before?: boolean,
        low: string,
        high: string,
    }
}

export let userDam: TDam = 'adeo';

export const setUserDam = (dam: TDam) => {
    userDam = dam;
};

interface IDamClient {
    damName: TDam,
    associatedDomains: string[],
}
export const DAM_LIST: IDamClient[] = [
    {
        damName: 'default',
        associatedDomains: ['naker.io']
    },
    {
        damName: 'adeo',
        associatedDomains: ['adeo.com', 'ext.adeo.com']
    }
];

export const checkAwsIssue = (textureUrl: string): string => {
    if (textureUrl.includes('cloudfront') || textureUrl.includes('amazonaws.com')) {
        const fakeQuery = getRandomString();
        textureUrl += `?test=${fakeQuery}`;
    }
    return textureUrl;
};

export const setUserDamFromEmail = (userEmail: string) => {
    DAM_LIST.forEach((damClient) => {
        damClient.associatedDomains.forEach((domain) => {
            if (userEmail.includes(domain)) setUserDam(damClient.damName);
        });
    });
};

export const DamModelList: IDamModel[] = [
    {
        name: 'default',
        textures: {
            albedoTexture: 'baseColor.png',
            bumpTexture: 'normal.png',
            metallicTexture: 'occlusionRoughnessMetallic.png',
        },
        qualities: {
            low: '',
            high: '',
        }
    },
    {
        name: 'adeo',
        textures: {
            albedoTexture: '_Diffuse.png',
            bumpTexture: '_Normal.png',
            metallicTexture: '_ORM.png',
        },
        qualities: {
            low: '',
            high: '',
        }
    }
];

export const getDamModelByName = (name: string): IDamModel => {
    let damModel = find(DamModelList, (d) => d.name === name);
    if (!damModel) damModel = find(DamModelList, (d) => d.name === userDam);
    return damModel;
};

export const getTextureUrl = (
    url: string,
    textureType: TTexture,
    qualityType: TQuality,
    damName?: string,
): string => {
    let damModel = find(DamModelList, (d) => d.name === damName);
    if (!damModel) damModel = find(DamModelList, (d) => d.name === userDam);
    const { textures, qualities, mainUrl } = damModel;
    const texture = textures[textureType];
    const quality = qualities[qualityType];
    if (mainUrl) url = mainUrl + url;
    if (quality.before) {
        return url + quality + texture;
    }
    return url + texture + quality;
};

const checkUrlValid = async (url: URL): Promise<boolean> => {
    const response = await fetch(url);
    return response.status === 200;
};

export const validateUrls = async (
    url: string,
    damName?: string,
): Promise<boolean> => {
    const textureTypes: TTexture[] = ['albedoTexture', 'bumpTexture', 'metallicTexture'];
    const quality = 'low';
    try {
        for (let i = 0; i < textureTypes.length; i += 1) {
            let testUrl = getTextureUrl(url, textureTypes[i], quality, damName);
            testUrl = checkAwsIssue(testUrl);
            const n = new URL(testUrl);
            if (!await checkUrlValid(n)) { throw new Error('Invalid DAM URL'); }
        }
    } catch (err) {
        return false;
    }
    return true;
};

export const getAllTextureUrlbyQuality = (
    url: string,
    qualityType: TQuality,
    damName?: string,
): IDamTextures => ({
    albedoTexture: getTextureUrl(url, 'albedoTexture', qualityType, damName),
    bumpTexture: getTextureUrl(url, 'bumpTexture', qualityType, damName),
    metallicTexture: getTextureUrl(url, 'metallicTexture', qualityType, damName)
});

export const getAllTextureUrlLow = (
    url: string,
    damName?: string,
): IDamTextures => getAllTextureUrlbyQuality(url, 'low', damName);

export const getAllTextureUrlHigh = (
    url: string,
    damName?: string,
): IDamTextures => getAllTextureUrlbyQuality(url, 'high', damName);
