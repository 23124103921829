import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import Login from './authLogin';
import Signup from './authSignup';
import './auth.sass';

type IAuthPages = 'login' | 'signup';

const Auth: FC = () => {
    const [currentPage, setCurrentPage] = useState<IAuthPages>('login');
    const navigate = useNavigate();

    const switchToSignup = () => {
        setCurrentPage('signup');
        navigate('/signup');
    };

    const switchToLogin = () => {
        setCurrentPage('login');
        navigate('/login');
    };

    const checkPath = () => {
        const { pathname } = window.location;
        if (pathname === '/login') switchToLogin();
        else if (pathname === '/signup') switchToSignup();
    };

    useEffect(() => {
        checkPath();
    }, []);

    return (
        <>
            {currentPage === 'login'
                ? (
                    <Login
                        switchPage={switchToSignup}
                    />
                )
                : (
                    <Signup
                        switchPage={switchToLogin}
                    />
                )}
        </>
    );
};

export default Auth;
