import { Vector2, Quaternion } from '@babylonjs/core/Maths/math';
import { Tools } from '@babylonjs/core/Misc/tools';
import { DeviceSourceManager } from '@babylonjs/core/DeviceInput/InputDevices/deviceSourceManager';
import { DeviceType, PointerInput } from '@babylonjs/core/DeviceInput/InputDevices/deviceEnums';
import {
    IPointerEvent, IUIEvent
} from '@babylonjs/core/Events/deviceInputEvents';
import { Catcher } from './catcher';
import { SystemAnimation } from '../System/systemAnimation';
import { Animation } from '../System/Animation/animation';
import { Ease, EaseMode } from '../System/Animation/animationEasing';

// NakerMouseEvent and not MouseEvent otherwise conflict with real window MouseEvent
export enum NakerMouseEvent {
    Move,
    InstantMove,
    Drag,
    DragStart,
    DragStop,
    Wheel,
    Preset
}

const getPinchDistance = (e): number => {
    if (e.touches && e.touches[1] && e.touches[1].pageX) {
        return Math.hypot(
            e.touches[0].pageX - e.touches[1].pageX,
            e.touches[0].pageY - e.touches[1].pageY
        );
    }
    return 0;
};

type TKeyboardMove = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight'
const KEY_EVENT_LIST = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

export class MouseCatcher extends Catcher<NakerMouseEvent, Vector2> {
    private moveAnimation: Animation;

    public dragAnimation: Animation;

    private wheelAnimation: Animation;

    constructor(system: SystemAnimation) {
        super(system, 'MouseCather');
        this.moveAnimation = this.addAnimation();
        this.dragAnimation = this.addAnimation();
        this.wheelAnimation = this.addAnimation();

        window.addEventListener('deviceorientation', (evt) => { this.deviceOrientation(evt); });
        window.addEventListener('orientationchange', () => { this.orientationChanged(); });
        this.orientationChanged();

        // Ask for device motion permission now mandatory on iphone since Safari 13 update
        // https://medium.com/@leemartin/three-things-im-excited-about-in-safari-13-994107ac6295
        // Can't make that work ;/
        // let motionTest = false;
        // container.addEventListener("touchstart", (evt) => {
        //     if (motionTest) return;
        //     motionTest = true;
        //     if (window.DeviceMotionEvent && window.DeviceMotionEvent.requestPermission) {
        //         window.DeviceMotionEvent.requestPermission()
        //             .then(response => {
        //                 console.log(response);
        //                 if (response == 'granted') {
        //                     // permission granted
        //                 } else {
        //                     // permission not granted
        //                 }
        //             });
        //     }
        // });

        window.addEventListener('focus', () => {
            if (this.catching) {
                this.catchMove(this.mousePosition);
            }
        });

        window.addEventListener('mouseleave', (evt) => {
            this.dragging = false;
        });

        this.addPinchEvent();
        this.addDragEvent();
        this.addKeybboardEvent();
    }

    private addAnimation(): Animation {
        const anim = new Animation(this.system, 10);
        anim.setEasing(Ease.Cubic, EaseMode.Out);
        return anim;
    }

    private addKeybboardEvent() {
        window.addEventListener('keydown', (e) => {
            e = e || window.event;
            const { key } = e;
            if (KEY_EVENT_LIST.includes(key)) this.keyboardDrag(key as TKeyboardMove);
        });
    }

    public keyboardDrag(side: TKeyboardMove): void {
        const step = 0.05;
        this.startDrag();
        this.dragStart = Vector2.Zero();
        if (side === 'ArrowUp') {
            this.catchDrag(new Vector2(0, step));
        } else if (side === 'ArrowDown') {
            this.catchDrag(new Vector2(0, -step));
        } else if (side === 'ArrowLeft') {
            this.catchDrag(new Vector2(step, 0));
        } else if (side === 'ArrowRight') {
            this.catchDrag(new Vector2(-step, 0));
        }
        setTimeout(() => {
            this.stopDrag();
        }, 1000);
    }

    private scaling = false

    private addPinchEvent() {
        let distStart = 0;
        this.system.canvas.addEventListener('touchstart', (e) => {
            distStart = getPinchDistance(e);
            if (e.touches.length === 2) {
                this.scaling = true;
            }
        });

        this.system.canvas.addEventListener('touchmove', (e) => {
            if (this.scaling) {
                const dist = getPinchDistance(e);
                e.deltaY = (distStart - dist) / 100;
                this.mouseWheel(e);
            }
        });

        this.system.canvas.addEventListener('touchend', () => {
            this.scaling = false;
        });
    }

    private _deviceInputSystem: DeviceSourceManager

    private _lastEvent: IUIEvent;

    private addDragEvent() {
        this._deviceInputSystem = new DeviceSourceManager(this.system.engine);
        this._deviceInputSystem._onInputChanged = (deviceType, deviceSlot, eventData) => {
            this._lastEvent = eventData;
            const evt = eventData;
            this.getMousePosition(evt as IPointerEvent);

            // Pointer Events
            if (
                deviceType === DeviceType.Mouse
                || deviceType === DeviceType.Touch
            ) {
                // POINTER DOWN
                if (
                    (eventData.inputIndex === PointerInput.LeftClick
                        || eventData.inputIndex === PointerInput.RightClick)
                    && (eventData.type === 'pointerdown' || eventData.type === 'mousedown')
                ) {
                    this.startDrag();
                }

                // POINTER UP
                if (
                    (eventData.inputIndex === PointerInput.LeftClick
                        || eventData.inputIndex === PointerInput.RightClick)
                    && (eventData.type === 'pointerup' || eventData.type === 'mouseup')
                ) {
                    this.stopDrag();
                }

                if (
                    eventData.type === 'pointermove' || eventData.type === 'mousemove'
                ) {
                    // POINTER MOVE
                    this.mouseOrientation(this.mousePosition);
                } else if (
                    eventData.inputIndex === PointerInput.MouseWheelX
                    || eventData.inputIndex === PointerInput.MouseWheelY
                    || eventData.inputIndex === PointerInput.MouseWheelZ
                ) {
                    // POINTER WHEEL
                    this.mouseWheel(evt as WheelEvent);
                }
            }
        };
    }

    public stop(): void {
        this.dragAnimation.stop();
        this.moveAnimation.stop();
        this.wheelAnimation.stop();
        this._stop();
    }

    public preventLastEvent(): void {
        this._lastEvent.preventDefault();
        this._lastEvent.stopPropagation();
    }

    // Code copied from babylon: https://github.com/BabylonJS/Babylon.js/blob/master/src/Cameras/Inputs/freeCameraDeviceOrientationInput.ts
    private screenQuaternion: Quaternion = new Quaternion();

    private constantTranform = new Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5));

    private orientationChanged() {
        // 8 Code by BabylonJS not working anymore
        // const wScreen = window.screen;
        // const wOrient = window.orientation;
        // const screenAngle = ((wScreen).orientation).angle ? ((wScreen).orientation).angle : 0;
        // const yo = (wScreen).orientation && screenAngle;
        // let screenOrientationAngle = (wOrient !== undefined ? +wOrient : yo);

        const orientation = window.screen
            && (screen.orientation
                || screen.msOrientation
                || screen.mozOrientation
                || {}
            );

        let screenOrientationAngle = 0;
        // override with Screen Orientation API if available
        if (orientation && typeof orientation.angle === 'number') {
            screenOrientationAngle = screen.orientation.angle;
        }

        screenOrientationAngle = -Tools.ToRadians(screenOrientationAngle / 2);
        this.screenQuaternion.copyFromFloats(
            0,
            Math.sin(screenOrientationAngle),
            0,
            Math.cos(screenOrientationAngle)
        );
    }

    private divideVector = new Vector2(Math.PI / 8, Math.PI / 8);

    private deviceMaxVector = new Vector2(Math.PI / 4, Math.PI / 4);

    private deviceMinVector = new Vector2(-Math.PI / 4, -Math.PI / 4);

    private deviceOrientation(evt: DeviceOrientationEvent) {
        if (this.catching) {
            const gamma = evt.gamma !== null ? evt.gamma : 0;
            const beta = evt.beta !== null ? evt.beta : 0;
            const alpha = evt.alpha !== null ? evt.alpha : 0;
            if (evt.gamma !== null) {
                const quaternion = Quaternion.RotationYawPitchRoll(
                    Tools.ToRadians(alpha),
                    Tools.ToRadians(beta),
                    -Tools.ToRadians(gamma)
                );
                quaternion.multiplyInPlace(this.screenQuaternion);
                quaternion.multiplyInPlace(this.constantTranform);
                quaternion.z *= -1;
                quaternion.w *= -1;
                const angles = quaternion.toEulerAngles();

                const pos = new Vector2(angles.y, angles.x);
                pos.divideInPlace(this.divideVector);
                const posMax = Vector2.Minimize(pos, this.deviceMaxVector);
                const posMin = Vector2.Maximize(posMax, this.deviceMinVector);
                this.catchMove(posMin);
            }
        }
    }

    private mouseOrientation(mousepos: Vector2) {
        if (this.catching) {
            if (this.dragging) {
                this.catchDrag(mousepos);
            } else {
                this.catchMove(mousepos);
            }
        }
    }

    private wheelCatch = Vector2.Zero();

    private wheelProgress = Vector2.Zero();

    public mouseWheel(evt: WheelEvent): void {
        const x = evt.deltaX;
        const y = evt.deltaY;
        const wheelVector = new Vector2(x, y);
        this.wheelProgress.addInPlace(wheelVector);
        const signX = Math.sign(this.wheelProgress.x);
        const signY = Math.sign(this.wheelProgress.y);
        this.wheelProgress.x = signX * Math.min(Math.abs(this.wheelProgress.x), 1);
        this.wheelProgress.y = signY * Math.min(Math.abs(this.wheelProgress.y), 1);
        this.catchAnimation(
            NakerMouseEvent.Wheel,
            this.wheelAnimation,
            this.wheelProgress,
            this.wheelCatch
        );
        // Also send event immmediatly in order to preventdefault scroll
        this.notify(NakerMouseEvent.Wheel, this.wheelProgress);
    }

    private mousePosition = Vector2.Zero();

    private getMousePosition(evt: IPointerEvent) {
        const w = window.innerWidth;
        const h = window.innerHeight;
        // Position start from center of the window
        this.mousePosition.x = (evt.x - w / 2) / 1000;
        this.mousePosition.y = (evt.y - h / 2) / 1000;
    }

    /**
    * Spped of the progress used when mousewheel or drag on phone
    */
    private speed = 0.02;

    /**
    * Set the speed of the progressCatcher
    * @param speed The new speed
    */
    public setSpeed(speed: number): void {
        this.speed = speed;
    }

    private moveCatch = Vector2.Zero();

    public catchMove(mouse: Vector2): void {
        if (this.hasEventObservers(NakerMouseEvent.InstantMove)) {
            this.notify(NakerMouseEvent.InstantMove, mouse.clone());
        }
        this.catchAnimation(NakerMouseEvent.Move, this.moveAnimation, this.moveCatch, mouse);
    }

    private dragStart = Vector2.Zero();

    public dragging = false;

    private startDrag() {
        // Make to stop animation or it will keep send data with bad start
        this.dragAnimation.stop();
        this.dragStart = this.mousePosition.clone();
        this.dragCatch = Vector2.Zero();
        this.dragging = true;
        this.notify(NakerMouseEvent.DragStart, Vector2.Zero());
    }

    public stopDrag(): void {
        this.dragging = false;
        this.notify(NakerMouseEvent.DragStop, Vector2.Zero());
    }

    private dragCatch = Vector2.Zero();

    private catchDrag(mouse: Vector2) {
        if (this.scaling) return;
        this.catchAnimation(
            NakerMouseEvent.Drag,
            this.dragAnimation,
            this.dragCatch,
            this.dragStart.subtract(mouse)
        );
    }

    private maxMouseGap = 0.001;

    private catchAnimation(
        event: NakerMouseEvent,
        animation: Animation,
        startValue: Vector2,
        catchValue: Vector2
    ) {
        // If no movement, no need to move
        const xMove = Math.abs(startValue.x - catchValue.x);
        const yMove = Math.abs(startValue.y - catchValue.y);
        if (xMove < this.maxMouseGap && yMove < this.maxMouseGap) return;
        // If no observers, no need to notify
        if (this.hasEventObservers(event)) {
            const start = startValue.clone();
            const change = catchValue.subtract(start);
            const howmany = 1 / this.speed;
            animation.simple(howmany, (perc) => {
                const progress = change.multiply(new Vector2(perc, perc));
                const newValue = start.add(progress);
                startValue.x = newValue.x;
                startValue.y = newValue.y;
                this.notify(event, startValue.clone());
            }, () => {
                startValue = catchValue;
            });
        }
    }
}
