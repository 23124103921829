import axios from 'axios';
import { ITokens } from 'sharedtypes';

import LocalStorage from './apiCookie';
import { apiSocket } from './apiSocket';
import env from '~/Services/Environment/environment';
import {
    IArgType,
    IOptionsBody,
    IHearderContent,
    Method,
    TBody,
    ISendResponse
} from '~/Types';
import { post } from './Analytics';

const ObjecToQuery = (obj: any): string => {
    let str = '';
    const keys = Object.keys(obj);
    keys.forEach((key) => {
        const param = encodeURIComponent(obj[key]);
        str += `${key}=${param}&`;
    });
    return str;
};

export class Api {
    private host = env.apiurl;

    private access_token = '';

    public refresh_token = '';

    public setToken({ access_token, refresh_token }: ITokens): void {
        this.access_token = access_token;
        this.refresh_token = refresh_token;
        LocalStorage.set({ access_token, refresh_token });
        // Can't set it in common because some request
        // like cloudinary doesn't allow Authorization headers
        // axios.defaults.headers.common['Authorization'] = token.access_token;
    }

    public put(path: string, arg: IArgType, callback: (data) => void): void {
        const query = arg ? ObjecToQuery(arg) : '';
        const url = `${this.host + path}?${query}`;
        this.send('PUT', url, '', {}, (data) => {
            callback(data);
        });
    }

    public post(
        path: string,
        arg: IArgType,
        options: IOptionsBody,
        callback: (data: ISendResponse) => void
    ): void {
        if (!options) options = {};
        const bodystring = options.body ? options.body : '';
        const header = options.header ? options.header : {};
        const query = arg ? ObjecToQuery(arg) : '';
        const url = `${this.host + path}?${query}`;
        this.send('POST', url, bodystring, header, callback);
    }

    public get(path: string, arg: IArgType, callback: (data: ISendResponse) => void): void {
        const query = arg ? ObjecToQuery(arg) : '';
        const url = `${this.host + path}?${query}`;
        this.send('GET', url, '', {}, callback);
    }

    public delete(
        path: string,
        arg: IArgType,
        callback: (data: ISendResponse) => void
    ): void {
        const query = arg ? ObjecToQuery(arg) : '';
        const url = `${this.host + path}?${query}`;
        this.send('DELETE', url, '', {}, (data: ISendResponse) => {
            callback(data);
        });
    }

    public upload(path: string, file: string | Blob, callback: (succces) => void): void {
        const url = `${this.host + path}`;
        const data = new FormData();
        data.append('file', file);
        this.send('POST', url, data, {}, callback);
    }

    private send(
        method: Method,
        url: string,
        body: TBody,
        headers: IHearderContent,
        callback: (data: ISendResponse) => void
    ): void {
        headers.Authorization = this.access_token;
        if (method === 'POST') {
            axios.post(url, body, { headers })
                .then((response) => { callback(response.data); })
                .catch((error) => {
                    this.checkError(error, method, url, body, headers, callback);
                });
        } else if (method === 'PUT') {
            axios.put(url, body, { headers })
                .then((response) => { callback(response.data); })
                .catch((error) => {
                    this.checkError(error, method, url, body, headers, callback);
                });
        } else if (method === 'GET') {
            axios.get(url, { headers })
                .then((response) => { callback(response.data); })
                .catch((error) => {
                    this.checkError(error, method, url, body, headers, callback);
                });
        } else {
            console.error('Method must be GET or POST');
        }
    }

    // If catch error after request, there is no response in error.
    private checkError(
        error: any,
        method: Method,
        url: string,
        body: TBody,
        headers: IHearderContent,
        callback: (data: { success: boolean, message?: string }) => void
    ) {
        if (error) {
            if (error.response) {
                this.checkToken(url, error.response.data, (result) => {
                    if (result) {
                        this.send(method, url, body, headers, callback);
                    } else {
                        callback(error.response.data);
                    }
                });
            } else {
                callback({ success: false });
            }
        } else {
            callback({ success: false });
        }
    }

    private checkToken(url: string, error: any, callback: (succces: boolean) => void) {
        if ((error.message === 'Bad Token.' || error.message === 'No token provided.') && url.indexOf('refreshtoken') === -1) {
            this.oldRefreshToken(callback);
        } else {
            callback(false);
        }
    }

    public onDisconnect: () => void;

    private waitingRefreshToken: ((success: boolean) => void)[] = [];

    public oldRefreshToken(callback: (succces: boolean) => void): void {
        console.log('This refreshToken function called');
        if (this.waitingRefreshToken.length) {
            this.waitingRefreshToken.push(callback);
        } else {
            this.waitingRefreshToken.push(callback);
            this.post('user/refreshtoken', { refresh_token: this.refresh_token }, null, (data: ISendResponse) => {
                if (data.success) {
                    this.setToken(data);
                    apiSocket.refreshAuth(data.access_token, (success) => {
                        this.waitingRefreshToken.forEach((callback) => {
                            callback(success);
                        });
                        this.waitingRefreshToken = [];
                    });
                } else {
                    // Make sure to disconnect before the callback
                    if (this.onDisconnect) this.onDisconnect();
                    callback(false);
                }
            });
        }
    }
}

const api = new Api();
export default api;

export const refreshToken = async (refreshToken: string): Promise<ITokens> => {
    const res = await post('user/refreshtoken', { refresh_token: refreshToken });
    const { access_token, refresh_token } = res.data;
    api.setToken({ access_token, refresh_token });
    return { access_token, refresh_token };
};
