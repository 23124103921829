import filter from 'lodash/filter';
import { toast } from 'react-toastify';
import { IVisualType } from 'sharedtypes/rtx/rtx';
import { IScenario } from 'sharedtypes/rtx/scenario';

import { SAVING } from '~/Services/Environment/devConfig';
import { apiSocket } from '~/Api/apiSocket';
import { getRandomString } from '~/Utils/math';
import { CONTEXT, context } from '../Environment/environment';

export const LOCAL_SCENARIOS: IScenario[] = [0, 1, 2, 3, 4, 5].map((v) => (
    {
        _id: getRandomString(),
        name: `Scénario ${v}`,
        type: 'image',
        state: { format: 'PNG', resolutions: [{ name: '1k', width: 1000, height: 1000 }], povs: [{ name: 'SceneView 2', position: { x: -1.51, y: 1.94, z: 1.71 }, rotation: { x: 40.41, y: 138.47, z: 0 } }, { name: 'SceneView 1', position: { x: 0, y: 1.93, z: 2.3 }, rotation: { x: 40, y: -180, z: 0 } }] },
        environment: {
            visibleSky: false, skycolor: [255, 255, 255, 1], skybrightness: 1, skyorientation: 0, skyblur: 0, lightOrientation: { x: 0, y: 0, z: 0 }, shadowGroundVisibility: 1, shadowGroundSoftness: 0.54, shadowGroundHeight: 0.08, skydiffuse: 'https://d2uret4ukwmuoe.cloudfront.net/environment_v4/studio_small_09.env'
        },
        created_by: '',
        company: ''
    }
));

export const MODEL_SCENARIOS: IScenario[] = [
    {
        _id: getRandomString(),
        name: 'GLB Export',
        type: 'model',
        state: { formats: ['glb'] },
        environment: {},
        created_by: '',
        company: ''
    },
    {
        _id: getRandomString(),
        name: 'USDZ Export',
        type: 'model',
        state: { formats: ['usdz'] },
        environment: {},
        created_by: '',
        company: ''
    }
];

export const getScenariosByType = (
    email: string,
    type: IVisualType,
    callback: (scenario: IScenario[]) => void
): void => {
    if (context === CONTEXT.LOCAL && !SAVING) {
        let scenarios = (type)
            ? filter(LOCAL_SCENARIOS, (s) => s.type === type)
            : LOCAL_SCENARIOS;
        if (!type) scenarios = MODEL_SCENARIOS.concat(scenarios);
        callback(scenarios);
    } else if (email) {
        apiSocket.getSocket().emit('scenario:get', { email, type }, ({ err, data }) => {
            if (err) {
                callback([]);
                console.error(err);
            } else {
                if (!type) data = MODEL_SCENARIOS.concat(data);
                callback(data);
            }
        });
    }
};

export const deleteScenario = (
    email: string,
    id: string,
    type: IVisualType,
    callback: (scenario: IScenario[]) => void
): void => {
    if (context === CONTEXT.LOCAL && !SAVING) {
        const idx = LOCAL_SCENARIOS.findIndex((x) => x._id === id);
        LOCAL_SCENARIOS.splice(idx, 1);
        getScenariosByType(email, type, callback);
    } else {
        apiSocket.getSocket().emit('scenario:delete', { id }, ({ err }) => {
            if (!err) {
                getScenariosByType(email, type, callback);
                toast.success('Scenario deleted');
            } else {
                console.error(err);
                toast.error('Error while deleting the scenario 😕');
            }
        });
    }
};
