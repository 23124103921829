import React, { FC, useEffect, useState } from 'react';
import {
    ActionButton, Text,
    Flex, Heading, Item, Menu, MenuTrigger
} from '@adobe/react-spectrum';
import { IAnalytics } from 'sharedtypes/analytic';

import {
    IAnInterval, IAnIntervalData, IImageQuality, IVideoFrames, getAnalyticArray
} from '~/Api/Analytics';
import { AnalyticsChart } from '../Charts/chart';

interface IChartProps {
    data: IAnalytics;
    int: IAnInterval;
}

export const VideoCharts: FC<IChartProps> = ({ data, int }) => {
    const [quality, setQuality] = useState<IImageQuality>('All');
    const [frames, setFrames] = useState<IVideoFrames>('All');
    const [sorted, setSorted] = useState<IAnIntervalData>([]);

    useEffect(() => {
        const sortedData = getAnalyticArray(int, data, quality, frames);
        setSorted(sortedData);
    }, [quality, data, frames]);

    return (
        <Flex direction="column">
            <Flex direction="row" alignItems="center">
                <Heading level={3}>
                    {'Videos rendered this '}
                    {' '}
                    {int}
                    :
                </Heading>
                <Flex direction="row" gap="size-200" marginStart="auto">
                    <Flex direction="row" alignItems="center" gap="size-100">
                        <Text>Fps: </Text>
                        <MenuTrigger>
                            <ActionButton>
                                {frames}
                            </ActionButton>
                            <Menu onAction={(key) => setFrames(key as IVideoFrames)}>
                                <Item key="All">All</Item>
                                <Item key="5">5</Item>
                                <Item key="25">25</Item>
                                <Item key="30">30</Item>
                                <Item key="60">60</Item>
                            </Menu>
                        </MenuTrigger>
                    </Flex>
                    <Flex direction="row" alignItems="center" gap="size-100">
                        <Text>Quality: </Text>
                        <MenuTrigger>
                            <ActionButton>
                                {quality}
                            </ActionButton>
                            <Menu onAction={(key) => setQuality(key as IImageQuality)}>
                                <Item key="All">All</Item>
                                <Item key="1K">1k</Item>
                                <Item key="2K">2k</Item>
                                <Item key="4K">4k</Item>
                                <Item key="8K">8k</Item>
                            </Menu>
                        </MenuTrigger>
                    </Flex>
                </Flex>
            </Flex>

            <AnalyticsChart data={sorted} />
        </Flex>
    );
};
