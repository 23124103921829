import React, { useState, useEffect, FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Flex,
    Form,
    TextField,
    Text,
    Button,
    Heading,
    Link
} from '@adobe/react-spectrum';
import { IUser } from 'sharedtypes';

import api from '~/Api/api';
import AuthLayout from './authLayout';

const Password: FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [token, settoken] = useState(null);

    const setToken = (value) => {
        settoken(value);
        setError(null);
    };

    const sendEmail = () => {
        if (email.indexOf('@') === -1) {
            setError('Wrong E-mail');
        } else {
            api.post('user/password', { email }, null, (data) => {
                if (data.success) {
                    setSuccess('Thanks! Check your email inbox with instruction.');
                } else {
                    setError(data.message);
                }
            });
        }
    };

    const savePassword = () => {
        if (password.length < 6) {
            setError('Password must have 6 characters');
        } else {
            api.post('user/confirmpassword', { password, token }, null, (data: IUser) => {
                // Must be corrected with promise
                // if (data.success === undefined) {
                if (data) {
                    setSuccess('Good! Your Password has been reset');
                } else {
                    // have to show message error (data.message)
                    setError(data);
                }
            });
        }
    };

    useEffect(() => {
        const url = window.location.href;
        const tokenArr = /token=([^&]+)/.exec(url);

        if (tokenArr !== null) {
            const token = tokenArr[1];
            console.log('token', token);
            setToken(token);
        } else {
            setToken(null);
        }
    }, []);

    return (
        <AuthLayout>
            <Flex direction="column" alignItems="center">
                <div className="align-height">
                    <Flex
                        direction="column"
                        alignItems="start"
                        gap="size-300"
                    >
                        <Form width="size-4000" height="100%">
                            {!token && !success && (
                                <Flex
                                    direction="column"
                                    alignItems="start"
                                    gap="size-200"
                                >
                                    <Heading>
                                        Enter your E-mail to reset your
                                        Password :
                                    </Heading>
                                    <Flex
                                        direction="row"
                                        gap="size-200"
                                    >
                                        <TextField
                                            aria-label="Email"
                                            width="100%"
                                            type="email"
                                            value={email}
                                            onChange={(v) => {
                                                setEmail(v);
                                            }}
                                            // onBlur={(v) => { setName(v) }}
                                        />
                                        <Button
                                            variant="accent"
                                            width="size-1600"
                                            onPress={(v) => {
                                                sendEmail();
                                            }}
                                        >
                                            <Text>Reset</Text>
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                            {token && !success && (
                                <Flex
                                    direction="column"
                                    alignItems="start"
                                    gap="size-200"
                                >
                                    <Heading>
                                        What is the new password you
                                        want?
                                    </Heading>
                                    <Flex
                                        direction="row"
                                        gap="size-200"
                                        alignItems="end"
                                    >
                                        <TextField
                                            width="100%"
                                            label="New Password"
                                            value={password}
                                            type="password"
                                            onChange={(v) => {
                                                setPassword(v);
                                            }}
                                            // onBlur={(v) => { setName(v) }}
                                        />
                                        <Button
                                            variant="accent"
                                            width="size-1600"
                                            onPress={savePassword}
                                        >
                                            <Text>Save</Text>
                                        </Button>
                                    </Flex>
                                </Flex>
                            )}
                        </Form>
                        {error ? (
                            <span className="login-error colorerror">
                                {error}
                            </span>
                        ) : null}
                        {success ? <Heading>{success}</Heading> : null}
                        <Link>
                            <RouterLink to="/login">
                                Back to Log In
                            </RouterLink>
                        </Link>
                    </Flex>
                </div>
            </Flex>
        </AuthLayout>
    );
};

export default Password;
