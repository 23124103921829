import React, { useState, FC } from 'react';
import {
    Header,
    Item,
    ComboBox,
    TextField,
    Grid,
    Flex,
} from '@adobe/react-spectrum';
import { toast } from 'react-toastify';

import { useAuthContext } from '~/Context/contextAuth';
import { apiSocket } from '~/Api/apiSocket';

interface IManagementUser {
    name?: string;
    type?: string;
    company?: string;
}

const AccountProfile: FC = () => {
    const { user, updateUser } = useAuthContext();
    const [updating, setUpdating] = useState(false);

    const optionList = [
        { id: 1, name: 'Student' },
        { id: 2, name: 'Freelance' },
        { id: 3, name: 'Agency' },
        { id: 4, name: 'Brand' }
    ];

    const tryUpdate = (userData: IManagementUser) => {
        if (!updating) {
            setUpdating(true);
            apiSocket.getSocket().emit('user:update', userData, ({ err }) => {
                setUpdating(false);
                if (err) {
                    toast.error('Can not update your profile for now 😕');
                } else {
                    updateUser({ ...user, ...userData });
                    toast.success('Nice, your profile has been updated 🤙');
                }
            });
        }
    };

    const onSelectionChange = (type: string) => {
        tryUpdate({ type });
    };

    const updateUserName = (name: string) => {
        tryUpdate({ name });
    };

    const updateCompany = (company: string) => {
        tryUpdate({ company });
    };

    return (
        <>
            <Header justifySelf="start" marginBottom="size-100">
                <Flex direction="row" gap="size-600">
                    <TextField
                        aria-label="textfield"
                        UNSAFE_style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }}
                        width="size-3000"
                        defaultValue={user.name}
                        onBlur={(value) => updateUserName((value.target as HTMLInputElement).value)}
                    />
                </Flex>
            </Header>
            <Grid rows={['60x']} gap="size-100">
                <ComboBox
                    width="size-4000"
                    label="You discribe yourself as"
                    defaultItems={optionList}
                    defaultInputValue={user.type}
                    onSelectionChange={(userType: string) => {
                        if (userType) {
                            onSelectionChange(userType);
                        }
                    }}
                >
                    {(item) => (
                        <Item textValue={`${item.name}`} key={item.name}>{item.name}</Item>
                    )}
                </ComboBox>
                <TextField
                    width="size-4000"
                    label="Company"
                    defaultValue={user.company}
                    onBlur={(value) => updateCompany((value.target as HTMLInputElement).value)}
                />
            </Grid>
        </>
    );
};

export default AccountProfile;
