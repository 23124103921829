import { Animation } from '../System/Animation/animation';
import { Ease, EaseMode } from '../System/Animation/animationEasing';
import { SystemAnimation } from '../System/systemAnimation';
import { Observable } from '../../Utils/observable';

/**
 * Detect progress action of the user
 */

export abstract class Catcher<T, U> extends Observable<T, U> {
    /**
     * Use to animate the catching
     */
    private animation: Animation;

    protected system: SystemAnimation;

    /**
     * Use to animate the catching
     * @param system System of the 3D scene
     * @param responsive If there is responsive changes, we may have to adapt progress height
     */
    constructor(system: SystemAnimation, catcher: string) {
        super(catcher);
        this.system = system;
        this.animation = new Animation(system, 10);
        this.animation.setEasing(Ease.Cubic, EaseMode.Out);
    }

    /**
     * Restart progress catcher
     */
    public restart(): void {
        this._restart();
    }

    protected _restart(): void {
        this.stop();
        this.start();
    }

    /**
     * Is the progress currently catched or not
     */
    protected catching = false;

    /**
     * Start catching progress
     */
    public start(): void {
        this._start();
    }

    /**
     * @ignore
     */
    protected _start(): void {
        this.catching = true;
    }

    public pause(): void {
        this.catching = false;
    }

    public play(): void {
        this.catching = true;
    }

    /**
     * Stop catching progress
     */
    public stop(): void {
        this._stop();
    }

    /**
     * @ignore
     */
    protected _stop(): void {
        this.animation.stop();
        this.catching = false;
    }

    private lastTimeCatch = new Date().getTime();

    /**
    * Allow to check if there was enough time between two catch event
    */
    protected checkRecentCatch(interval: number): boolean {
        const now = new Date().getTime();
        if (now - this.lastTimeCatch < interval) {
            return true;
        }
        this.lastTimeCatch = now;
        return false;
    }
}
