import { IQuality } from 'sharedtypes/math';
import { IVideoFramerate } from 'sharedtypes/rtx/rtx';

import { IAnalytic, IAnalytics } from 'sharedtypes/analytic';
import { apiSocket } from '../apiSocket';

export type IAnInterval = 'day' | 'week' | 'month' | 'year';

export type IImageQuality = IQuality & 'All';

export type IVideoFrames = IVideoFramerate & 'All';

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
};
const DAY_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    hour: 'numeric'
};
const WEEK_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
};
const YEAR_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    month: 'short',
};

export type IAnIntervalData = {
    name: string;
    simple: string;
    nb: number;
}[];

export const getAnalytics = (interval: IAnInterval, id: string, callback): void => { apiSocket.getSocket().emit('analytics:user', { interval, id }, callback); };
export const getUserInfos = (id: string, callback): void => { apiSocket.getSocket().emit('analytics:infos', { id }, callback); };

export function getCleanDate(d: string | Date): string {
    const date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hour = date.getHours();
    const mins = date.getMinutes();
    return `${day}/${month} - ${hour}:${mins}`;
}

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = 4 * ONE_WEEK;
const ONE_YEAR = 12 * ONE_MONTH;

function getDateNumber(date: Date, step: number): number {
    const start = new Date(date.getFullYear(), 0, 0);
    // @ts-ignore
    const diff = date - start;
    return Math.floor(diff / step);
}

type TDateFunction = (date: Date) => number;

const checkStepData = (
    data: IAnalytic,
    dateNumber: number,
    dateFunction: TDateFunction,
    quality: IImageQuality,
    frames?: IVideoFrames
) => {
    const { created_at, settings } = data;
    // Some old data were missing settings
    if (!settings) return false;
    const { fps, resolution } = settings;

    const date = new Date(created_at);
    const dayCheck = dateFunction(date) === dateNumber;

    let qualityCheck = true;
    let framesCheck = true;
    if (quality && quality !== 'All') {
        qualityCheck = resolution === quality;
    }
    if (frames && frames !== 'All') {
        framesCheck = parseFloat(frames) === fps;
    }
    return dayCheck && qualityCheck && framesCheck;
};

const getRangeArray = (
    data: IAnalytics,
    range: number,
    step: number,
    dateOption: Intl.DateTimeFormatOptions,
    quality: IImageQuality,
    frames?: IVideoFrames
): IAnIntervalData => {
    const result = [];
    const today = new Date();
    const endDate = new Date(today.getTime() - range);
    const stepFunction = (date: Date) => getDateNumber(date, step);
    while (today >= endDate) {
        const name = today.toLocaleString('en-US', DATE_OPTIONS);
        const simple = today.toLocaleString('en-US', dateOption);
        const stepNumber = stepFunction(today);
        const filterData = (x) => checkStepData(x, stepNumber, stepFunction, quality, frames);
        const stepData = data.filter(filterData);
        result.unshift({
            name, simple, nb: stepData.length
        });
        today.setTime(today.getTime() - step);
    }
    return result;
};

export const getAnalyticArray = (
    int: IAnInterval,
    data: IAnalytics,
    quality: IImageQuality,
    frames?: IVideoFrames
): IAnIntervalData => {
    switch (int) {
        case 'day':
            return getRangeArray(data, ONE_DAY, ONE_HOUR, DAY_DATE_OPTIONS, quality, frames);
        case 'week':
            return getRangeArray(data, ONE_WEEK, ONE_DAY, WEEK_DATE_OPTIONS, quality, frames);
        case 'month':
            return getRangeArray(data, ONE_MONTH, ONE_WEEK, WEEK_DATE_OPTIONS, quality, frames);
        case 'year':
            return getRangeArray(data, ONE_YEAR, ONE_MONTH, YEAR_DATE_OPTIONS, quality, frames);
        default:
            return getRangeArray(data, ONE_WEEK, ONE_DAY, WEEK_DATE_OPTIONS, quality, frames);
    }
};
