import React, { FC } from 'react';
import { View, Flex, Text } from '@adobe/react-spectrum';

import Add from '@spectrum-icons/workflow/Add';

interface ICardAddProjectProps {
    createProject: () => void;
}

export const CardAddProject: FC<ICardAddProjectProps> = ({ createProject }) => (
    <div
        role="button"
        aria-label="edit"
        tabIndex={-1}
        onClick={() => createProject()}
        style={{ cursor: 'pointer' }}
    >
        <View
            borderWidth="thin"
            borderColor="dark"
            borderRadius="medium"
            paddingTop="size-50"
            UNSAFE_className="card-view"
            height="240px"
            width="size-3000"
        >
            <Flex
                alignContent="center"
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignSelf="center"
                marginTop="size-600"
            >
                <Add size="XXL" />
                <br />
                {/* <ActionButton onPress={() => createProject()} isQuiet > */}
                <Text>New Project</Text>
            </Flex>
        </View>
    </div>
);
