import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    View,
    Text,
    Image,
    Flex,
    Well,
    Button,
    ActionButton,
    TooltipTrigger,
    Tooltip,
} from '@adobe/react-spectrum';
import Duplicate from '@spectrum-icons/workflow/Duplicate';
import Share from '@spectrum-icons/workflow/Share';
import ImageIcon from '@spectrum-icons/workflow/Image';
import Launch from '@spectrum-icons/workflow/Launch';
import Visibility from '@spectrum-icons/workflow/Visibility';
import VisibilityOff from '@spectrum-icons/workflow/VisibilityOff';
import { IProject } from 'sharedtypes';

import { apiSpy } from '~/Api';
import './card.sass';
import { DeleteButton } from '~/Components/Delete/deleteButton';
import { apiSocket } from '~/Api/apiSocket';

interface ICardProps {
    project: IProject;
    selectProject: (project: IProject) => void;
    user: string;
    designer: string;
}

export const Card: FC<ICardProps> = (
    props: ICardProps
) => {
    const [isWorking, setIsWorking] = useState(false);
    const {
        project, user, designer, selectProject
    } = props;
    const { worker, shareimage, image } = project;

    useEffect(() => {
        setIsWorking(worker && worker.working);
    }, []);

    const refreshSocket = (Project: IProject) => {
        apiSocket.getSocket().emit('project:status', { id: Project._id }, ({ err, data }) => {
            if (err) {
                console.error(err);
            } else if (data.working) {
                toast.error(`${Project.worker.name} is still working🙁`);
            } else {
                Project.worker = {
                    working: false,
                    id: '',
                    socket: '',
                    name: ''
                };
                toast.success('You can now edit the project 👍');
                setIsWorking(false);
            }
        });
    };

    const edit = (editProject: IProject) => {
        if (editProject.worker && !editProject.worker.working) {
            apiSpy.track('Story Edit');
            setTimeout(() => {
                // navigate(`/studio/${editProject._id}`);
                window.location.href = `/studio/${editProject._id}`;
            }, 100);
        }
    };

    const isImage = () => (
        <Flex direction="column" alignItems="center">
            <div
                role="button"
                aria-label="edit"
                tabIndex={0}
                onClick={() => edit(project)}
                style={{ cursor: 'pointer' }}
            >
                {image
                    ? <Image objectFit="none" alt="" width="size-3000" src={image} />
                    : <ImageIcon width="size-3000" height="165px" />}
            </div>
        </Flex>
    );

    const IsWorker = () => {
        if (isWorking) {
            return (
                <Well marginTop="size-25">
                    <Flex
                        direction="column"
                        alignSelf="center"
                        justifyContent="center"
                        alignItems="center"
                        rowGap="size-100"
                        height="132px"
                    >
                        <View>
                            <Text>
                                {' '}
                                {worker.name}
                                {' '}
                                is working
                            </Text>
                        </View>
                        <View>
                            <Button
                                variant="primary"
                                onPress={() => refreshSocket(project)}
                            >
                                Refresh
                            </Button>
                        </View>
                    </Flex>
                </Well>
            );
        }
        return (
            <>
                {shareimage ? (
                    <img
                        src={shareimage}
                        className="project-card-no-thumbnail"
                        alt="thumbnail"
                    />
                ) : (
                    isImage()
                )}
            </>
        );
    };

    const duplicate = (duplicateProject: IProject) => {
        apiSocket.getSocket().emit('project:duplicate', { id: duplicateProject._id }, ({ err }) => {
            if (err) {
                return toast.error(
                    'Oups we had an issue while duplicating the project. 🙁'
                );
            }
            apiSpy.track('Story Create', {
                from: 'duplicate'
            });
            return toast.success('Good, your project has been duplicated. 👍');
        });
    };

    const deleteProject = (delProject: IProject) => {
        apiSocket.getSocket().emit('project:delete', { id: delProject._id }, ({ err }) => {
            if (err) {
                return toast.error(
                    'There was an issue while trying to delete your project. 👿'
                );
            }
            apiSpy.track('Story Delete');
            return toast.success('Project deleted! 👌');
        });
    };

    const leaveTeam = (leaveProject: IProject) => {
        const id = leaveProject._id;
        apiSocket.getSocket().emit('project:leave', { id }, ({ err }) => {
            if (err) {
                toast.error(
                    'There was an issue while trying to leave this project. 👿'
                );
            } else {
                toast.success('Project left! 👌');
            }
        });
    };

    const CardHeader = () => (
        <Flex direction="row" alignItems="center" height="size-400">
            <Text marginStart="size-150" UNSAFE_className="project-name">{project.name}</Text>
        </Flex>
    );

    const CardHeaderWithButtons = () => (
        <Flex direction="row" alignItems="center">
            <Text marginStart="size-150" UNSAFE_className="project-name">{project.name}</Text>
            <View flex />
            {user === designer
                && (
                    <>
                        <TooltipTrigger delay={0} placement="bottom">
                            <ActionButton
                                isQuiet
                                onPress={() => selectProject(project)}
                            >
                                <Share size="S" />
                            </ActionButton>
                            <Tooltip>Share</Tooltip>
                        </TooltipTrigger>
                        <TooltipTrigger delay={0} placement="bottom">
                            <ActionButton
                                isQuiet
                                onPress={() => duplicate(project)}
                            >
                                <Duplicate size="S" />
                            </ActionButton>
                            <Tooltip>Duplicate</Tooltip>
                        </TooltipTrigger>
                        <DeleteButton
                            onDelete={() => deleteProject(project)}
                            title="project"
                        />
                    </>
                )}
        </Flex>
    );

    const CardFooter = () => (
        <Flex direction="row" justifyContent="space-between">
            <View>
                {project.published ? (
                    <TooltipTrigger delay={0}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{ color: '#39b990' }}
                        >
                            <Launch size="S" />
                        </ActionButton>
                        <Tooltip variant="positive">Published</Tooltip>
                    </TooltipTrigger>
                ) : (
                    <TooltipTrigger delay={0}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{ color: '#7c7c7c' }}
                        >
                            <Launch size="S" />
                        </ActionButton>
                        <Tooltip variant="neutral">Unpublished</Tooltip>
                    </TooltipTrigger>
                )}
            </View>
            <View>
                {project.views || project.views === 0 ? (
                    <TooltipTrigger delay={0}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{ color: '#39b990' }}
                        >
                            <Visibility size="S" />
                            {project.views}
                        </ActionButton>
                        <Tooltip variant="neutral">Viewed</Tooltip>
                    </TooltipTrigger>
                ) : (
                    <TooltipTrigger delay={0}>
                        <ActionButton
                            isQuiet
                            UNSAFE_style={{ color: '#7c7c7c' }}
                        >
                            <VisibilityOff size="S" />
                        </ActionButton>
                        <Tooltip variant="neutral">Not viewed yet</Tooltip>
                    </TooltipTrigger>
                )}
            </View>
        </Flex>
    );

    return (
        <View
            borderWidth="thin"
            borderColor="dark"
            borderRadius="medium"
            paddingTop="size-50"
            height="240px"
            UNSAFE_className="card-view"
            width="size-3000"
        >
            <div className="Card">
                {user === designer ? <CardHeaderWithButtons /> : <CardHeader />}
                {IsWorker()}
                <CardFooter />
            </div>
        </View>
    );
};
