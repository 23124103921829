import { Vector3 } from '@babylonjs/core/Maths/math.vector';

import { SystemLayer } from '../System/systemLayer';
import { projectInterface } from '../Tool/interface';
import { PatternLight } from './Pattern/patternLight';
import { PatternModel } from './Pattern/Group/patternModel';
import { PatternTransform } from './Pattern/patternTransform';

export type ContentType = 'model' | 'light'

export interface IContentOptions {
    id?: string,
    type?: ContentType;
    position?: Vector3,
    scale?: Vector3,
    rotation?: Vector3,
    visible?: boolean,
    play?: boolean,
    animations?: any,
    tag?: string,
}

/**
 * @ignore Property Rights, ThreedJson
 */
export const vector3Json = {
    x: { dim: 'x', accuracy: 5 },
    y: { dim: 'y', accuracy: 5 },
    z: { dim: 'z', accuracy: 5 }
};

export const vector2Json = {
    x: { dim: 'x', accuracy: 5 },
    y: { dim: 'y', accuracy: 5 },
};

projectInterface.content = {
    id: 'i',
    position: {
        dim: 'p',
        next: vector3Json
    },
    rotation: {
        dim: 'r',
        next: vector3Json
    },
    scale: {
        dim: 's',
        next: vector3Json
    },
    visible: 'v',
    play: 'pl',
    tag: 't',
    type: 'tp',
};

export abstract class Content {
    protected _system: SystemLayer;

    public id: string;

    public position = Vector3.Zero();

    public rotation = Vector3.Zero();

    public scale = Vector3.Zero();

    public type: string;

    public pattern: PatternTransform;

    public visible = true;

    public play = true;

    public tag = '';

    constructor(systemLayer: SystemLayer, content: IContentOptions, contentType: ContentType) {
        this._system = systemLayer;
        if (contentType === 'model') this.pattern = new PatternModel(systemLayer);
        else if (contentType === 'light') this.pattern = new PatternLight(systemLayer);
        this.id = content.id;

        this.setGeometryParameters(content);
        this.setContentParameter(content, true);
    }

    public abstract setTypeParameter(typeStyle: any): void

    public setGeometryParameters(content: IContentOptions): void {
        if (content.position !== undefined) this.setPosition(content.position);
        if (content.scale !== undefined) this.setScale(content.scale);
        if (content.rotation !== undefined) this.setRotation(content.rotation);
    }

    public setContentParameter(content: IContentOptions, init?: boolean): void {
        if (content.visible !== undefined) this.visible = content.visible;
        if (content.play !== undefined) this.play = content.play;
        if (content.tag !== undefined) this.tag = content.tag;
        if (!init) {
            if (content.visible !== undefined) this.setVisible(content.visible);
            if (content.play !== undefined) this.setPlay(content.play);
        }
    }

    //* need to set function here for setContentParam function
    public abstract setPlay(play: boolean): void

    public setPosition(pos: Vector3): void {
        this.setVector('position', pos);
        this.pattern.setPosAxis(pos);
        this._system.resetShadows();
    }

    public setRotation(rot: Vector3): void {
        this.setVector('rotation', rot);
        this.pattern.setAngleAxis(rot);
        this._system.resetShadows();
    }

    public keepScaleRatio = false

    public setScale(scale: Vector3): void {
        if (this.keepScaleRatio) {
            scale.y = scale.x;
            scale.z = scale.x;
        }
        this.setVector('scale', scale);
        this.pattern.setScaleAxis(scale);
        this._system.resetShadows();
    }

    private setVector(param: string, vector: Vector3) {
        ['x', 'y', 'z'].forEach((key) => {
            this[param][key] = vector[key];
        });
    }

    public setVisible(visible: boolean): void {
        this.visible = visible;
        if (visible) this.show();
        else this.hide();
    }

    protected shown = false;

    protected _show(): void {
        this.shown = true;
        this.pattern.show();
    }

    public abstract show(): void

    protected _hide(): void {
        this.shown = false;
        this.pattern.hide();
    }

    // Fast used in editor to quickly hide some content which have fade animation
    public abstract hide(fast?: boolean): void
}
