import { ITokens } from 'sharedtypes';

export const LocalStorage = {
    get(): ITokens | null {
        const tokens = localStorage.getItem('token');
        try {
            if (!tokens) return null;
            return JSON.parse(tokens);
        } catch (err) {
            LocalStorage.remove();
            return null;
        }
    },

    set(tokens: ITokens): void {
        localStorage.setItem('token', JSON.stringify(tokens));
    },

    remove(): void {
        localStorage.removeItem('token');
    }
};

export default LocalStorage;
