import React, {
    FC, useEffect, useState
} from 'react';
import {
    Item, Text, ListBox, Menu, Flex, View, Divider, Image, useProvider, Section, Grid
} from '@adobe/react-spectrum';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import UserAdd from '@spectrum-icons/workflow/User';
import UsersShare from '@spectrum-icons/workflow/UsersShare';
import CreditCard from '@spectrum-icons/workflow/CreditCard';
import DownloadList from '@spectrum-icons/workflow/ViewList';
import Chat from '@spectrum-icons/workflow/Chat';
import Launch from '@spectrum-icons/workflow/Launch';
import UserGroup from '@spectrum-icons/workflow/UserGroup';
import PeopleGroup from '@spectrum-icons/workflow/PeopleGroup';
import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';

import { useAuthContext } from '~/Context/contextAuth';
import { TDashboardLibrary, PAGE_LIST } from '../IDashboard';
import { DashboardPremium } from '../dashboardPremium';
import { AccountButton } from '../Account/accountButton';

import NAKER_DARK from '~/Assets/image/LOGO_DARK.svg';
import NAKER_LIGHT from '~/Assets/image/LOGO_LIGHT.svg';

export const DashboardSidebar: FC = () => {
    const [page, setPage] = useState('personal');
    const navigate = useNavigate();
    const { colorScheme } = useProvider();
    const location = useLocation();
    const { isAdmin, isPremium, isLogged } = useAuthContext();

    useEffect(() => {
        const arr = location.pathname.split('/');
        const p = arr[arr.length - 1];
        if (arr[arr.length - 2] === 'analytics') {
            const page = `analytics/${p}`;
            if (PAGE_LIST.includes(page as TDashboardLibrary)) {
                setPage(page);
            }
        } else if (PAGE_LIST.includes(p as TDashboardLibrary)) {
            setPage(p);
        }
    }, []);

    const onButtonClick = (value: TDashboardLibrary) => {
        if (value === 'billing') navigate('/billing');
        if (value === 'contact') window.open('mailto:nicolas@naker.io?subject=Help%20me');
    };

    const onNavigateClick = (value) => {
        const page = value.anchorKey;
        if (page) {
            setPage(page);
            navigate(`${page}`);
        }
    };

    if (!isLogged) {
        return <></>;
    }

    if (!isPremium) {
        return <DashboardPremium />;
    }

    return (
        <View height="100%" width="100%">
            <Flex direction="row" width="100%" height="100vh">
                <View backgroundColor="gray-200">
                    <Flex
                        direction="column"
                        height="100%"
                        gap="size-100"
                        width="size-3000"
                    >
                        <Image
                            src={colorScheme === 'dark' ? NAKER_LIGHT : NAKER_DARK}
                            alt="naker-logo"
                            height="size-400"
                            marginTop="size-400"
                            marginBottom="size-200"
                        />
                        <ListBox
                            aria-label="Menu"
                            onSelectionChange={onNavigateClick}
                            selectedKeys={[page]}
                            selectionMode="single"
                            width="size-2400"
                            alignSelf="center"
                        >
                            <Section title="Dashboard">
                                <Item textValue="personal" key="personal">
                                    <UserAdd aria-label="personal" size="S" />
                                    <Text>Personal Library</Text>
                                </Item>
                                <Item textValue="shared" key="shared">
                                    <UsersShare aria-label="shared projects" />
                                    <Text>Shared projects</Text>
                                </Item>
                                <Item textValue="queue" key="queue">
                                    <DownloadList aria-label="render queue" />
                                    <Text>Render queue</Text>
                                </Item>
                                <Item textValue="scenario" key="scenario">
                                    <Launch aria-label="scenario" />
                                    <Text>Scenarios</Text>
                                </Item>
                            </Section>
                            {
                                isAdmin && (
                                    <Section title="DAM">
                                        <Item key="dam/upload" textValue="upload">
                                            <UploadToCloud />
                                            <Text>Files</Text>
                                        </Item>
                                    </Section>
                                )
                            }
                            {isAdmin
                                && (
                                    <Section title="Analytics">
                                        <Item key="analytics/users" textValue="upload">
                                            <UserGroup />
                                            <Text>Users</Text>
                                        </Item>
                                        <Item key="analytics/companies" textValue="companies">
                                            <PeopleGroup />
                                            <Text>Companies</Text>
                                        </Item>
                                    </Section>
                                )}
                        </ListBox>
                        <View flex />
                        <Divider size="M" />
                        <ListBox width="size-2400" marginStart="size-300" marginBottom="size-200" marginTop="size-100">
                            <Section>
                                <Item textValue="billing" key="billing">
                                    <CreditCard aria-label="billing" size="S" />
                                    <Text>Billing</Text>
                                </Item>
                                <Item textValue="contact" key="contact">
                                    <Chat aria-label="chat" size="S" />
                                    <Text>Help/Contact</Text>
                                </Item>
                            </Section>
                        </ListBox>
                    </Flex>
                </View>
                <View width="100%" height="100%" backgroundColor="gray-100">
                    <Grid
                        areas={[
                            'content account',
                            'content account'
                        ]}
                        columns={['3fr', 'size-1000']}
                        rows={['auto', 'size-1000']}
                        height="100vh"
                    >
                        <View gridArea="account">
                            <AccountButton />
                        </View>
                        <View gridArea="content">
                            <Outlet />
                        </View>
                    </Grid>
                </View>
            </Flex>
        </View>
    );
};
