import { Quaternion } from '@babylonjs/core/Maths/math';
import { IResolution, IGeometryPointFlat } from 'sharedtypes/math';
import {
    IRtxCamera, IRtxEnvironment, IRtxPlane, IRtxScenery, IVideoRatio
} from 'sharedtypes/rtx/rtx';
import { IEnvironmentOptions, IScenarioImage, IScenarioVideo } from 'sharedtypes/rtx/scenario';

import { DegreeToRadian, deg2rad } from '~/Utils/math';
import { IVariantModel } from '~/Scene/Content/contentModel';
import { DEFAULT_CAMERA_GEOMETRY } from '~/Scene/Scenery/sceneryCamera';

export interface IScenarioShared {
    exposure: number;
    environment: IEnvironmentOptions
}

export interface IStateVariant {
    model: string;
    variant: IVariantModel;
    id: string;
}

export const getRtxEnvironment = async (
    options: IEnvironmentOptions
): Promise<IRtxEnvironment> => {
    const { skyorientation, skydiffuse } = options;
    return {
        url: skydiffuse,
        orientation: {
            x: 0,
            y: deg2rad(skyorientation) || 0,
            z: 0
        }
    };
};

export const getRtxEnvironments = async (s: IEnvironmentOptions[]): Promise<IRtxEnvironment[]> => {
    const envs: IRtxEnvironment[] = [];
    for (let i = 0; i < s.length; i++) {
        envs.push(await getRtxEnvironment(s[i]));
    }
    return envs;
};

export const getRtxPlane = async (options: IEnvironmentOptions): Promise<IRtxPlane> => ({
    visibility: options.shadowGroundVisibility,
    height: options.shadowGroundHeight
});

export const getRtxScenery = async (
    state: IScenarioImage | IScenarioVideo,
    options: IEnvironmentOptions
): Promise<IRtxScenery> => {
    const { backgroundStrength } = state;
    return {
        environment: await getRtxEnvironment(options),
        environments: await getRtxEnvironments([options]),
        background_strength: backgroundStrength,
        plane: await getRtxPlane(options),
        exposure: options.skybrightness,
    };
};

export const getRtxCamera = async (geo: IGeometryPointFlat): Promise<IRtxCamera> => {
    const defPos = DEFAULT_CAMERA_GEOMETRY.position;
    const position = {
        x: geo.position.x + defPos.x,
        y: geo.position.y + defPos.y,
        z: geo.position.z + defPos.z,
    };

    const rot = geo.rotation;
    const quat = Quaternion.FromEulerAngles(
        rot.x * DegreeToRadian,
        rot.y * DegreeToRadian,
        rot.z * DegreeToRadian
    );
    const rotation = {
        x: quat.x,
        y: quat.y,
        z: quat.z,
        w: quat.w
    };

    const json: IRtxCamera = {
        position,
        rotation,
        fov: 0.4 * 0.875
    };
    return json;
};

export const getRtxCameras = async (povs: IGeometryPointFlat[]): Promise<IRtxCamera[]> => {
    const map: IRtxCamera[] = [];
    for (let i = 0; i < povs.length; i++) {
        const pov = povs[i];
        const fullPov = await getRtxCamera(pov);
        map.push(fullPov);
    }
    return map;
};

export const getResolution = (res: IVideoRatio, resolution: IResolution): IResolution => {
    const defaultRes = 1024;
    const dividedRes = 540;
    const qual = Number(resolution) / 1000;
    const ratio = defaultRes * qual;
    const smallRatio = dividedRes * qual;
    if (res === '1:1') {
        return {
            name: res,
            width: ratio,
            height: ratio
        };
    } if (res === '9:16') {
        return {
            name: res,
            width: smallRatio,
            height: ratio
        };
    }
    return {
        name: '16:9',
        width: ratio,
        height: smallRatio
    };
};
