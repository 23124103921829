import React, {
    FC, createContext, useContext, useState, useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IUser, ITokens } from 'sharedtypes';

import { api, LocalStorage, apiSpy } from '~/Api';
import { apiSocket } from '~/Api/apiSocket';
import env, { CONTEXT, context } from '~/Services/Environment/environment';
import { setUserDamFromEmail } from '~/Utils/dam';

import LocalUser from './localUser.json';
import { getQueryParam } from '~/Utils/html';
import { refreshToken } from '~/Api/api';

interface IAuth {
    user: IUser;
    login: (token: ITokens) => void;
    logout: () => void;
    isLogged: boolean;
    isPremium: boolean;
    isAdmin: boolean;
    isLoading: boolean;
    updateUser: (u: IUser) => void;
}

interface IAuthUser {
    user: IUser;
    isLogged: boolean;
    isPremium: boolean;
    isAdmin: boolean;
    isLoading: boolean;
}

export const AuthContext = createContext<IAuth>(null);

export const useAuthContext = (): IAuth => useContext(AuthContext);

export const DASHBOARD_PAGE = '/dashboard/personal';

const DEFAULT_AUTH: IAuthUser = {
    user: null,
    isLogged: false,
    isPremium: false,
    isAdmin: true,
    isLoading: true
};

const PATH_NEED_REDIRECTION = ['studio', 'dashboard'];

const AuthProvider: FC = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState<IAuthUser>(DEFAULT_AUTH);

    const logout = () => {
        setAuth({ ...DEFAULT_AUTH, isLoading: false });
        //* Make sure there is no more bad token
        LocalStorage.remove();
        //* Otherwise page confirm or password will also be redirected
        const { location } = window;
        PATH_NEED_REDIRECTION.forEach((v) => {
            if (location.href.includes(v)) navigate('/login');
        });
    };

    const disconnectUser = () => {
        toast.error('You have been disconnected, you will be redirected to the login page.');
        setTimeout(() => {
            logout();
        }, 3000);
    };

    const updateUser = (user: IUser) => {
        auth.user = user;
        setAuth(auth);
    };

    const logUser = (user: IUser) => {
        if (env.sentry) apiSpy.startSentry();
        if (env.intercom) apiSpy.identify(user);
        const isAdmin = context === CONTEXT.LOCAL || (user && user.admin);
        const isPremium = isAdmin || (user.premium_level && user.premium_level !== 'none');
        setAuth({
            user, isLogged: true, isAdmin, isPremium, isLoading: false
        });

        setUserDamFromEmail(user.email);
        const { pathname } = window.location;
        // If on studio, stay on studio
        if (pathname.includes('studio')) navigate(pathname);
        // else if not on dashboard, redirect to dashboard
        else if (!pathname.includes('dashboard')) navigate(DASHBOARD_PAGE);
    };

    const getUserFromSocket = () => {
        apiSocket.getSocket().emit('user:connect', {}, ({ err, data }) => {
            if (err) {
                console.error(err);
                return logout();
            }
            return logUser(data);
        });
    };

    const getUser = async () => {
        const tokens = LocalStorage.get();
        if (!tokens) return logout();
        try {
            const newTokens = await refreshToken(tokens.refresh_token);
            LocalStorage.set(newTokens);
            const connected = await apiSocket.tryConnect(tokens);
            if (!connected) return logout();
            return getUserFromSocket();
        } catch (err) {
            return logout();
        }
    };

    const login = (user: IUser) => {
        const { admin } = user;
        if (env.admin && !admin) {
            toast.error(`You must be an admin to access ${env.subdomain}`);
        } else {
            const origin = getQueryParam('origin');
            apiSpy.track('Platform Login', { origin });
            api.setToken(user);
            // Make sure token is updated before going to dashboard
            setTimeout(() => {
                getUser();
            }, 100);
        }
    };

    useEffect(() => {
        apiSocket.onDisconnect = disconnectUser;
        api.onDisconnect = disconnectUser;

        if (!env.saving) logUser(LocalUser);
        else getUser();
        return null;
    }, []);

    if (auth.isLoading) {
        return <></>;
    }

    return (
        <AuthContext.Provider
            value={{
                isLoading: auth.isLoading,
                isLogged: auth.isLogged,
                isAdmin: auth.isAdmin,
                isPremium: auth.isPremium,
                user: auth.user,
                login,
                logout,
                updateUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
