import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

import { PatternMesh } from '../Mesh/patternMesh';
import { PatternGroup } from './patternGroup';

export class PatternModel extends PatternGroup {
    public setMeshes(meshes: Mesh[]): void {
        this.destroyMeshes();
        this.setGroupParent();
        if (meshes.length === 0) return;
        this.addParentMeshes(meshes);
        // Get the group maximum bounds
        this.checkBounds();
        // Get center of the group of meshes
        this.getCenter();
        // Set mesh position so that the group is centered in the boundmesh
        this.setParentsRelativePosition();
        // Get and Set the group scale so that it fits in the boundmesh
        this.scaleGroup();
    }

    private setGroupParent() {
        // Reset the main mesh for correct scale and center calculation
        const mesh = new TransformNode('__modelroot__', this._system.scene);
        // this.mesh.showBoundingBox = true;
        // this.setMeshInvisible(this.mesh);
        // if (righthanded) this.mesh.scaling = new Vector3(-1, 1, 1);
        // else this.mesh.scaling = new Vector3(1, 1, 1);
        mesh.scaling = new Vector3(1, 1, 1);

        this.scaleParent.scaling = new Vector3(1, 1, 1);
        this.scaleParent.parent = mesh;

        if (this.mesh) this.mesh.dispose();
        this.mesh = mesh;
    }

    public patternParents: PatternMesh[] = [];

    private addParentMeshes(meshes: Mesh[]) {
        meshes.forEach((mesh) => {
            mesh.parent = this.scaleParent;
            const newmesh = new PatternMesh(this._system);
            newmesh.setMesh(mesh);
            this.patternParents.push(newmesh);
            const children = mesh.getDescendants(false);
            if (children.length) this.addChildren(children);
            else this.addChildren([mesh]);
        });
    }

    private addChildren(meshes: Mesh[]) {
        meshes.forEach((mesh) => {
            // Instance can't have its own material
            if (mesh.subMeshes !== undefined && !(mesh instanceof InstancedMesh)) {
                const newPatternMesh = new PatternMesh(this._system);
                newPatternMesh.setMesh(mesh);
                // mesh.showBoundingBox = true;
                if (this.patternMeshes.indexOf(newPatternMesh) === -1) {
                    this.patternMeshes.push(newPatternMesh);
                }
            }
        });
    }

    private setParentsRelativePosition() {
        this.patternParents.forEach((patternParent) => {
            patternParent.mesh.position.subtractInPlace(this.center);
        });
    }

    private scaleVector: Vector3;

    // Used when first import to use real scale for model and keep original Size
    public originalScale: number;

    private scaleGroup() {
        let max = 0;
        ['x', 'y', 'z'].forEach((key) => {
            const scale = Math.abs(this.maximum[key] - this.minimum[key]);
            if (scale > max) max = scale;
        });
        this.originalScale = max;
        this.scaleVector = new Vector3(1 / max, 1 / max, 1 / max);
        this.scaleParent.scaling = this.scaleVector;
    }

    public destroyMeshes(): void {
        this.patternParents = [];
        this._destroyMeshes();
    }
}
