import React, {
    FC
} from 'react';
import {
    Flex, TableView, TableHeader, TableBody,
    Column, Row, Cell, ToggleButton, IllustratedMessage, Heading, Content, ActionButton, Text
} from '@adobe/react-spectrum';
import { useNavigate } from 'react-router-dom';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { IUser } from 'sharedtypes';

import { ORANGE_MEDIUM } from '~/Utils/color';

interface ITableData {
    users: IUser[];
    makeAdmin: (u: IUser) => void;
}

function renderEmptyState() {
    return (
        <IllustratedMessage>
            <NotFound />
            <Heading>No results</Heading>
            <Content>No results found</Content>
        </IllustratedMessage>
    );
}

export const DashboardTable: FC<ITableData> = ({ users, makeAdmin }) => {
    const navigate = useNavigate();
    const columns = [
        { name: 'Name', uid: 'name' },
        { name: 'Email', uid: 'email' },
        { name: 'Admin', uid: 'admin' }
    ];

    const redirectToProfile = (user: IUser) => {
        navigate(`user/${user._id}`, {
            state: user
        });
    };

    return (
        <Flex height="size-5000" width="100%" direction="column" gap="size-150">
            <TableView
                flex
                aria-label="Users Table"
                renderEmptyState={renderEmptyState}
                isQuiet
            >
                <TableHeader columns={columns}>
                    {(column) => (
                        <Column
                            key={column.uid}
                        >
                            {column.name}
                        </Column>
                    )}
                </TableHeader>
                <TableBody items={users}>
                    {(item) => (
                        <Row key={item.name}>
                            {(key) => {
                                if (key === 'admin') {
                                    return (
                                        <Cell>
                                            <ToggleButton
                                                isEmphasized={item.admin.toString() === 'true'}
                                                onPress={() => { makeAdmin(item); }}
                                                isSelected={item.admin.toString() === 'true'}
                                            >
                                                {item[key]}
                                            </ToggleButton>
                                        </Cell>
                                    );
                                }
                                if (key === 'name') {
                                    return (
                                        <Cell>
                                            <ActionButton
                                                isQuiet
                                                onPress={() => redirectToProfile(item)}
                                            >
                                                <Text UNSAFE_style={{ color: ORANGE_MEDIUM, textDecoration: 'underline' }}>
                                                    {item.name}
                                                </Text>
                                            </ActionButton>
                                        </Cell>
                                    );
                                }
                                return (
                                    <Cell>{item[key]}</Cell>
                                );
                            }}

                        </Row>
                    )}
                </TableBody>
            </TableView>

        </Flex>
    );
};
