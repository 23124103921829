import React, {
    FC, useEffect, useMemo, useState
} from 'react';
import {
    Flex, View, ProgressCircle, Menu, Item, MenuTrigger, ActionButton, Text, Heading, Divider
} from '@adobe/react-spectrum';
import { useParams } from 'react-router-dom';
import { IUser } from 'sharedtypes';
import { IAnalytics } from 'sharedtypes/analytic';

import { ORANGE_MEDIUM } from '~/Utils/color';
import {
    IAnInterval, getAnalytics, getUserInfos, getCleanDate
} from '~/Api/Analytics';
import { ImageCharts } from '../Charts/imageCharts';
import { VideoCharts } from '../Charts/videoCharts';
import { UserProjectsAnalytics } from './userProjects';
import { getStringFromSeconds } from '~/Services/Rtx/rtxTimeCalculator';

export const UserDashboard: FC = () => {
    const params = useParams();
    const [int, setInt] = useState<IAnInterval>('month');
    const [images, setImages] = useState<IAnalytics>([]);
    const [videos, setVideos] = useState<IAnalytics>([]);
    const serverTimeUsed = useMemo(() => {
        let totalTime = 0;
        images.forEach((im) => {
            totalTime += im.duration;
        });
        videos.forEach((im) => {
            totalTime += im.duration;
        });
        return getStringFromSeconds(totalTime);
    }, [images, videos]);

    const [user, setUser] = useState<IUser>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            getUserInfos(params.id, async ({ err, data }) => {
                if (err) {
                    console.error(err);
                } else {
                    setUser(data.user);
                    setLoading(false);
                }
            });
        };
        loadUser();
    }, []);

    useEffect(() => {
        const fetchData = () => {
            getAnalytics(int, params.id, async ({ err, data }) => {
                if (err) {
                    console.error(err);
                } else {
                    setImages(data.images);
                    setVideos(data.videos);
                }
            });
        };
        if (user) {
            fetchData();
        }
    }, [int, user]);

    if (loading) {
        return (
            <View>
                <ProgressCircle aria-label="loading" isIndeterminate />
            </View>
        );
    }

    return (
        <View marginStart="size-1000">
            <Flex direction="row" alignItems="center" gap="size-325" marginTop="size-200">
                <Heading level={1}>{`${user.name} analytics`}</Heading>
                <Text>
                    Last log in:
                    {' '}
                    <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                        {getCleanDate(user.updated_at)}
                    </Text>
                </Text>
            </Flex>
            <Divider size="M" marginBottom="size-400" />
            <View
                height="calc(100vh - 180px)"
                UNSAFE_style={{
                    overflow: 'hidden visible',
                }}
            >
                <Flex direction="row" wrap gap="size-400" alignItems="center">
                    <View>
                        Total images:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {user.images_rendered || 0}
                        </Text>
                    </View>
                    <View>
                        Total videos:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {user.videos_rendered || 0}
                        </Text>
                    </View>
                    <View>
                        Server time used:
                        {' '}
                        <Text UNSAFE_style={{ color: ORANGE_MEDIUM }}>
                            {serverTimeUsed}
                        </Text>
                    </View>
                    <View marginStart="auto">
                        <MenuTrigger>
                            <ActionButton>
                                {`This ${int}`}
                            </ActionButton>
                            <Menu onAction={(key) => setInt(key as IAnInterval)}>
                                <Item key="day">This day</Item>
                                <Item key="week">This week</Item>
                                <Item key="month">This month</Item>
                                <Item key="year">This year</Item>
                            </Menu>
                        </MenuTrigger>
                    </View>
                </Flex>
                <Flex direction="row" wrap gap="size-1000">
                    <ImageCharts data={images} int={int} />
                    <VideoCharts data={videos} int={int} />
                </Flex>
                <UserProjectsAnalytics data={[...images, ...videos]} />
            </View>
        </View>
    );
};
