import React, { FC } from 'react';
import { AlertDialog } from '@adobe/react-spectrum';

interface IDeleteDialogProps {
    title: string;
    onDelete: () => void;
}

export const DeleteDialog: FC<IDeleteDialogProps> = ({
    title, onDelete
}: IDeleteDialogProps) => (
    <AlertDialog
        variant="destructive"
        title="Confirm Deletion"
        primaryActionLabel="Confirm"
        cancelLabel="Cancel"
        onPrimaryAction={onDelete}
    >
        {`Are you sure you want to delete this ${title}?`}
    </AlertDialog>
);
