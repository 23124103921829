import React, { FC, useEffect, useState } from 'react';
import {
    Flex, Item, ActionGroup, Image, ActionButton
} from '@adobe/react-spectrum';

import { useNavigate } from 'react-router-dom';
import {
    ICompany
} from '~/Api/Analytics';

import NakerLogo from '~/Assets/image/Naker.jpg';
import AdeoLogo from '~/Assets/image/Adeo.png';
import VenumLogo from '~/Assets/image/Venum.jpg';

export const Companies: FC = () => {
    const [company, setCompany] = useState<ICompany>();
    const navigate = useNavigate();

    useEffect(() => {
        if (company) {
            navigate(`/dashboard/analytics/companies/${company}`);
        }
    }, [company]);

    return (
        <Flex direction="row" gap="size-200" margin="size-200">
            <div role="button" tabIndex={0} onClick={() => setCompany('naker')}>
                <img
                    src={NakerLogo}
                    width="200px"
                    height="200px"
                    alt="thumbnail"
                    style={{
                        cursor: 'pointer',
                        borderRadius: '10px'
                    }}
                />
            </div>
            <div role="button" tabIndex={0} onClick={() => setCompany('adeo')}>
                <img
                    src={AdeoLogo}
                    width="200px"
                    height="200px"
                    alt="thumbnail"
                    style={{
                        cursor: 'pointer',
                        borderRadius: '10px'
                    }}
                />
            </div>
            <div role="button" tabIndex={0} onClick={() => setCompany('venum')}>
                <img
                    src={VenumLogo}
                    width="200px"
                    height="200px"
                    alt="thumbnail"
                    style={{
                        cursor: 'pointer',
                        borderRadius: '10px'
                    }}
                />
            </div>
        </Flex>
    );
};
