import { toast } from 'react-toastify';
import {
    IVisualType, IRtxImage, IRtxVideo, IRtxModel
} from 'sharedtypes/rtx/rtx';

import { Observable } from '~/Utils/observable';
import { downloadFile } from '~/Utils/html';
import { apiSocket } from '~/Api/apiSocket';
import env from '../Environment/environment';

export const fetchAndDownload = (url: string, name: string): void => {
    fetch(url).then((t) => t.blob().then((b) => {
        downloadFile(URL.createObjectURL(b), name);
    }));
};

export interface IRaytracingData {
    progress?: number;
    message?: string;
    error?: string;
    data?: string;
    ext?: string;
    url?: string;
}

let lastDownloadUrl: string;
const glbDownloadCallback = ({ url, ext }: IRaytracingData) => {
    if (url && url !== lastDownloadUrl) {
        lastDownloadUrl = url;
        fetchAndDownload(url, `object${ext}`);
    }
};

export const checkRaytracingOption = (isAdmin: boolean, cb: () => void): void => {
    if (isAdmin) {
        cb();
    } else {
        apiSocket.getSocket().emit('user:level', null, ({ err, data }) => {
            if (err) {
                return toast.error(`Error while trying to access raytracing ${err}`);
            }
            if (!data || data === 'none' || data === 'starter') {
                return toast.error('You need to be at least a booster to access the raytracing.');
            }
            return cb();
        });
    }
};

interface IRTXVideoProgress {
    message: string;
    progress: number;
}

export enum IRaytracingEvent {
    Start,
    Add,
    Progress,
    Step,
    Done,
    Error,
    Close,
    Cancelled
}

class RaytracingObservable extends Observable<IRaytracingEvent, IRaytracingData> {
    private _rendering = false;

    protected get rendering() {
        return this._rendering;
    }

    protected set rendering(value) {
        this._rendering = value;
    }

    constructor() {
        super('Raytracing');

        // No socket in LOCAL DEV
        if (!env.saving) return;
        this.setSocketEvents();
    }

    public startRender = () => {
        this.rendering = true;
    }

    public isRendering = () => this.rendering;

    private setSocketEvents = () => {
        apiSocket.addListener((socket) => {
            socket.on('render:progress', (progress: IRTXVideoProgress) => {
                this.notify(IRaytracingEvent.Progress,
                    { progress: progress.progress, message: progress.message });
            });

            socket.on('render:cancelled', (message) => {
                this.notify(IRaytracingEvent.Cancelled, { error: message.data });
            });

            socket.on('render:done', (data) => {
                this.notify(IRaytracingEvent.Done, { progress: 100, ...data });
                const arr = data.data.split('/');
                const isOnDashboard = window.location.href.includes('dashboard');
                if (!isOnDashboard) fetchAndDownload(data.data, arr[arr.length - 1]);
            });

            socket.on('render:add', (data) => {
                this.notify(IRaytracingEvent.Add, data);
            });

            socket.on('render:close', () => {
                this.notify(IRaytracingEvent.Close, { progress: 100 });
                this.rendering = false;
            });

            socket.on('render:step', (message: string) => {
                this.notify(IRaytracingEvent.Step, { message });
            });

            socket.on('render:error', (error: string) => {
                console.error(error);
                this.notify(IRaytracingEvent.Error, { error });
            });

            socket.on('render:model', (data) => {
                glbDownloadCallback(data);
            });
        });
    }
}

export const raytracingObservable = new RaytracingObservable();

export const startRtxRender = (
    json: IRtxVideo | IRtxImage | IRtxModel,
    type: IVisualType
): void => {
    console.log(`Rendering ${type} with json`, json);
    raytracingObservable.startRender();
    apiSocket.getSocket().emit('render:start', { ...json, type }, (data) => {
    });
};

export const stopRender = (renderId: string): void => {
    apiSocket.getSocket().emit('render:stop', { id: renderId }, (res) => {
    });
};
