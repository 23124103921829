import React, { FC, useEffect, useState } from 'react';
import {
    View, ActionButton, Text, DialogTrigger,
    Dialog, Heading, Divider, Content, Flex, ProgressBar
} from '@adobe/react-spectrum';
import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';
import { IDamProgress } from 'sharedtypes/socket/damEvents';
import { toast } from 'react-toastify';
import { uploadMaterial, uploadModel } from '~/Services/Dam/upload';
import { DamDropzone } from './damDropzone';
import { apiSocket } from '~/Api/apiSocket';

export const DamUploadButton: FC = () => {
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(0);
    const [label, setLabel] = useState('');

    useEffect(() => {
        const listener = (params: IDamProgress) => {
            setProgress(params.progress);
            setLabel(params.message);
            if (params.progress === 100) {
                toast.success(params.message);
                setTimeout(() => {
                    setShow(false);
                    setProgress(0);
                }, 3000);
            }
        };
        apiSocket.addListener((socket) => {
            socket.on('dam:progress', listener);
        });
        return function cleanup() {
            apiSocket.getSocket().off('dam:progress', listener);
        };
    }, []);

    const startUpload = (files: File[], next: (f: File[]) => void) => {
        setShow(true);
        next(files);
    };

    return (
        <View>
            <DialogTrigger>
                <ActionButton>
                    <UploadToCloud />
                    <Text>Upload file online</Text>
                </ActionButton>
                <Dialog width="calc(size-4000 * 2)" isDismissable>
                    <Heading>
                        Select file to download
                        {
                            show
                            && (
                                <Flex justifyContent="center" margin="0 auto">
                                    <ProgressBar
                                        marginTop="size-100"
                                        width="size-2000"
                                        label={label}
                                        size="S"
                                        value={progress}
                                        aria-label="render-bar"
                                    />
                                </Flex>
                            )
                        }
                    </Heading>
                    <Divider />
                    <Content>
                        <Flex direction="row" gap="size-200">
                            <DamDropzone
                                acceptedTypes={['application/octet-stream']}
                                title="Select your 3D model"
                                onDrop={(files) => startUpload(files, uploadModel)}
                                fileTypes={['.glb']}
                            />
                            <DamDropzone
                                acceptedTypes={['image/jpeg', 'image/png']}
                                title="Select your material"
                                onDrop={(files) => startUpload(files, uploadMaterial)}
                                fileTypes={['.png', '.jpg', '.jpeg']}
                                allowsMultiple
                            />
                        </Flex>
                    </Content>
                </Dialog>
            </DialogTrigger>
        </View>
    );
};
