import React, { useState, FC } from 'react';
import { SearchField } from '@adobe/react-spectrum';

interface IProjectSearchBarProps {
    value: string;
    setValue: (v: string) => void;
}

export const ProjectSearchBar: FC<IProjectSearchBarProps> = ({ value, setValue }) => {
    const [localValue, setLocalValue] = useState(value || '');
    const [searchTimeout, setSearchTimeout] = useState<
        NodeJS.Timeout | undefined
    >();

    const getSuggestions = (newValue: string) => {
        setLocalValue(newValue);
        if (searchTimeout) clearTimeout(searchTimeout);
        // eslint-disable-next-line
        setSearchTimeout(setTimeout(() => {
            setValue(newValue);
        }, 200));
    };

    return (
        <SearchField
            UNSAFE_className="searchBar"
            label="Search project"
            marginBottom="size-200"
            width="size-3600"
            aria-label="Search by name"
            value={localValue}
            onInput={(e) => getSuggestions((e.target as HTMLTextAreaElement).value)}
        />
    );
};
