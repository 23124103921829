import { io, Socket } from 'socket.io-client';
import { toast } from 'react-toastify';
import { ITokens } from 'sharedtypes';
import { IClientEvents } from 'sharedtypes/socket/clientEvents';
import { IServerEvents } from 'sharedtypes/socket/serverEvents';

import env from '~/Services/Environment/environment';

type TSocket = Socket<IServerEvents, IClientEvents>
type TSocketListener = ((s: Socket) => void)[];

export class ApiSocket {
    private socket: TSocket;

    private tryConnected = false;

    private onConnectListeners: TSocketListener = [];

    public tryConnect(tokens: ITokens): Promise<boolean> | boolean {
        if (env.saving && (!this.socket || !this.socket.connected)) {
            return this.connect(env.apiurl, tokens);
        }
        return true;
    }

    private errorConnectionAttemp(attempt: number) {
        if (attempt === 5) toast.error('We have difficulties connecting to server. Make sure you are connnected to internet or try again later.');
        if (attempt === 10) { if (this.onDisconnect) this.onDisconnect(); }
    }

    private handleRefresh = async (resolve) => {
        try {
            // await refreshToken();
            return resolve(true);
        } catch (err) {
            if (!err.response) {
                console.error(err);
                return resolve(false);
            }
            const errData = err.response.data.message as string;
            console.error(`Error while refreshing token: ${errData}`);
            return resolve(false);
        }
    }

    private async connect(host: string, token: ITokens): Promise<boolean> {
        let connectionError = 0;
        return new Promise<boolean>((resolve, reject) => {
            this.tryConnected = true;
            this.socket = io(host, {
                path: '/socket.io',
                transports: ['websocket'],
                rejectUnauthorized: false,
                reconnectionDelay: 3000,
                reconnectionAttempts: 10,
                auth: {
                    token: token.access_token
                }
            });
            this.socket.on('connect_error', (err) => {
                connectionError++;
                const error = err.message;
                if (error === '403: No token provided.' || error === '403: Invalid token.') {
                    return this.handleRefresh(resolve);
                }
                this.errorConnectionAttemp(connectionError);
                console.error(err.message);
                reject(err);
                return resolve(false);
            });
            this.socket.on('connect', () => {
                this.onConnectListeners.forEach((func) => { func(this.socket); });
                this.setListeners();
                return this.handleRefresh(resolve);
            });
        });
    }

    public refreshAuth(access_token: string, callback: (success) => void): void {
        this.socket.auth = { token: access_token };
        this.socket.disconnect().connect();
        this.socket.on('connect', () => callback(true));
        this.socket.on('connect_error', () => callback(false));
    }

    public onDisconnect: () => void;

    private setListeners() {
        this.socket.on('reconnect', (attempt) => {
            if (attempt >= 5) { toast.success('Successfully reconnected!'); }
        });

        this.socket.on('reconnect_attempt', (attempt) => {
            this.errorConnectionAttemp(attempt);
        });

        this.socket.on('disconnect', (err) => {
            console.error('Socket disconnect', err);
        });
    }

    public getSocket = (): TSocket => this.socket;

    public addListener(cb: (socket: TSocket) => void): void {
        if (!this.tryConnected) {
            this.onConnectListeners.push(cb);
        } else {
            cb(this.socket);
        }
    }
}

export const apiSocket = new ApiSocket();
