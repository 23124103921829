import React, {
    FC, useEffect, useRef, useState
} from 'react';
import { IUser } from 'sharedtypes';

import jwt_decode from 'jwt-decode';
import { useAuthContext } from '~/Context/contextAuth';
import api from '~/Api/api';

declare global {
    interface Window {
        google: any;
    }
}

const CLIENT_ID = '746660630463-71395qasohf6hsv4ns19ac7b3lcri1cm.apps.googleusercontent.com';

interface IGoogleProp {
    loginUser: (data: IUser) => void;
    currentPage: string;
    isUserDisconnected: boolean;
}

interface ILoginUser {
    loginUser: (data: IUser) => void;
    name: string,
    email: string
}

const onSuccess = (loginUserProps: ILoginUser) => {
    const { name, email, loginUser } = loginUserProps;
    api.post('user/login/social', { name, email }, null, (data) => {
        if (data) loginUser(data);
    });
};

const Google: FC<IGoogleProp> = ({ loginUser, currentPage, isUserDisconnected }) => {
    const divRef = useRef<HTMLDivElement>(null);
    const { isLogged } = useAuthContext();
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const handleGoogleSignIn = (res) => {
        const { given_name, email } = res;
        if (!given_name || !email) return;
        const name = given_name;
        onSuccess({ loginUser, name, email });
    };

    const customButtonStyle = {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        text: currentPage === 'login' ? 'signin_with' : 'signup_with',
        shape: 'rectangular',
        logo_alignment: 'right',
        width: '240px',
    };

    useEffect(() => {
        if (scriptLoaded) return undefined;

        const initializeGoogle = () => {
            if (!window.google || scriptLoaded || isLogged) return;
            if (!window.google.accounts || !window.google.accounts.id) return;
            const { id } = window.google.accounts;
            if (isUserDisconnected) window.google.accounts.id.disableAutoSelect();
            setScriptLoaded(true);

            id.initialize({
                client_id: CLIENT_ID,
                auto_select: true,
                context: currentPage === 'login' ? 'signin' : 'signup',
                itp_support: true,
                callback: async (res) => {
                    const responsePayload = jwt_decode(res.credential);
                    await handleGoogleSignIn(responsePayload);
                },
            });

            id.renderButton(divRef.current, customButtonStyle);
            id.prompt();
        };

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = initializeGoogle;
        script.async = true;
        script.defer = true;
        script.id = 'google-client-script';
        document.querySelector('body').appendChild(script);

        return () => {
            if (window.google && window.google.accounts) window.google.accounts.id.cancel();
            document.getElementById('google-client-script').remove();
        };
    }, [scriptLoaded, window.google, currentPage]);

    return (
        <div
            ref={divRef}
            style={{
                overflow: 'hidden', width: '240px', height: '40px', borderRadius: '4px'
            }}
        />
    );
};

export default Google;
