import {
    EasingFunction,
    CircleEase,
    BounceEase,
    BackEase,
    CubicEase,
    ElasticEase,
    ExponentialEase,
    PowerEase,
    QuadraticEase,
    QuarticEase,
    QuinticEase,
    SineEase,
    BezierCurveEase
} from '@babylonjs/core/Animations/easing';
import { clamp } from '../../../Utils/math';
import { SystemAnimation } from '../systemAnimation';
import { Ease, EaseMode, LinearEase } from './animationEasing';

/**
 * animation which can be create aniwhere and which will be run by system
 */

export class Animation {
    private system: SystemAnimation;

    /**
     * Starting value
     */
    private start = 1;

    /**
     * Current progress
     */
    public count = 0;

    /**
     * Progress step used in each run call
     */
    public step = 1;

    /**
     * Is the animation running or not
     */
    public running = false;

    /**
     * How many step is needed to end the animation
     */
    public howmany: number;

    /**
     * Function called at each run and used to animate something
     */
    private funct: (perc: number, count: number) => void;

    /**
     * Function called when animation is over
     */
    private functend: (force?: boolean) => void;

    /**
     * Key of animation used to store it
     */
    public key: string;

    /**
     * Easing of the animation
     */
    public easing: EasingFunction;

    /**
     * Create a new animation
     * @param system Manager where to push animation
     * @param howmany How many step is needed to end the animation
     * @param start Starting value
     * @param step Progress step used in each run call
     */
    constructor(system: SystemAnimation, howmany?: number, start?: number, step?: number) {
        this.system = system;
        if (howmany) this.setParam(howmany, start, step);
        this.key = Math.random().toString(36);
        // Set default easing mode
        this.setEasing(Ease.Linear);
        return this;
    }

    /**
     * Set animation parameters
     * @param howmany How many step is needed to end the animation
     * @param start Starting value
     * @param step Progress step used in each run call
     */
    public setParam(howmany: number, start?: number, step?: number): void {
        if (this.running) this.stop(true);
        this.howmany = howmany - 1;
        if (step) this.step = step;
        if (start) this.start = start;
        this.count = this.start;
    }

    public setEasing(ease: Ease, mode?: EaseMode): void {
        if (ease === Ease.Linear) this.easing = new LinearEase();
        else if (ease === Ease.Circle) this.easing = new CircleEase();
        else if (ease === Ease.Back) this.easing = new BackEase();
        else if (ease === Ease.Bounce) this.easing = new BounceEase();
        else if (ease === Ease.Cubic) this.easing = new CubicEase();
        else if (ease === Ease.Elastic) this.easing = new ElasticEase();
        else if (ease === Ease.Exponential) this.easing = new ExponentialEase();
        else if (ease === Ease.Power) this.easing = new PowerEase();
        else if (ease === Ease.Quadratic) this.easing = new QuadraticEase();
        else if (ease === Ease.Quartic) this.easing = new QuarticEase();
        else if (ease === Ease.Quintic) this.easing = new QuinticEase();
        else if (ease === Ease.Sine) this.easing = new SineEase();
        else if (ease === Ease.BezierCurve) this.easing = new BezierCurveEase();

        if (mode && this.easing) {
            if (mode === EaseMode.In) {
                this.easing.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
            } else if (mode === EaseMode.Out) {
                this.easing.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
            } else if (mode === EaseMode.InOut) {
                this.easing.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            }
        }
    }

    /**
     * Create an infinite animation which will never stop
     * @param funct Function called at each run and used to animate something
     */
    public infinite(funct: (perc: number, count: number) => void): void {
        const howmany = 1000000000000;
        this.simple(howmany, funct);
    }

    /**
     * Create an infinite animation which will never stop
     * @param howmany How many step is needed to end the animation
     * @param alter How many step do we need to alternate the animation
     * @param funct1 Alternate function 1
     * @param funct2 Alternate function 2
     * @param functend Function called when animation is over
     */
    public alternate(
        howmany: number,
        alter: number,
        funct1: (perc: number, count: number) => void,
        funct2?: (perc: number, count: number) => void,
        functend?: () => void
    ): void {
        let ft = true;
        let alterstep = 0;
        this.simple(howmany, (perc, count) => {
            if (count > alter * (alterstep + 1)) {
                ft = !ft;
                alterstep++;
            }
            count -= alter * alterstep;
            perc = count / alter;
            if (ft) funct1(perc, count);
            else if (funct2) funct2(perc, count);
        }, functend);
    }

    /**
     * Create an infinite animation which will never stop
     * @param howmany How many step is needed to end the animation
     * @param loop How many step do we need to loopn the animation
     * @param funct Function called at each run and used to animate something
     * @param functloop Function called everytime the loop goes back to start
     * @param functend Function called when animation is over
     */
    public loop(
        howmany: number,
        loop: number,
        funct: (perc: number, count: number) => void,
        functloop?: () => void,
        functend?: () => void
    ): void {
        let loopstep = 0;
        this.simple(howmany, (perc, count) => {
            if (count > loop * (loopstep + 1)) {
                loopstep++;
                if (functloop) functloop();
            }
            count -= loop * loopstep;
            perc = count / loop;
            funct(perc, count);
        }, functend);
    }

    /**
     * Reverse the current step of the animation
     */
    public reverse(): void {
        this.step = -this.step;
    }

    public interval(time: number, funct: () => void): void {
        let timeTest = 0;
        this.infinite(() => {
            timeTest += 1000 / this.system.fps;
            if (timeTest > time) {
                funct();
                timeTest = 0;
            }
        });
    }

    // steps (steps:any) {
    // 	this.loopsteps(steps, 0);
    // 	return this;
    // }
    //
    // loopsteps (steps:any, step:number) {
    // 	if (!steps[step]) return;
    // 	let stepO = steps[step];
    // 	this.simple(stepO.howmany, (perc, count) => {
    // 		stepO.funct(count, perc);
    // 	}, () => {
    // 		stepO.functend();
    // 		step++
    // 		if (!this.running) this.loopsteps(steps, step);
    // 	});
    // }

    /**
     * Easiest way to lauch an animation (By default it start at 0 and use a step of 1)
     * @param howmany How many step is needed to end the animation
     * @param funct Function called at each run and used to animate something
     * @param functend Function called when animation is over
     */
    public simple(
        howmany: number,
        funct: (perc: number, count: number) => void,
        functend?: () => void
    ): void {
        // Equal 1 so that it start quick
        this.start = 1;
        this.count = 1;
        this.step = 1;
        this.howmany = howmany;
        this.go(funct, functend);
    }

    /**
     * Set main animation functions and launch it
     * (Often used after setting the animation parameters)
     * @param funct Function called at each run and used to animate something
     * @param functend Function called when animation is over
     */
    public go(
        funct: (perc: number, count: number) => void,
        functend?: (force: boolean) => void
    ): void {
        this.resetVar();
        this.running = true;
        this.funct = funct;
        this.functend = functend;
        this.play();
    }

    public easedPerc = 0

    public run(count: number): void {
        const easedPerc = this.easing.ease(count / this.howmany);
        this.easedPerc = clamp(easedPerc);
        const easedCount = this.easedPerc * this.howmany;
        this.funct(this.easedPerc, easedCount);
    }

    /**
     * Restart animation
     */
    public restart(): void {
        if (this.running) {
            this.pause();
            this.go(this.funct, this.functend);
        }
    }

    private resetVar(force?: boolean) {
        this.count = this.start;
        if (this.functend && this.running) this.functend(force);
        this.functend = null;
    }

    /**
     * Stop animation
     * @param force Sent to functend so that it knows the stop can be forced
     * and is not due to the end of the animation
     */
    public stop(force?: boolean): void {
        this.resetVar(force);
        this.running = false;
    }

    /**
     * Pause animation
     */
    public pause(): void {
        this.running = false;
    }

    /**
     * Play animation
     */
    public play(): void {
        this.system.addAnimation(this);
    }
}
