import React, { FC, useEffect, useState } from 'react';
import {
    View, SearchField
} from '@adobe/react-spectrum';
import { IUser } from 'sharedtypes';

import { getUsers, switchAdmin } from '~/Api/Analytics';

import { DashboardTable } from './analyticsTable';
import { DashboardHeader } from '../dashboardHeader';

export const AnalyticsDashboard: FC = () => {
    const [search, setSearch] = useState<string>('');
    const [users, setUsers] = useState<IUser[]>([]);

    const searchUsers = async (value: string) => {
        setSearch(value);
        const [err, res] = await getUsers(value);
        if (err || !res) { return console.error(err); }
        return setUsers([...res.data.map((x) => ({
            ...x,
            admin: (!!x.admin).toString()
        }))]);
    };

    const makeAdmin = async (u: IUser) => {
        const [err, res] = await switchAdmin(u._id);
        if (err || !res) { return console.error(err); }
        return searchUsers(search);
    };

    useEffect(() => {
        searchUsers(search);
    }, []);

    return (
        <View>
            <DashboardHeader title="User analytics">
                <SearchField
                    label="Search User"
                    width="size-3600"
                    value={search}
                    onInput={(e) => searchUsers((e.target as HTMLTextAreaElement).value)}
                />
                <DashboardTable
                    users={users}
                    makeAdmin={makeAdmin}
                />
            </DashboardHeader>
        </View>
    );
};
