import {
    Button, Content, Dialog, Flex, Footer, Heading
} from '@adobe/react-spectrum';
import React, { FC } from 'react';
import { IDialogProps } from '~/Types';

const BillingConfirmationDialog: FC<IDialogProps> = ({
    setOpen, plan, open, setClose
}) => (
    <>
        {open && (
            <Dialog width="size-1200">
                <Content>
                    <Heading level={2}>
                        You&apos;re opting for a
                        {' '}
                        {plan}
                        {' subscription ?'}
                    </Heading>
                </Content>
                <Footer>
                    <Flex direction="row" gap="size-115">
                        <Button variant="secondary" onPress={() => setClose()}>
                            No
                        </Button>
                        <Button
                            variant="cta"
                            onPress={() => {
                                setClose();
                                setOpen(true);
                            }}
                        >
                            Yes
                        </Button>
                    </Flex>
                </Footer>
            </Dialog>
        )}
        <></>
    </>
);

export default BillingConfirmationDialog;
