import {
    ActionButton,
    Badge,
    Button,
    Cell,
    Column, Divider, Flex, Heading, ProgressBar,
    Row, StatusLight, TableBody, TableHeader, TableView, View
} from '@adobe/react-spectrum';
import React, {
    useState, useEffect, FC, Key, useRef
} from 'react';
import DownloadFromCloud from '@spectrum-icons/workflow/DownloadFromCloud';
import DataRefresh from '@spectrum-icons/workflow/DataRefresh';
import MovieCamera from '@spectrum-icons/workflow/MovieCamera';
import Camera from '@spectrum-icons/workflow/Camera';
import CancelIcon from '@spectrum-icons/workflow/Cancel';
import Box from '@spectrum-icons/workflow/Box';

import {
    IModelSettings,
    IResponseQueueItem, IServerStatusResponse
} from 'sharedtypes/socket/raytracingEvents';
import { useAuthContext } from '~/Context/contextAuth';
import { apiSocket } from '~/Api/apiSocket';
import { fetchAndDownload, stopRender } from '~/Services/Rtx/rtxObservable';
import { getStringFromSeconds } from '~/Services/Rtx/rtxTimeCalculator';

const event = new Date(1993, 6, 28, 14, 39, 7);
const LOCAL_QUEUE: IResponseQueueItem[] = [
    {
        status: 'running',
        type: 'image',
        settings: { format: 'PNG', resolution: '2K', povs: 2 },
        progress: 10,
        download: {
            isAvailable: false,
            url: '',
        },
        id: '12345',
        user: 'Pich',
        username: 'Pich',
        estimation: 1000,
        name: 'LOCAL 1',
        launchDate: event,
    },
];

interface IQueueColumn {
    name: string
    uid: string
    size: string
    width: number
}

const QUEUE_COLUMNS: IQueueColumn[] = [
    {
        name: 'Name', uid: 'name', size: '1fr', width: null
    },
    {
        name: 'Status', uid: 'status', size: '1fr', width: null
    },
    {
        name: 'Progress', uid: 'progress', size: '2fr', width: null
    },
    {
        name: 'Type', uid: 'type', size: null, width: 30
    },
    {
        name: 'Parameters', uid: 'settings', size: null, width: 110
    },
    {
        name: 'Launch', uid: 'launch', size: null, width: 90
    },
    {
        name: 'User', uid: 'username', size: '1fr', width: null
    },
    {
        name: 'Action', uid: 'dwl', size: null, width: 30
    },
];

interface ITableCell {
    k: Key;
    it: IResponseQueueItem;
    userId: string;
}

const onAction = (it: IResponseQueueItem) => {
    if (it.status === 'done') {
        const arr = it.download.url.split('/');
        fetchAndDownload(it.download.url, arr[arr.length - 1]);
    } else {
        stopRender(it.id);
    }
};

const QueueTableCell: FC<ITableCell> = ({ k, it, userId }) => {
    if (k === 'progress') {
        return (
            <ProgressBar
                label={it.status === 'pending' ? `Estimated time: ${getStringFromSeconds(it.estimation)}` : it.status}
                aria-label="progress-bar"
                key={it.id + k}
                value={it.progress}
                width="size-2400"
                showValueLabel
            />
        );
    }
    if (k === 'dwl') {
        return (
            <ActionButton
                aria-label="delete-download-button"
                isQuiet
                isDisabled={it.user !== userId}
                onPress={() => { onAction(it); }}
            >
                {(it.status === 'done') ? <DownloadFromCloud /> : <CancelIcon />}
            </ActionButton>
        );
    }
    if (k === 'type') {
        if (it.type === 'video') return <MovieCamera />;
        if (it.type === 'model') return <Box />;
        return <Camera />;
    }
    if (k === 'launch') {
        const date = new Date(it.launchDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const hour = date.getHours();
        const mins = date.getMinutes();
        return <View key={it.id + k}>{`${day}/${month} - ${hour}:${mins}`}</View>;
    }
    if (k === 'settings') {
        if (it.type === 'video') {
            // @ts-ignore
            return <View key={it.id + k}>{`${it.settings.fps} FPS, ${it.settings.duration} sec, ${it.settings.resolution}`}</View>;
        }
        if (it.type === 'model') {
            console.log(it);
            return <View key={it.id + k}>{`${(it.settings as IModelSettings).formats.toString().replace(',', '/').toUpperCase()}`}</View>;
        }
        // @ts-ignore
        return <View key={it.id + k}>{`${it.settings.povs} Pov, ${it.settings.format}, ${it.settings.resolution}`}</View>;
    }
    if (k === 'status') {
        let variantColor: 'notice' | 'info' | 'negative' | 'positive' = 'notice';
        if (it.status === 'running') {
            variantColor = 'info';
        }
        if (it.status === 'failed') {
            variantColor = 'negative';
        }
        if (it.status === 'done') {
            variantColor = 'positive';
        }
        return (
            <View key={it.id + k}>
                <StatusLight
                    UNSAFE_style={{ marginLeft: -15 }}
                    variant={variantColor}
                >
                    {it[k]}

                </StatusLight>
            </View>
        );
    }
    return <View key={it.id + k}>{it[k]}</View>;
};

export const QueueDashboard: FC = () => {
    const [items, setItems] = useState<IResponseQueueItem[]>([]);
    const [serverStatus, setServerStatus] = useState('running');
    const [timer, setTimer] = useState(0);
    const lastUpdate = useRef(0);
    const [refreshIt, setRefreshIt] = useState<NodeJS.Timeout>();
    const [updateIt, setUpdateIt] = useState<NodeJS.Timeout>();
    const { isLogged, user, isLoading } = useAuthContext();

    const refreshStatus = () => {
        apiSocket.getSocket().emit('queue:infos', null, () => { });
    };

    const updateTimer = (time: number) => {
        setTimer(time);
        lastUpdate.current = time;
    };

    const updateInfos = (data: IServerStatusResponse) => {
        setServerStatus(data.serverStatus);
        setItems(data.queue);
        updateTimer(0);
    };

    const setAutoRefresh = () => {
        clearInterval(refreshIt);
        clearInterval(updateIt);
        setRefreshIt(setInterval(() => {
            refreshStatus();
        }, 30000));
        setUpdateIt(setInterval(() => {
            updateTimer(lastUpdate.current + 2);
        }, 2000));
    };

    useEffect(() => {
        const socket = apiSocket.getSocket();
        if (isLogged && socket && user) {
            setAutoRefresh();
            refreshStatus();
            apiSocket.getSocket().on('queue:update', updateInfos);
        } else {
            setItems(LOCAL_QUEUE);
        }
        return () => {
            apiSocket.getSocket().off('queue:update', updateInfos);
        };
    }, [isLogged, isLoading, user]);

    if (!user) {
        return <></>;
    }

    return (
        <View
            gridArea="content"
            marginStart="size-1000"
            UNSAFE_style={{
                overflow: 'hidden visible',
                height: '100%'
            }}
        >
            <Heading level={1} marginTop="size-400">
                <Flex alignItems="center" direction="row" justifyContent="space-between">
                    Render Queue
                    <View alignSelf="end">
                        <Flex direction="row" alignItems="center" justifyContent="center">
                            <Button variant="primary" marginEnd="size-100" onPress={() => refreshStatus()} UNSAFE_style={{ cursor: 'pointer' }}>
                                <DataRefresh marginEnd="size-100" />
                                {`Last updated: ${timer}s ago`}
                            </Button>
                            <Badge variant={serverStatus === 'stopped' ? 'negative' : 'positive'}>
                                {serverStatus.charAt(0).toUpperCase() + serverStatus.slice(1)}
                            </Badge>
                        </Flex>
                    </View>
                </Flex>
            </Heading>
            <Divider size="M" marginBottom="size-400" />
            <TableView aria-label="queue-items" isQuiet>
                <TableHeader columns={QUEUE_COLUMNS}>
                    {
                        (column) => (
                            <Column
                                defaultWidth={column.size}
                                width={column.width}
                                key={column.uid}
                                align="start"
                                allowsResizing
                            >
                                {column.name}
                            </Column>
                        )
                    }
                </TableHeader>
                <TableBody items={items}>
                    {
                        (it) => (
                            <Row key={it.id}>
                                {(columnKey) => (
                                    <Cell>
                                        <QueueTableCell userId={user._id} k={columnKey} it={it} />
                                    </Cell>
                                )}
                            </Row>
                        )
                    }
                </TableBody>
            </TableView>
        </View>
    );
};
