import { Color3 } from '@babylonjs/core/Maths/math';

export const ORANGE_MEDIUM = '#F27649';

export interface IHSL {
    h: number;
    s: number;
    l: number;
}

export interface IRGB {
    r: number;
    g: number;
    b: number;
}

export const colorBase255toBase1 = (color: number[]): number[] => {
    const fixedColor = (color) ? color.map((v) => v / 255) : null;
    return fixedColor;
};

export const hexToHSL = (hex: string): IHSL => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16) / 255;
    const g = parseInt(result[2], 16) / 255;
    const b = parseInt(result[3], 16) / 255;
    const max = Math.max(r, g, b); const
        min = Math.min(r, g, b);
    let h; let s; const
        l = (max + min) / 2;
    if (max === min) {
        // eslint-disable-next-line no-multi-assign
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }
        h /= 6;
    }
    const HSL: IHSL = {
        h, s, l
    };
    return HSL;
};

export const hexToRgbBabylon = (hex: string): Color3 => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = result ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255
    } : null;
    return new Color3(rgb.r, rgb.g, rgb.b);
};

export const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = (rgb: IRGB): string => {
    const str = `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
    return str;
};

export const hslToStr = (hsl: IHSL): string => {
    const str = `hsl(${Math.round(hsl.h * 360)}, ${hsl.s * 100}%, ${hsl.l * 100}%)`;
    return str;
};
