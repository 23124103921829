import React, { FC, ReactChild } from 'react';
import { View } from '@adobe/react-spectrum';

interface IDashboardFooterProps {
    children: ReactChild | ReactChild[];
}

export const DashboardFooter: FC<IDashboardFooterProps> = ({ children }) => (
    <View
        position="absolute"
        bottom="0px"
        height="size-500"
        backgroundColor="gray-100"
        width="calc(100% - 400px)"
        padding="size-100"
        marginStart="size-1000"
        marginEnd="size-1000"
    >
        {children}
    </View>
);
