import React, { FC, useMemo, useState } from 'react';
import { DialogTrigger, ActionButton, DialogContainer } from '@adobe/react-spectrum';
import DeleteIcon from '@spectrum-icons/workflow/Delete';
import { DeleteDialog } from './deleteDialog';

interface IDeleteButtonProps {
    title: string;
    onDelete: () => void;
    onOpenChange?: (isOpen: boolean) => void;
    size?: number
}

export const DeleteButton: FC<IDeleteButtonProps> = ({
    title, onDelete, onOpenChange, size
}: IDeleteButtonProps) => {
    const sizePixel = useMemo(() => ((size) ? `${size}px` : null), []);
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <ActionButton
                isQuiet
                UNSAFE_style={{
                    maxWidth: sizePixel,
                    maxHeight: sizePixel,
                    minWidth: sizePixel,
                    minHeight: sizePixel
                }}
                onPress={() => setOpen(true)}
            >
                <DeleteIcon
                    UNSAFE_style={{ padding: '0px' }}
                    width={sizePixel}
                    height={sizePixel}
                />
            </ActionButton>
            <DialogContainer onDismiss={() => setOpen(false)}>
                {isOpen
                    && (
                        <DeleteDialog
                            title={title}
                            onDelete={onDelete}
                        />
                    )}
            </DialogContainer>
        </>
    );
};

DeleteButton.defaultProps = {
    onOpenChange: null,
    size: null
};
