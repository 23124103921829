import React, { FC } from 'react';
import {
    Button, Content, Dialog, Flex, Footer, Heading
} from '@adobe/react-spectrum';
import { IDialogProps } from '~/Types';

const goToAgenda = () => {
    window.open('https://meetings.hubspot.com/jack125');
};

const BillingSuccessDialog: FC<IDialogProps> = ({ setOpen, open }) => (
    <>
        {open && (
            <Dialog width="size-1200">
                <Content>
                    <Heading level={2}>
                        Your request has been sent.

                        You can book an appointment
                        with Nicolas for an introduction.
                    </Heading>
                </Content>
                <Footer>
                    <Flex direction="row" gap="size-115">
                        <Button variant="secondary" onPress={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="cta"
                            onPress={() => {
                                goToAgenda();
                            }}
                        >
                            Book
                        </Button>
                    </Flex>
                </Footer>
            </Dialog>
        )}
    </>
);

export default BillingSuccessDialog;
