import React, { FC } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { IProjects, IProject } from 'sharedtypes';

import { CardAddProject } from './ProjectCard/cardAddProject';
import { ProjectList } from './projectList';

import { TDashboardLibrary } from '../../IDashboard';

interface IProjectListProps {
    projects: IProjects;
    listType: TDashboardLibrary;
    currentPage: number;
    createProject: () => void;
    setSelectedProject: (project: IProject) => void;
    setShowModal: (b: boolean) => void;
}

export const ProjectListLayout: FC<IProjectListProps> = ({
    projects, listType, currentPage, createProject, setSelectedProject, setShowModal
}) => (
    <Flex
        gap="size-200"
        wrap="wrap"
    >
        {
            listType === 'personal' && currentPage === 1
            && <CardAddProject createProject={createProject} />
        }
        <ProjectList
            projects={projects}
            setSelectedProject={setSelectedProject}
            listType={listType}
            setShowModal={setShowModal}
        />
    </Flex>
);
