import { Quaternion, Vector3 } from '@babylonjs/core/Maths/math';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

import { SystemLayer } from '../../System/systemLayer';
import { DegreeToRadian, getRandomString, RadianToDegreeVector } from '../../../Utils/math';

export class PatternTransform {
    public key: string;

    protected _system: SystemLayer;

    public mesh: TransformNode;

    constructor(systemCamera: SystemLayer) {
        this._system = systemCamera;
        this.key = getRandomString();
    }

    protected initMesh = (mesh: TransformNode): void => {
        if (this.mesh) {
            mesh.position = this.mesh.position.clone();
            mesh.rotation = this.mesh.rotation.clone();
            mesh.scaling = this.mesh.scaling.clone();
            // Initialize the quaternion
            if (mesh.rotationQuaternion) {
                const rot = mesh.rotation;
                mesh.rotationQuaternion = Quaternion.RotationYawPitchRoll(rot.y, rot.x, rot.z);
            }
            this.mesh.dispose();
        }
        this.mesh = mesh;
        this._system.checkActiveMeshes();
    }

    public setParent(parent: PatternTransform): void {
        this.mesh.parent = parent.mesh;
    }

    public unSetParent(): void {
        this.mesh.parent = undefined;
    }

    public setPosAxis(pos: Vector3): void {
        this.mesh.position = pos;
    }

    public getPosition(): Vector3 {
        return this.mesh.position.clone();
    }

    public getRotationQuaternion = (): Quaternion => this.mesh.rotationQuaternion.clone();

    public getScale = (): Vector3 => this.mesh.scaling;

    private rotation = Vector3.Zero()

    public setAngleAxis(angles: Vector3): void {
        // Same function used in light
        return this._setAngleAxis(angles);
    }

    protected _setAngleAxis(angles: Vector3): void {
        this.rotation = angles.multiplyByFloats(DegreeToRadian, DegreeToRadian, DegreeToRadian);
        const rot = this.rotation;
        this.mesh.rotationQuaternion = Quaternion.RotationYawPitchRoll(rot.y, rot.x, rot.z);
    }

    public getRotation(): Vector3 {
        const rot = this.rotation;
        return rot.multiply(RadianToDegreeVector);
    }

    public setScaleAxis(axis: Vector3): void {
        this.mesh.scaling = axis;
    }

    public destroy(): void {
        this.mesh.dispose();
    }

    public show(): void {
        this._show();
    }

    protected _show(): void {
        this._system.checkActiveMeshes();
    }

    public hide(): void {
        this._hide();
    }

    protected _hide(): void {
        this._system.checkActiveMeshes();
    }

    // Make sur change is needed becasue very heavy calculation
    protected flat = false;

    public setFlatMesh(flat: boolean): void {
        this.flat = flat;
    }

    // https://doc.babylonjs.com/how_to/optimizing_your_scene
    public optimize(): void {
        this.mesh.freezeWorldMatrix();
    }

    public unOptimize(): void {
        this.mesh.unfreezeWorldMatrix();
    }

    public getAbsolutePosition(): Vector3 {
        return this.mesh.getAbsolutePosition();
    }

    public getAbsoluteRotation(): Vector3 {
        const quaternion = Quaternion.Identity();
        const position = Vector3.Zero();
        this.mesh.getWorldMatrix().decompose(Vector3.Zero(), quaternion, position);
        // if (this.mesh.rotationQuaternion) {
        //   this.mesh.rotationQuaternion.copyFrom(quaternion)
        // } else {
        // }
        const rot = quaternion.toEulerAngles();
        return rot.multiply(RadianToDegreeVector);
    }
}
