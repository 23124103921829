import { Color3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';

import { colorBase255toBase1 } from '../../../../Utils/color';
import { PatternTransform } from '../patternTransform';
import { Animation } from '../../../System/Animation/animation';
import { Ease, EaseMode } from '../../../System/Animation/animationEasing';

interface ITintColorKeyList {
    albedo?: Color3,
    emissive?: Color3,
    reflectivity?: Color3,
    ambient?: Color3,
    specular?: Color3,
    bump?: Color3,
    opacity?: Color3,
}

export const tintColorList: ITintColorKeyList = {
    albedo: Color3.White(),
    emissive: Color3.Black(),
    reflectivity: Color3.White(),
    ambient: Color3.Black(),
    specular: Color3.Black(),
    bump: Color3.Black(),
    opacity: Color3.White(),
};

export class PatternColor extends PatternTransform {
    public mesh: Mesh;

    public material: PBRMaterial;

    public setTintColor(tint: number[]): void {
        if (!tint) {
            this.resetTintColor();
            return;
        }
        const fixedTint = colorBase255toBase1(tint);
        const tintColor3 = new Color3(fixedTint[0], fixedTint[1], fixedTint[2]);
        this.setTintColor3(tintColor3);
    }

    protected setEmissiveColor(color: Color3): void {
        if (this.flashAnimation) this.flashAnimation.stop();
        this.originTints.emissive = color;
        if (this.currentTintColor3) {
            this.setTintColor3(this.currentTintColor3);
        } else {
            this.material.emissiveColor = color;
        }
    }

    private originTints: ITintColorKeyList = {}

    private currentTintColor3: Color3

    private setTintColor3(tintColor3: Color3) {
        this.currentTintColor3 = tintColor3;
        if (this.material) {
            const keys = Object.keys(tintColorList);
            keys.forEach((key) => {
                const oc = this.material[`${key}Color`];
                // Can't limit to differences from defaultColors
                // as the mesh color can come from the texture
                // but still needs to be changed with Tint
                if (oc) {
                    if (!this.originTints[key]) this.originTints[key] = oc.clone();
                    const sourceColor: Color3 = this.originTints[key];
                    const average = (sourceColor.r + sourceColor.g + sourceColor.b) / 3;
                    const color3Norm = new Color3(average, average, average);
                    this.material[`${key}Color`] = color3Norm.multiply(tintColor3);
                }
            });
        }
    }

    public resetTintColor(): void {
        if (!this.currentTintColor3) return;
        if (this.material) {
            const keys = Object.keys(tintColorList);
            keys.forEach((key) => {
                const c: Color3 = this.material[`${key}Color`];
                if (c && this.originTints[key]) this.material[`${key}Color`] = this.originTints[key].clone();
            });
        }
        this.currentTintColor3 = null;
    }

    flashAnimation: Animation

    public flash(length?: number): void {
        if (!this.flashAnimation) {
            this.flashAnimation = new Animation(this._system);
            this.flashAnimation.setEasing(Ease.Exponential, EaseMode.InOut);
        }
        const startEmissive = this.material.emissiveColor;
        length = (length) || 150;
        this.flashAnimation.simple(length, (perc) => {
            const percA = Math.min(Math.min(4 * perc, 4 - 4 * perc), 1) / 3;
            this.material.emissiveColor = startEmissive.add(new Color3(percA, percA, percA));
        }, () => {
            this.material.emissiveColor = startEmissive;
        });
    }
}
