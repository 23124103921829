import find from 'lodash/find';

import { SAVING } from '~/Services/Environment/devConfig';

export type ISubDomain = 'app' | 'staging' | 'develop' | 'local' | 'naker-annotation.s3-website.eu-west-3';

type TApiUrl =
    | 'https://backend-prod.naker.io/'
    | 'https://backend-staging.naker.io'
    | 'https://backend-dev.naker.io/'
    | 'http://localhost:3000/';

export enum CONTEXT {
    PROD,
    STAGING,
    DEVELOP,
    LOCAL,
}

export interface IEnvironment {
    subdomain: ISubDomain;
    context: CONTEXT;
    apiurl: TApiUrl;
    admin: boolean;
    sentry: boolean;
    saving: boolean;
    intercom: boolean;
}

const Environments: IEnvironment[] = [
    {
        context: CONTEXT.PROD,
        subdomain: 'app',
        apiurl: 'https://backend-prod.naker.io/',
        saving: true,
        intercom: true,
        sentry: true,
        admin: false,
    },
    {
        context: CONTEXT.STAGING,
        subdomain: 'staging',
        apiurl: 'https://backend-prod.naker.io/',
        // apiurl: 'https://backend-staging.naker.io/',
        saving: true,
        intercom: false,
        sentry: false,
        admin: true, // Need to be admin to log and enter
    },
    {
        context: CONTEXT.DEVELOP,
        subdomain: 'develop',
        apiurl: 'https://backend-dev.naker.io/',
        saving: true,
        intercom: false,
        sentry: false,
        admin: true,
    },
    {
        context: CONTEXT.LOCAL,
        subdomain: 'local',
        apiurl: 'http://localhost:3000/',
        saving: SAVING,
        intercom: false,
        sentry: false,
        admin: true,
    },
    {
        context: CONTEXT.LOCAL,
        subdomain: 'naker-annotation.s3-website.eu-west-3',
        apiurl: 'http://localhost:3000/',
        saving: SAVING,
        intercom: false,
        sentry: false,
        admin: true,
    }
];

export const getSubdomain = (): ISubDomain => {
    const { hostname } = window.location;
    // eslint-disable-next-line
    const regexParse = new RegExp('[a-z-0-9]{2,63}.[a-z.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    const fullSub = hostname.replace(urlParts[0], '').slice(0, -1);
    const sub = fullSub.indexOf('www.') > -1 ? fullSub.substring(4) : fullSub;
    if (sub.includes('develop') || sub.includes('dev')) {
        return 'develop';
    } if (sub.includes('staging')) return 'staging';
    if (sub.includes('app') || sub.includes('master') || sub.includes('adeo')) return 'app';
    return sub as ISubDomain || 'local';
};

const subdomain = getSubdomain();
const env: IEnvironment = find(Environments, (e) => e.subdomain === subdomain);
export const { context } = env;
export default env;
