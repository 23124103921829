import axios from 'axios';
import env from '~/Services/Environment/environment';

const BASE_URL = env.apiurl;

export const post = (url: string, body: object) => {
    const fullUrl = BASE_URL + url;
    return axios.post(fullUrl, body);
};

export const get = (url: string, body: object) => {
    const fullUrl = BASE_URL + url;
    return axios.get(fullUrl, { params: body });
};

export const put = (url: string, body: object) => {
    const fullUrl = BASE_URL + url;
    return axios.put(fullUrl, body);
};

export const upload = (url: string, file: string | Blob) => {
    const data = new FormData();
    data.append('file', file);
    return post(url, data);
};
