import React, {
    FC, useEffect, useState, Key
} from 'react';
import {
    Cell,
    Column,
    Divider,
    Row,
    TableBody,
    TableView,
    View,
    TableHeader,
} from '@adobe/react-spectrum';

import { IDamItem } from 'sharedtypes/socket/damEvents';
import { DashboardHeader } from '../dashboardHeader';
import { DamUploadButton } from './damUploadButton';
import { apiSocket } from '~/Api/apiSocket';
import { DamTableCell } from './damTableCell';

interface IQueueColumn {
    name: string;
    uid: string;
    size: string;
    align: 'center' | 'start' | 'end';
}

const QUEUE_COLUMNS: IQueueColumn[] = [
    {
        name: '', uid: 'thumbnail', size: '0.1fr', align: 'center'
    },
    {
        name: 'Name', uid: 'name', size: '1fr', align: 'start'
    },
    {
        name: 'Type', uid: 'type', size: '0.1fr', align: 'start'
    },
    {
        name: 'Copy URL', uid: 'url', size: '0.5fr', align: 'start'
    },
    {
        name: 'Size', uid: 'size', size: '0.5fr', align: 'start'
    },
    {
        name: 'Uploaded', uid: 'upload_date', size: '0.5fr', align: 'start'
    },
    {
        name: 'Uploaded by', uid: 'owner', size: '0.5fr', align: 'start'
    },
    {
        name: 'Download', uid: 'dwl', size: '0.5fr', align: 'center'
    },
];

interface DamTableCell {
    k: Key;
    it: IDamItem;
}

export const DamDashboard: FC = () => {
    const [data, setData] = useState<IDamItem[]>([]);

    const updateDamInfos = ({ err, data }) => {
        setData(data);
    };

    useEffect(() => {
        apiSocket.getSocket().emit('dam:get', null, updateDamInfos);
    }, []);

    return (
        <View>
            <DashboardHeader title="Naker DAM">
                <DamUploadButton />
                <TableView isQuiet aria-label="dam-table">
                    <TableHeader columns={QUEUE_COLUMNS}>
                        {
                            (column) => (
                                <Column
                                    // @ts-ignore
                                    defaultWidth={column.size}
                                    key={column.uid}
                                    align={column.align}
                                >
                                    {column.name}
                                </Column>
                            )
                        }
                    </TableHeader>
                    <TableBody items={data}>
                        {
                            (it) => (
                                <Row key={it.url}>
                                    {(columnKey) => (
                                        <Cell>
                                            <DamTableCell
                                                key={it.url + columnKey}
                                                k={columnKey}
                                                it={it}
                                            />
                                        </Cell>
                                    )}
                                </Row>
                            )
                        }
                    </TableBody>
                </TableView>
            </DashboardHeader>
        </View>
    );
};
